<template>
  <div class="dolligo-wrap">
    <!-- <header class="fixed">
      <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="icon back-btn">뒤로가기</a>
      <h1>희망 근무지</h1>
      <div class="right-btns">
        <a href="javascript:void(0);" v-bind:class="{'txt-btn':true, on:isSubmit()}" v-on:click="save()">저장</a>
      </div>
    </header> -->
    <top-back-header-dolligo :propTitle="`희망 근무지`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="write-wrap">
        <div class="list-wrap">
          <ul class="btns-wrap">
            <li>
              <a href="javascript:void(0);" v-bind:class="{ 'submit-btn': true, on: member.mb_18 == '국가직' }"
                v-on:click="member.mb_18 = '국가직'">국가직</a>
              <a href="javascript:void(0);" v-bind:class="{ 'submit-btn': true, on: member.mb_18 == '지방직' }"
                v-on:click="member.mb_18 = '지방직'; member.mb_19 = ''">지방직</a>
              <a href="javascript:void(0);" v-bind:class="{ 'submit-btn': true, on: member.mb_18 == '선택안함' }"
                v-on:click="member.mb_18 = '선택안함'; member.mb_19 = ''">선택안함</a>
            </li>
            <li v-if="member.mb_18 == '국가직'">
              <select v-model="member.mb_19">
                <option value="">희망 행정처 선택</option>
                <option v-for="office in offices" v-bind:key="office.grd3_cd" v-bind:value="office.grd3_cd">
                  {{ office.grd3_nm }}</option>
              </select>
            </li>
          </ul>
        </div>
        <div class="list-wrap">
          <h2>1지망 희망 근무지</h2>
          <ul>
            <li>
              <select v-model="wishAreas[0].cityCode" v-on:change="getDistricts(wishAreas[0].cityCode, 1)">
                <option value="">1지망 광역.시.도 선택</option>
                <option v-for="city in cities" v-bind:key="city.addr1_cd" v-bind:value="city.addr1_cd">{{ city.addr1_nm }}
                </option>
              </select>
            </li>
            <li>
              <select v-model="wishAreas[0].districtCode">
                <option value="">1지망 시.군.구 선택</option>
                <option v-for="district in districts1" v-bind:key="district.addr2_cd" v-bind:value="district.addr2_cd">
                  {{ district.addr2_nm }}</option>
              </select>
            </li>
          </ul>
        </div>
        <div class="list-wrap">
          <h2>2지망 희망 근무지</h2>
          <ul>
            <li>
              <select v-model="wishAreas[1].cityCode" v-on:change="getDistricts(wishAreas[1].cityCode, 2)">
                <option value="">2지망 광역.시.도 선택</option>
                <option v-for="city in cities" v-bind:key="city.addr1_cd" v-bind:value="city.addr1_cd">{{ city.addr1_nm }}
                </option>
              </select>
            </li>
            <li>
              <select v-model="wishAreas[1].districtCode">
                <option value="">2지망 시.군.구 선택</option>
                <option v-for="district in districts2" v-bind:key="district.addr2_cd" v-bind:value="district.addr2_cd">
                  {{ district.addr2_nm }}</option>
              </select>
            </li>
          </ul>
        </div>
        <div class="list-wrap">
          <h2>3지망 희망 근무지</h2>
          <ul>
            <li>
              <select v-model="wishAreas[2].cityCode" v-on:change="getDistricts(wishAreas[2].cityCode, 3)">
                <option value="">3지망 광역.시.도 선택</option>
                <option v-for="city in cities" v-bind:key="city.addr1_cd" v-bind:value="city.addr1_cd">{{ city.addr1_nm }}
                </option>
              </select>
            </li>
            <li>
              <select v-model="wishAreas[2].districtCode">
                <option value="">3지망 시.군.구 선택</option>
                <option v-for="district in districts3" v-bind:key="district.addr2_cd" v-bind:value="district.addr2_cd">
                  {{ district.addr2_nm }}</option>
              </select>
            </li>
          </ul>
        </div>
        <button type="submit" v-bind:class="{ 'submit-btn': true, on: isSubmit() }" v-on:click="save()">저장</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '../../../mixin'
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

export default {
  components: { TopBackHeaderDolligo },
  name: 'dolligoMemberWishArea',
  mixins: [mixin],
  data: function () {
    return {
      member: this.MEMBER,
      offices: [],
      cities: [],
      districts1: [],
      districts2: [],
      districts3: [],
      wishAreas: [{
        cityCode: '',
        districtCode: '',
        order: 1,
        wr_id: null,
        newMember: false,
      }, {
        cityCode: '',
        districtCode: '',
        order: 2,
        wr_id: null,
        newMember: false,
      }, {
        cityCode: '',
        districtCode: '',
        order: 3,
        wr_id: null,
        newMember: false,
      }]
    }
  },
  created: async function () {
    try {
      this.offices = await axios.get('/api/offices', this.axiosConfig); // 행정처
      this.offices = this.offices.data;
      this.cities = await axios.get('/api/cities', this.axiosConfig); // 광역시도
      this.cities = this.cities.data;
      const { data } = await axios.get('/api/wisharea', this.axiosConfig); // 희망지역
      if (data[0]) { // 희망지역 1 세팅
        this.wishAreas[0].cityCode = data[0].wr_3.substr(0, 3);
        await this.getDistricts(this.wishAreas[0].cityCode, 1);
        this.wishAreas[0].districtCode = data[0].wr_3.substr(3, 3);
        this.wishAreas[0].wr_id = data[0].wr_id;
      }
      if (data[1]) { // 희망지역 2 세팅
        this.wishAreas[1].cityCode = data[1].wr_3.substr(0, 3);
        await this.getDistricts(this.wishAreas[1].cityCode, 2);
        this.wishAreas[1].districtCode = data[1].wr_3.substr(3, 3);
        this.wishAreas[1].wr_id = data[1].wr_id;
      }
      if (data[2]) { // 희망지역 3 세팅
        this.wishAreas[2].cityCode = data[2].wr_3.substr(0, 3);
        await this.getDistricts(this.wishAreas[2].cityCode, 3);
        this.wishAreas[2].districtCode = data[2].wr_3.substr(3, 3);
        this.wishAreas[2].wr_id = data[2].wr_id;
      }
    } catch (error) {
      this.errorHandler(error);
    }
  },
  methods: {
    // 시군구
    getDistricts: async function (cityCode, order) {
      if (!cityCode) {
        if (order == 1) this.wishAreas[0].districtCode = '';
        if (order == 2) this.wishAreas[1].districtCode = '';
        if (order == 3) this.wishAreas[2].districtCode = '';
        return;
      }
      this.axiosConfig.params.cityCode = cityCode;
      let districts = await axios.get('/api/districts', this.axiosConfig); // 시군구

      if (order == 1) {
        this.wishAreas[0].districtCode = '';
        this.districts1 = districts.data;
      }
      if (order == 2) {
        this.wishAreas[1].districtCode = '';
        this.districts2 = districts.data;
      }
      if (order == 3) {
        this.wishAreas[2].districtCode = '';
        this.districts3 = districts.data;
      }
    },
    // 저장 가능한지 검사
    isSubmit: function () {
      if (!this.wishAreas[0].cityCode) return false;
      if (!this.wishAreas[0].districtCode) return false;
      if (!this.member.mb_18) return false;
      if (this.member.mb_18 == '국가직' && !this.member.mb_19) return false;
      if (this.wishAreas[0].districtCode && this.wishAreas[1].districtCode && this.wishAreas[0].districtCode == this.wishAreas[1].districtCode) return false;
      if (this.wishAreas[1].districtCode && this.wishAreas[2].districtCode && this.wishAreas[1].districtCode == this.wishAreas[2].districtCode) return false;
      if (this.wishAreas[0].districtCode && this.wishAreas[2].districtCode && this.wishAreas[0].districtCode == this.wishAreas[2].districtCode) return false;

      if (this.wishAreas[1].cityCode && !this.wishAreas[1].districtCode) return false;
      if (this.wishAreas[2].cityCode && !this.wishAreas[2].districtCode) return false;

      if (!this.wishAreas[1].cityCode && !this.wishAreas[1].districtCode && this.wishAreas[2].cityCode && this.wishAreas[2].districtCode) return false;
      return true;
    },
    // 저장
    save: async function () {
      try {
        if (this.isSubmit() == false) return false;
        let self = this;
        // 희망지역 
        this.wishAreas.forEach(async function (wishArea, index) {
          if (wishArea.cityCode && wishArea.districtCode) {
            if (wishArea.wr_id) await axios.put('/api/wisharea', wishArea, self.axiosConfig); // 업데이트
            else {
              if (index == 0) wishArea.newMember = true;
              axios.post('/api/wisharea', wishArea, self.axiosConfig); // 추가
            }
          } else if (wishArea.wr_id && !wishArea.cityCode && !wishArea.districtCode) {
            await axios.delete(`/api/wisharea/${wishArea.wr_id}`, self.axiosConfig);
          }
        });

        // 회원정보
        let body = { mb_18: this.member.mb_18, mb_19: this.member.mb_19 };
        let member = await axios.patch('/api/member', body, this.axiosConfig);
        // 회원 레벨이 0이면 일반회원 전환 한다.
        if (member.data.mb_level == '0') {
          body = { mb_level: '2' };
          await axios.patch('/api/member', body, this.axiosConfig);
        }

        // 신규가입시 매창자 알림
        if (!this.MEMBER.g5_z_applies || this.MEMBER.g5_z_applies.length == 0) {
          let hostName = '';
          if (location.hostname == 'www-dev.gongdoll.com' || location.hostname == 'www-dev.gongdoll.com') hostName = 'https://cron-dev.gongdoll.com';
          else if (location.hostname == 'www.gongdoll.com' || location.hostname == 'www.gongdoll.com') hostName = 'https://cron.gongdoll.com';
          axios.post(`${hostName}/api/dolligo/match/member/push`, {}, this.axiosConfig);
        }

        this.$router.push({ name: 'dolligoMemberRegistDone' });
      } catch (error) {
        this.errorHandler(error);
      }
    }
  }
}
</script>

