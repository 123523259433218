import mobileDetect from "mobile-detect";
import axios from 'axios';
import Vue from 'vue';
import newlineBr from 'newline-br';
import $ from "jquery";

const md = new mobileDetect(navigator.userAgent);

let mixin = {
  data: function () {
    return {
      axiosConfig: {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
          "PushToken": this.getPushToken(),
        },
        params: {},
      },
      me: Vue.prototype.MEMBER,
      appStoreUrl: md.is('iPhone') == true ? 'https://apps.apple.com/us/app/%EC%9D%B8%EC%82%AC%EA%B5%90%EB%A5%98/id1461429441' : 'https://play.google.com/store/apps/details?id=com.hustay.swing.dededae603d0b4850bac7d4209309ce94',
      backUrl: document.referrer.indexOf(document.location.hostname) == -1 ? '/branch' : 'javascript:history.back();',
      baseUrl: window.location.host == 'localhost:8088' ? 'http://localhost:3001' : window.location.protocol + '//' + window.location.host,
      benefit : {
        titles: [
          '상대방에게 알림톡(카카오톡) 발송',
          '상대방의 쪽지 응답률 및 최근 접속일 확인',
          '다자교류자들 확인 및 그룹채팅',
          '더 꼭 맞는 교류자 검색',
          '현직 근무자들의 근무환경 리뷰'
        ],
        descs: [
          '매칭교류자에게 쪽지, 채팅을 발송할 경우 상대방에게 알림톡을 자동으로 전송하여 혹시 지나칠 수 있는 중요한 교류요청을 잊지 않을 수 있습니다.',
          '상대방이 얼마나 빠른시간에 쪽지 답장을 하는지 궁금하시죠? 쪽지응답률을 프로필 페이지에서 확인하시고 답장 여부와 시간을 예상해보세요. 또한 최근 어플 접속일을 확인해 볼 수 있어 얼마나 인사교류에 관심을 갖고 있는지 가늠해볼 수 있습니다.',
          '다자교류자들 확인이 가능하며 그룹채팅으로 동시에 매칭여부를 빠르게 확인할 수 있어 교류자들과 각각 쪽지를 보내는 번거로움을 덜 수 있습니다.',
          '지역, 직렬의 2가지 조건에 추가하여 연령대, 동일직급, 동일성별, 전보제한 여부, 강임허용 여부 등의 상세조건으로 맞춤형 교류자를 찾을 수 있습니다.',
          '교류도 중요하지만, 희망하는 행정처의 근무환경은 더더욱 중요하죠? 현직 공무원이 직접 작성한 전국 300여개 행정처의 실시간 근무환경 평가내용을 확인하세요.'
        ]
      },
      isIphone: md.is('iPhone') == true,
      interval: null
    }
  },
  created: function () {
    if(this.MEMBER) {
      // console.log(window.swingLoginPlugin);
      // window.swingLoginPlugin.doLoginForce(this.MEMBER.mb_id, this.MEMBER.mb_nick)
    }
    // console.log(window.swingLoginPlugin.doLoginForce);
    //swingLoginPlugin.doLoginForce("<?=$member[mb_id]?>","<?=$member[mb_nick]?>");
    // let axiosConfig = this.axiosConfig;
    // axiosConfig.params = { payText: `${window.location.href} \`\`\`12354\`\`\``, type: 'error' };
    // axios.get(`/api/dolligo/pay-inapp-log/slack`, axiosConfig);  
  },
  methods: {
    errorHandler: function (error) {
      if (error.response) {
        // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
        console.log(error.response.data);
        if (error.response.data.messge) {
          //alert(error.response.data.messge);
          let axiosConfig = this.axiosConfig;
          axiosConfig.params = { payText: `\`\`\`${error.response.data.messge}\`\`\``, type: 'error' };
          axios.get(`/api/dolligo/pay-inapp-log/slack`, axiosConfig);            
        }
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // 요청이 이루어 졌으나 응답을 받지 못했습니다.
        // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
        // Node.js의 http.ClientRequest 인스턴스입니다.
        console.log(error.request);
      } else {
        // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
        let axiosConfig = this.axiosConfig;
        axiosConfig.params = { payText: `\`\`\`${location.href}:${JSON.stringify(error.message)}\`\`\``, type: 'error' };
        axios.get(`/api/dolligo/pay-inapp-log/slack`, axiosConfig);       
        console.log('Error', error.message);
        // if (error.message) alert(error.message);
      }
    },
    getCityName: async function (id) {
      try {
        let response = await axios.get(`/api/cities/${id}`, this.axiosConfig);
        let data = response.data;
        return data.addr1_nm;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    getDistrictName: async function (id) {
      let response = await axios.get(`/api/districts/${id}`, this.axiosConfig);
      let data = response.data;
      return data.addr2_nm;
    },
    getOfficetName: async function (id) {
      let response = await axios.get(`/api/offices/${id}`, this.axiosConfig);
      let data = response.data;
      return data.grd3_nm;
    },
    getJobName: async function (id) {
      let response = await axios.get(`/api/jobs/${id}`, this.axiosConfig);
      let data = response.data;
      return data.grd1_nm;
    },
    getRankName: async function (id) {
      let response = await axios.get(`/api/ranks/${id}`, this.axiosConfig);
      let data = response.data;
      return data.grd2_nm;
    },
    getPushToken: function () {
      // 푸시토큰 얻기
      let agent = navigator.userAgent;
      if (md.is('iPhone') == true) {
        let pushToken = agent.split(';gmid=')[1];
        pushToken = pushToken.split(';')[0];
        return pushToken;
      } else {
        let pushToken = agent.split('/gmid=')[1];
        pushToken = pushToken.split(')')[0];
        return pushToken;
      }
    },
    getState: function (state) {
      let stateName = '';
      switch (state) {
        case '1':
          stateName = '준비중(전보 제한 중)';
          break;
        case '2':
          stateName = '관망중(탐색 중)';
          break;
        case '3':
          stateName = '인사교류 진행 중';
          break;
        case '4':
          stateName = '교류희망(앞으로3개월 내외)';
          break;
        case '5':
          stateName = '교류희망(앞으로1년 내외)';
          break;
        case '6':
          stateName = '교류의사 없음';
          break;
        case '7':
          stateName = '인사교류 성공!';
          break;
      }
      return stateName;
    },
    changeBr: function (str) {
      return newlineBr(str);
    },
    goBack: function () {
      this.$router.go(-1);
    },
    shareApp: function () {
      if(window.swingDeviceScript) 
        window.swingWebViewPlugin.app.methods.doShareWithUrl(this.appStoreUrl);
      else
        window.swingLoginPlugin.doShare(this.appStoreUrl, `[인사교류] 1초 만에 공무원 인사교류 대상자 찾아주는 인사교류 App! 자체 개발한 알고리즘을 통해 국가직, 지방직 공무원의 현재 근무지, 희망 근무지 및 직렬만 입력 하게 되면 1:1 교류에서 다자 교류까지의 조합을 찾아줍니다.`);
    },
    removeSession: async function (sessionName) {
      if (!sessionName) sessionName = this.$route.name;
      sessionStorage.removeItem(sessionName);
    },
    saveSession: async function (sessionName) {
      // console.log(sessionName);
      if (!sessionName) sessionName = this.$route.name;
      // console.log(sessionName);
      // console.log(this.datas);
      sessionStorage.setItem(sessionName, JSON.stringify(this.datas))
    },
    getSession: function () {
      // console.log(this.$route.name);
      // console.log({getSession: JSON.parse(sessionStorage.getItem(this.$route.name))});
      if (sessionStorage.getItem(this.$route.name)) {
        this.datas = JSON.parse(sessionStorage.getItem(this.$route.name));
        sessionStorage.removeItem(this.$route.name);
        return true;
      } else {
        return false;
      }
    },
    logOut: async function () {
      localStorage.removeItem('authToken');
      this.$router.push('/certnum');
    },    
    handleScroll: async function () {
      if ($(window).scrollTop() > 0) {
        $('header').addClass('fixed');
      } else {
        $('header').removeClass('fixed');
      }
    },    
    getErrorMessage: function (error) {
      if (error.response) {
        if (error.response.status === 500) {
          // 서버 오류(500)의 경우
          if (error.response.data && error.response.data.message) {
            // 서버에서 에러 메시지를 제공한 경우
            return error.response.data.message;
          } else if (error.response.data) {
            // 서버에서 제공하는 다른 형태의 에러 정보가 있는 경우
            return JSON.stringify(error.response.data);
          } else {
            // 서버에서 별도의 에러 정보를 제공하지 않는 경우
            return 'Internal Server Error';
          }
        } else if (error.response.data && error.response.data.message) {
          // 다른 HTTP 에러 코드에 대한 에러 메시지
          return error.response.data.message;
        } else {
          // HTTP 오류 코드에 대한 일반 메시지
          return `An error occurred with status code ${error.response.status}`;
        }
      } else if (error.request) {
        // 요청이 이루어졌으나 응답을 받지 못한 경우
        return 'No response was received';
      } else {
        // 요청 설정 과정에서 발생한 오류
        return error.message || 'An error occurred';
      }
    },    
  }
}

export default mixin