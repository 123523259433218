<template>
  <div class="blind-wrap">
    <top-back-header-blind :propIsBack="true"></top-back-header-blind>
    <section>
      <div class="tit-wrap space-between">
        <div class="left">
          <ul class="name-list">
            <li>{{ datas.board.isAnonymous == '1' ? '익명게시자' : datas.board.wr_name }}</li>
            <li v-if="datas.board.isAnonymous != '1'">{{ MEMBER.jobNameBlind }}</li>
          </ul>
          <h3 class="tit-2">{{ datas.board.wr_subject }}</h3>
          <ul class="data-list">
            <li v-if="datas.board.hitCount > 0">조회 {{ datas.board.hitCount }}</li>
            <li v-if="datas.board.wr_comment > 0">댓글 {{ datas.board.wr_comment }}</li>
            <li>{{ datas.board.txtDate }}</li>
          </ul>
        </div>
        <div class="right">
          <router-link :to="{
            name: 'dolligoReportEdit', query: {
              recv_mb_nick: datas.board.g5_member && datas.board.g5_member.mb_id,
              bo_table: $route.params.boardId,
              wr_id: $route.params.wr_id
            }
          }" class="report-btn">신고</router-link>
        </div>
      </div>
      <div class="txt-wrap" style="white-space:pre-line;" v-if="datas.board.wr_option != 'html1'">{{
        datas.board.wr_content
      }}</div>
      <div class="txt-wrap" style="white-space:pre-line;" v-if="datas.board.wr_option == 'html1'"
        v-html="datas.board.wr_content"></div>
      <div class="btn-wrap">
        <button class="like" :class="{ on: datas.board.myGoodCount > 0 }" @click="setGood(datas.board)">
          <span class="count">{{ datas.board.wr_good }}</span>
          <strong>좋아요<span class="icon"></span></strong>
        </button>
        <button class="comment">
          <span class="count">{{ datas.board.wr_comment }}</span>
          <strong>댓글<span class="icon"></span></strong>
        </button>
      </div>

      <div class="admin-btn" v-if="MEMBER.mb_id == datas.board.mb_id || MEMBER.mb_level == 9">
        <router-link
          :to="{ name: 'blindBoardModify', params: { boardId: $route.params.boardId, wr_id: $route.params.wr_id } }">
          수정
        </router-link>
        <a href="javascript:void(0)" @click="del(datas.board.wr_id)">삭제</a>
        <a href="javascript:void(0)" v-if="MEMBER.mb_level == 9" class="copyurl" @click="copyUrl()">주소복사</a>
      </div>

      <div class="comment-wrap">
        <h3 class="tit-3">댓글
          <strong v-if="datas.board.wr_comment > 0">{{ datas.board.wr_comment }}</strong>
        </h3>
        <ul v-if="datas.board.wr_comment == 0">
          <li>
            <p class="empty-txt">댓글이 없습니다.</p>
          </li>
        </ul>
        <ul v-if="datas.board.wr_comment > 0">
          <li v-for="(reply, index) in datas.replies" v-bind:key="reply.wr_id" >
            <div class="comment" v-bind:class="{ reply: reply.wr_comment_reply }" :ref="`comment-${reply.wr_id}`">
              <div class="name-row">
                <span class="name">{{ reply.wr_name }}</span><span class="date">{{ reply.txtDate }}</span>
              </div>
              <div class="txt-row" style="white-space:pre-line;"
                v-html="reply.wr_content.replace(/([\@]((?![\s]).)*)/g, '<strong>$1</strong>')"></div>
              <div class="btn-row">
                <button class="reply" :class="{ on: datas.replies[index].is_reply == true }"
                  @click="clickReply(index)">답글</button>
                <button class="like" v-bind:class="{ on: reply.myGoodCount > 0 }" v-on:click="setGood(reply)">좋아요
                </button>
                <button class="del" v-if="MEMBER.mb_level == '9' || (reply && reply.mb_id == MEMBER.mb_id)"
                  @click="del(reply.wr_id)">삭제</button>
                <button class="report">신고</button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <div class="write-bar">
      <textarea class="write-comment" placeholder="댓글을 입력해주세요" maxlength="10000" ref="comment"
        v-model="datas.reply.wr_content" :style="{ height: datas.replyHeight }" @focus="datas.replyHeight = '200px'"
        @blur="datas.replyHeight = ''"></textarea>
      <button class="btn_submit" @click="save()">등록</button>
    </div>

    <spinner-blind ref="spinner"></spinner-blind>
  </div>
</template>


<script>
import mixin from '../../../mixin';
import axios from 'axios';
import $ from "jquery";
import clipboard from 'clipboard';
import SpinnerBlind from '@/components/common/SpinnerBlind.vue';
import TopBackHeaderBlind from '../../common/TopBackHeaderBlind.vue';

export default {
  components: {
    SpinnerBlind,
    TopBackHeaderBlind
  },
  mixins: [mixin],
  data: function () {
    return {
      datas: {
        // 게시물
        board: {
          wr_content: '',
          g5_member: {
            mb_id: '1'
          }
        },

        // 댓글 리스트
        replies: [],

        // 입력될 comment
        reply: {
          wr_subject: '',
          wr_content: '',
          wr_is_comment: '1',
          wr_parent: '',
          wr_comment: '',
          wr_option: '',
        },

        // 댓글 입력창 높이
        replyHeight: '',

        // 댓댓글 ID
        replyId: ''
      },
      blindBoardList: JSON.parse(sessionStorage.getItem('blindBoardList')),
    }
  },
  created: async function () {
    try {
      window.addEventListener('scroll', this.handleScroll);

      // 게시물 상세
      await this.getBoard();

      if(this.datas.board && this.datas.board.wr_id) {
        // 댓글
        this.datas.reply.wr_parent = this.datas.board.wr_parent;

        // 댓글리스트
        await this.getReplyList();

        // 조회수 증가
        await this.addReadCount();
      }
    } catch (error) {
      this.errorHandler(error);
    }
  },
  methods: {
    getReplyList: async function () {
      // 댓글리스트
      let axiosConfig = this.axiosConfig;
      axiosConfig.params = {
        wr_is_comment: '1',
        wr_parent: this.datas.board.wr_parent,
        order: 'blind'
      }
      let replies = await axios.get(`/api/dolligo/community/${this.$route.params.boardId}`, axiosConfig);
      replies = await replies.data.map(function (reply) {
        reply.is_reply = false;
        return reply;
      })
      this.datas.replies = replies;
    },
    copyUrl: function () {
      let clip = new clipboard('.copyurl', {
        text: function () {
          alert('주소가 복사 되었습니다.');
          return location.href;
        },
      });
      setTimeout(() => {
        clip.destroy();
      }, 500);
    },
    del: async function (wr_id) {
      try {
        if (confirm('삭제 하겠습니까?')) {
          await axios.delete(`/api/dolligo/community/${this.$route.params.boardId}/${wr_id}`, this.axiosConfig);
          if (wr_id == this.$route.params.wr_id) {
            if (this.blindBoardList) {
              let self = this;
              let boards = this.blindBoardList.boards.filter(function (e) {
                return e.wr_id !== self.$route.params.wr_id;
              })
              this.blindBoardList.boards = boards;
              sessionStorage.setItem('blindBoardList', JSON.stringify(this.blindBoardList));
              this.$router.push({
                name: 'blindBoardList'
              })
            } else {
              this.$router.push({
                name: 'blindBoardList'
              })
            }
          } else {
            await this.getReplyList();
            this.updateSession();

            let self = this;
            this.datas.replies.forEach((element) => {
              if (element.wr_id == self.datas.replyId) {
                element.is_reply = true;
              }
            });

            // 게시물 상세
            await this.getBoard();
          }
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },

    // 댓글 저장
    save: async function () {
      try {
        if (!this.datas.reply.wr_content.trim()) return false;

        this.$refs.spinner.isSpinnerShow = true;

        this.datas.reply.wr_content = this.datas.reply.wr_content.trim();
        let comment = await axios.post(`/api/dolligo/community/${this.$route.params.boardId}`, this.datas.reply, this.axiosConfig);
        comment = comment.data;
        await this.getReplyList();
        this.datas.reply.wr_content = '';
        this.datas.reply.wr_comment = '';
        this.datas.reply.wr_option = '';
        let self = this;
        setTimeout(() => {
          self.$refs.spinner.isSpinnerShow = false;
          if (self.$refs[`comment-${comment.wr_id}`]) {
            self.$refs[`comment-${comment.wr_id}`][0].scrollIntoView({ block: 'center', behavior: "smooth" });
          }
        }, 500);

        await this.getBoard(); // 게시물 상세
        this.updateSession();
      } catch (error) {
        this.errorHandler(error);
      }
    },

    // 조회수 증가
    addReadCount: async function () {
      let body = {
        wr_hit: this.datas.board.wr_hit + 1
      }
      await axios.put(`/api/dolligo/community/${this.$route.params.boardId}/${this.$route.params.wr_id}`, body, this.axiosConfig);
    },

    handleScroll: async function () {
      if ($(window).scrollTop() > 0) {
        $('header').addClass('fixed');
      } else {
        $('header').removeClass('fixed');
      }
    },

    // 좋아요 본글
    setGood: async function (item) {
      try {
        let data = {
          bo_table: this.$route.params.boardId,
          wr_id: item.wr_id,
          bg_flag: 'good'
        }
        if (item.myGoodCount == 0) await axios.post(`/api/dolligo/board/good`, data, this.axiosConfig);
        else await axios.delete(`/api/dolligo/board/good`, {
          headers: this.axiosConfig.headers,
          data: data
        });

        if (item.wr_is_comment == 0) await this.getBoard(); // 게시물 상세
        else {
          await this.getReplyList(); // 댓글 리스트
          let self = this;
          this.datas.replies.forEach((element) => {
            if (element.wr_id == self.datas.replyId) {
              element.is_reply = true;
            }
          });
        }

        this.updateSession();
      } catch (error) {
        this.errorHandler(error);
      }
    },

    // 게시물 상세
    getBoard: async function () {
      this.datas.board = await axios.get(`/api/dolligo/community/${this.$route.params.boardId}/${this.$route.params.wr_id}`, this.axiosConfig);
      this.datas.board = this.datas.board.data;
      if (!this.datas.board) {
        alert('존재하지 않는 글 입니다.');
        this.datas.board = {
          wr_content: '',
          g5_member: {
            mb_id: '1'
          }
        };
        this.$router.replace({ name: 'blindBoardList' });
      }
    },

    // click reply
    clickReply: async function (index) {
      this.datas.replies.forEach((element, idx) => {
        if (element.is_reply == true && index != idx) {
          this.datas.reply.wr_comment = '';
          this.datas.replies[idx].is_reply = false;
          this.datas.reply.wr_content = this.datas.reply.wr_content.split(`@${this.datas.replies[idx].wr_name} `).join('');
          this.datas.replyId = '';
        }
      });
      if (!this.datas.replies[index].is_reply) {
        this.datas.reply.wr_comment = this.datas.replies[index].wr_comment;
        this.datas.replies[index].is_reply = true;
        this.datas.reply.wr_content = `@${this.datas.replies[index].wr_name} ${this.datas.reply.wr_content}`;
        this.datas.replyId = this.datas.replies[index].wr_id;
        this.$refs.comment.focus();
      } else {
        this.datas.reply.wr_comment = '';
        this.datas.replies[index].is_reply = false;
        this.datas.reply.wr_content = this.datas.reply.wr_content.split(`@${this.datas.replies[index].wr_name} `).join('');
        this.datas.replyId = '';
      }
    },

    // 세션 수정
    updateSession: async function () {
      if (this.blindBoardList) {
        let self = this;
        this.blindBoardList.boards.forEach((element, index) => {
          if (element.wr_id == this.$route.params.wr_id) {
            self.blindBoardList.boards[index].wr_comment = self.datas.replies.length;
            self.blindBoardList.boards[index].wr_hit = self.datas.board.wr_hit;
            self.blindBoardList.boards[index].wr_good = self.datas.board.wr_good;
            self.blindBoardList.boards[index].hitCount = self.blindBoardList.boards[index].wr_hit.toLocaleString();
          }
        })
        sessionStorage.setItem('blindBoardList', JSON.stringify(this.blindBoardList));
      }
    },
  }
}
</script>

