<template>
  <div class="dolligo-wrap">
    <div v-if="showTerms2" v-bind:class="{ 'layer-wrap': isLayer == true }">
      <header class="fixed">
        <a href="javascript:void(0)" class="icon back-btn" v-on:click="$router.go(-1)">뒤로가기</a>
        <h1>개인정보 수집 및 이용</h1>
      </header>
      <div class="content-wrap bg-white">
        <div class="content-wrap">
          <div class="term-wrap">
            <ul>
              <li>
                <h1>1. 개인정보의 처리 목적</h1>
                <p>
                  (주)이공이공 (이하 ‘회사’) 는 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.<br />-
                  고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품
                  또는 서비스의 공급.배송 등
                </p>
              </li>
              <li>
                <h1>2. 개인정보의 처리 및 보유 기간</h1>
                <ol>
                  <li>
                    회사는 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유?이용기간 또는 법령에 따른 개인정보 보유?이용기간 내에서
                    개인정보를 처리?보유합니다
                  </li>
                  <li>
                    구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.<br />☞ 아래 예시를 참고하여 개인정보 처리업무와 개인정보 처리업무에 대한
                    보유기간 및 관련 법령, 근거 등을 기재합니다.<br />- 고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만
                    채권?채무관계 잔존시에는 해당 채권?채무관계 정산시까지<br />- 전자상거래에서의 계약?청약철회, 대금결제, 재화 등 공급기록 : 5년
                  </li>
                </ol>
              </li>
              <li>
                <h1>3. 정보주체와 법정대리인의 권리·의무 및 그 행사방법</h1>
                <p>
                  이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.<br />정보주체는 회사는 에 대해 언제든지 다음 각 호의 개인정보 보호
                  관련 권리를 행사할 수 있습니다.<br />1. 개인정보 열람요구<br />2. 오류 등이 있을 경우 정정 요구<br />3. 삭제요구<br />4. 처리정지
                  요구
                </p>
              </li>
              <li>
                <h1>4. 처리하는 개인정보의 항목 작성</h1>
                <p>회사는는 다음의 개인정보 항목을 처리하고 있습니다.<br />- 휴대 전화번호, 성별, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보</p>
              </li>
              <li>
                <h1>5. 개인정보의 파기</h1>
                <p>
                  회사는 원칙적으로 개인정보 이용목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. (여기서 ‘이용목적이 달성된 때’란
                  철회요청, 서비스계약 만료, 탈퇴 시를 의미.)<br />
                  파기 절차는 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류)<br />
                  내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다.<br />
                  이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
                </p>
                <h3>1) 정보주체와 법정대리인의 권리 의무 및 행사 방법</h3>
                <p>
                  이용자는 언제든지 개인정보를 조회하거나 수정할 수 있고 수집/이용에 대한 동의 철회 또는 가입 해지를 요청 할 수 있습니다. <br />
                  서비스 내 설정 기능을 통한 변경, 가입해지(동의철회)를 위해서 아래의 경로를 따를 수 있습니다.<br />
                  - 개인정보 조회 : 인사교류 > 메뉴 > 회원정보 > 회원정보 입력/수정<br />
                  - 개인정보 변경 : 인사교류 > 메뉴 > 회원정보 > 회원정보 입력/수정<br />
                  - 동의철회 : 인사교류 > 메뉴 > 회원정보 > 회원 탈퇴하기 (공블리 > 프로필 > 회원정보 변경 > 회원탈퇴)
                </p>
              </li>
              <li>
                <h1>6. 개인정보 처리업무의 위탁에 관한 사항</h1>
                <h3>1) 수탁업체</h3>
                - (주)써머스플랫폼 스윗트래커(비즈엠) : 알림톡 발송<br />
                - Amazon Web Services, Inc. : 정보 보관
              </li>
              <li>
                <h1>7. 개인정보 보호책임자 작성</h1>
                <p>
                  회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
                  개인정보 보호책임자를 지정하고 있습니다.<br /><br />▶ 개인정보 보호책임자 <br />성명 : 최용민 <br />연락처 : dolligo3@gmail.com
                </p>
              </li>
              <li>
                <h1>8. 개인정보 처리방침 변경</h1>
                <p>
                  이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일
                  전부터 공지사항을 통하여 고지할 것입니다.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terms2',
  props: {
    isLayer: Boolean,
    showTerms: Boolean,
  },
  data: function () {
    return {
      showTerms2: this.isLayer == false ? true : this.showTerms,
    };
  },
};
</script>
