<template>
  <div id="app" class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="$route.params.type == 'plus' ? '매너' : '비매너'"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="top-txt">
        <h2><strong>{{ member.mb_nick }}</strong>님과<br>교류활동에서 {{ $route.params.type == 'plus' ? '좋았던' : '불편했던' }} 점을 선택해주세요.
        </h2>
      </div>
      <div class="write-wrap">
        <div class="list-wrap">
          <ul v-if="$route.params.type == 'plus'">
            <li>
              <label for="plus_type1" class="form-check-label">
                <input type="checkbox" id="plus_type1" class="form-check-input" true-value="1" false-value="0"
                  v-model="mannerScore.plus_type1">채팅, 쪽지 답변이
                빨라요.<span class="form-check-sign"><span class="check"></span></span>
              </label>
            </li>
            <li>
              <label for="plus_type2" class="form-check-label">
                <input type="checkbox" id="plus_type2" class="form-check-input" true-value="1" false-value="0"
                  v-model="mannerScore.plus_type2">친절하고 매너가
                좋아요.<span class="form-check-sign"><span class="check"></span></span>
              </label>
            </li>
          </ul>

          <ul v-if="$route.params.type == 'minus'">
            <li>
              <label for="minus_type1" class="form-check-label">
                <input type="checkbox" id="minus_type1" class="form-check-input" true-value="1" false-value="0"
                  v-model="mannerScore.minus_type1">채팅, 쪽지 답변이 없어요.<span class="form-check-sign"><span
                    class="check"></span></span>
              </label>
            </li>
            <li>
              <label for="minus_type2" class="form-check-label">
                <input type="checkbox" id="minus_type2" class="form-check-input" true-value="1" false-value="0"
                  v-model="mannerScore.minus_type2">답변이 너무 늦어요.<span class="form-check-sign"><span
                    class="check"></span></span>
              </label>
            </li>
            <li>
              <label for="minus_type3" class="form-check-label">
                <input type="checkbox" id="minus_type3" class="form-check-input" true-value="1" false-value="0"
                  v-model="mannerScore.minus_type3">인사교류 약속을 하고 연락이 안 돼요.<span class="form-check-sign"><span
                    class="check"></span></span>
              </label>
            </li>
            <li>
              <label for="minus_type4" class="form-check-label">
                <input type="checkbox" id="minus_type4" class="form-check-input" true-value="1" false-value="0"
                  v-model="mannerScore.minus_type4">욕설, 비방 등의 언행을 했어요.<span class="form-check-sign"><span
                    class="check"></span></span>
              </label>
            </li>
          </ul>

        </div>
        <button class="submit-btn" v-bind:class="{ on: isSubmit() == true }" @click="save()">평가 완료</button>
        <a href="javascript:void(0)" class="low-btn" @click="$router.back()">취소</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '../../../mixin'
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin],
  data: function () {
    return {
      type: this.$route.params.type,
      mannerScore: {},
      member: {},
    }
  },
  created: async function () {
    await this.get();
    // 회원
    let member = await axios.get(`/api/member/${this.$route.query.reg_mb_id}`, this.axiosConfig);
    this.member = member.data;
  },
  methods: {
    isSubmit: function () {
      if (this.$route.params.type == 'plus') {
        if (this.mannerScore.plus_type1 == '1' || this.mannerScore.plus_type2 == '1') return true;
        else return false;
      } else {
        if (this.mannerScore.minus_type1 == '1' || this.mannerScore.minus_type2 == '1' || this.mannerScore.minus_type3 == '1' || this.mannerScore.minus_type4 == '1') return true;
        else return false;
      }
    },
    get: async function () {
      try {
        this.axiosConfig.params.reg_mb_id = this.$route.query.reg_mb_id;
        let mannerScore = await axios.get(`/api/manner-score`, this.axiosConfig);
        this.mannerScore = mannerScore.data.length > 0 ? mannerScore.data[0] : {};
      } catch (error) {
        this.errorHandler(error);
      }
    },
    save: async function () {
      try {
        if (!this.isSubmit()) return false;
        if (this.mannerScore.mb_id) {
          await axios.patch(`/api/manner-score`, {
            plus_type1: this.mannerScore.plus_type1,
            plus_type2: this.mannerScore.plus_type2,
            minus_type1: this.mannerScore.minus_type1,
            minus_type2: this.mannerScore.minus_type2,
            minus_type3: this.mannerScore.minus_type3,
            minus_type4: this.mannerScore.minus_type4,
            reg_mb_id: this.$route.query.reg_mb_id
          }, {
            headers: this.axiosConfig.headers
          });
        } else {
          await axios.post(`/api/manner-score`, {
            plus_type1: this.mannerScore.plus_type1,
            plus_type2: this.mannerScore.plus_type2,
            minus_type1: this.mannerScore.minus_type1,
            minus_type2: this.mannerScore.minus_type2,
            minus_type3: this.mannerScore.minus_type3,
            minus_type4: this.mannerScore.minus_type4,
            reg_mb_id: this.$route.query.reg_mb_id
          }, {
            headers: this.axiosConfig.headers
          });
        }
        await this.get();
        this.$router.replace({
          name: 'dolligoMemberMannerScoreView',
          query: {
            reg_mb_id: this.$route.query.reg_mb_id
          }
        })
      } catch (error) {
        this.errorHandler(error);
      }
    }
  }
}
</script>

