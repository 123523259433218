<template>
  <div class="blind-wrap">
    <top-back-header-blind :propIsBack="true" :propTitle="`글쓰기`" ref="topHeader"
      :propSubTitle="$route.params.boardId == 'free' ? '자유게시판' : '공무원이슈'"></top-back-header-blind>
    <section class="mgbt0 pd0">
      <div class="write-wrap">
        <ul>
          <li v-if="$route.params.boardId == 'free'">
            <div class="check-block">
              <label for="isAnonymous" class="form-check-label">
                <input type="checkbox" id="isAnonymous" class="form-check-input" v-model="datas.board.isAnonymous"
                  true-value="1" false-value="0">익명으로 글쓰기
                <span class="form-check-sign"><span class="check"></span></span>
              </label>
              <p>닉네임, 직렬이 노출되지 않습니다.</p>
            </div>
          </li>
          <li v-if="!$route.params.wr_id && $route.params.boardId == 'free'">
            <div class="check-block">
              <label for="isQuestionPush" class="form-check-label">
                <input type="checkbox" id="isQuestionPush" class="form-check-input" v-model="datas.board.isQuestionPush"
                  true-value="1" false-value="0">내직렬에게 문의하기
                <span class="form-check-sign"><span class="check"></span></span>
              </label>
              <p>내직렬({{ MEMBER.jobNameBlind }}) 분들께 알림이 보내집니다.</p>
            </div>
          </li>
          <li v-if="MEMBER.mb_level == '9'">
            <div class="check-block">
              <label for="isNotice" class="form-check-label">
                <input type="checkbox" id="isNotice" class="form-check-input" v-model="datas.board.isNotice"
                  v-bind:checked="datas.board.isNotice == '1'" true-value="1" false-value="0">공지로 등록하기
                <span class="form-check-sign"><span class="check"></span></span>
              </label>
              <p>공지로 등록됩니다.</p>
            </div>
          </li>
          <li v-if="MEMBER.mb_level == '9'">
            <div class="check-block">
              <label for="wr_option" class="form-check-label">
                <input type="checkbox" id="wr_option" class="form-check-input" v-model="datas.board.wr_option"
                  true-value="html1" false-value="">html 허용해서 표현하기
                <span class="form-check-sign"><span class="check"></span></span>
              </label>
              <p>html태그가 허용됩니다.</p>
            </div>
          </li>
          <li><input type="text" placeholder="제목" v-model="datas.board.wr_subject" required maxlength="150" /></li>
          <li><textarea placeholder="내용을 입력해주세요" v-model="datas.board.wr_content" required></textarea></li>
          <li v-if="$route.params.boardId == 'issue'">
            <div class="vote-block">
              <input type="text" v-model="datas.board.wr_1" placeholder="문항 1번">
              <input type="text" v-model="datas.board.wr_2" placeholder="문항 2번">
              <input type="text" v-model="datas.board.wr_3" placeholder="문항 3번">
              <input type="text" v-model="datas.board.wr_4" placeholder="문항 4번">
              <input type="text" v-model="datas.board.wr_5" placeholder="문항 5번">
            </div>
          </li>
        </ul>
      </div>
    </section>
    <spinner-blind ref="spinner"></spinner-blind>
  </div>
</template>

<script>
import mixin from '@/mixin';
import axios from 'axios';
import TopBackHeaderBlind from '../../common/TopBackHeaderBlind.vue';
import SpinnerBlind from '@/components/common/SpinnerBlind.vue';

export default {
  components: { SpinnerBlind, TopBackHeaderBlind },
  mixins: [mixin],
  data: function () {
    return {
      datas: {
        board: {
          isAnonymous: '',
          isQuestionPush: '',
          isNotice: '0',
          wr_option: '',
          wr_subject: '',
          wr_content: '',
          wr_is_comment: '0',
          wr_1: '',
          wr_2: '',
          wr_3: '',
          wr_4: '',
          wr_5: '',
        }
      },
    }
  },
  mounted: async function () {
    // this.$refs.topHeader.isSave = true;
    this.getBoard();
  },
  methods: {
    getBoard: async function () {
      if (this.$route.params.wr_id) {
        let board = await axios.get(`/api/dolligo/community/${this.$route.params.boardId}/${this.$route.params.wr_id}`, this.axiosConfig);
        board = board.data;
        if (!board) {
          alert('존재하지 않는 글 입니다.');
          this.$router.replace({ name: 'blindBoardList' });
        }
        this.datas.board.isAnonymous = board.isAnonymous;
        this.datas.board.isQuestionPush = board.isQuestionPush;
        this.datas.board.isNotice = board.isNotice;
        this.datas.board.wr_option = board.wr_option;
        this.datas.board.wr_subject = board.wr_subject;
        this.datas.board.wr_content = board.wr_content;
        this.datas.board.wr_is_comment = board.wr_is_comment;
        this.datas.board.wr_1 = board.wr_1;
        this.datas.board.wr_2 = board.wr_2;
        this.datas.board.wr_3 = board.wr_3;
        this.datas.board.wr_4 = board.wr_4;
        this.datas.board.wr_5 = board.wr_5;
      }
    },
    isSave: function () {
      if (!this.datas.board.wr_subject.trim()) return false;
      if (!this.datas.board.wr_content.trim()) return false;
      return true;
    },
    save: async function () {
      try {
        if (this.isSave() == false) return false;

        this.$refs.spinner.isSpinnerShow = true;
        if (this.$route.params.wr_id) {
          await axios.put(`/api/dolligo/community/${this.$route.params.boardId}/${this.$route.params.wr_id}`, this.datas.board, this.axiosConfig);
          sessionStorage.removeItem(this.FROM.name);
          setTimeout(() => {
            this.$router.replace({ name: 'blindBoardView', params: { boardId: this.$route.params.boardId, wr_id: this.$route.params.wr_id } })
          }, 1000);
        }
        else {
          let board = await axios.post(`/api/dolligo/community/${this.$route.params.boardId}`, this.datas.board, this.axiosConfig);
          sessionStorage.removeItem(this.FROM.name);
          setTimeout(() => {
            this.$router.replace({ name: 'blindBoardView', params: { boardId: this.$route.params.boardId, wr_id: board.data.wr_id } });
          }, 1000);
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
  }
}
</script>

