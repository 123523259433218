<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`알림`"></top-back-header-dolligo>
    <div class="content-wrap bg-white" id="content-wrap">
      <ul class="page-info-list">
        <li>알림 메세지는 7일 후 자동 삭제됩니다.</li>
      </ul>
      <ul class="noti-list">
        <li v-for="(element, index) in datas.pushList" v-bind:key="element.pl_id">
          <router-link :to="element.url.substr(element.url.indexOf('/', 10))" class="list" :class="{ new: element.isview == '0' }"
            @click.native="setView(element.pl_id, index)">
            <span :class="`icon face${element.faceType}`"></span>
            <span class="noti-txt">{{ element.message }}</span>
            <span class="noti-date">{{ element.txtDate }}</span>
          </router-link>
        </li>
        <li v-if="datas.pushList.length == 0"><a href="javascript:void(0);" class="list"
            style="text-align: center; padding:20px 24px 20px 14px;">알림이 없습니다</a></li>
        <infinite-loading :identifier="datas.infiniteId" @infinite="getPushList" ref="infiniteLoading">
          <div slot="no-more"></div>
          <li slot="no-results"></li>
        </infinite-loading>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '../../../mixin';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';
export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin],
  data: function () {
    return {
      datas: {
        pushList: [],
        infiniteId: +new Date(),
        last_pl_id: ''
      }
    }
  },
  created: async function () {
    await this.getSession();
  },
  methods: {
    getPushList: async function ($state) {
      try {
        this.axiosConfig.params.flag = '1';
        this.axiosConfig.params.last_pl_id = this.datas.last_pl_id;
        let pushList = await axios.get('/api/dolligo/push-list', this.axiosConfig);
        pushList = pushList.data;

        if (pushList.length == 0) {
          $state.complete();
        } else {
          this.datas.last_pl_id = pushList[pushList.length - 1].pl_id;
          this.datas.pushList.push(...pushList);
          $state.loaded();
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    setView: async function (pl_id, index) {
      try {
        let routeName = this.$route.name;
        await axios.patch(`/api/dolligo/push-list/${pl_id}`, {
          isview: '1'
        }, {
          headers: this.axiosConfig.headers
        });
        this.datas.pushList[index].isview = '1';
        this.saveSession(routeName);
      } catch (error) {
        this.errorHandler(error);
      }
    }
  }
}
</script>

