import DolligoPushlistIndex from '@/components/dolligo/pushlist/Index';

const dolligoPushlist = [{
  path: '/dolligo/push-list',
  name: 'dolligoPushlist',
  component: DolligoPushlistIndex,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}]

export default dolligoPushlist