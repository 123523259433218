<template>
  <div class="blind-wrap">
    <top-back-header-blind :propIsBack="true" :propTitle="`인증 퀴즈`"></top-back-header-blind>
    <div class="form-wrap">
      <div class="container">
        <h2 class="tit-2">공무원 인증을 위해<br>아래 문제의 정답을 맞춰주세요.</h2>
        <ul>
          <li>
            <h3 class="tit-4">Q1. 공공기관에서 업무를 처리하기 위해 사용하는 시ㆍ군ㆍ구 행정정보시스템은?</h3>
            <div class="input-wrap">
              <select v-model="quiz1">
                <option value="">선택해주세요</option>
                <option value="세월행정시스템">세월행정시스템</option>
                <option value="새올행정시스템">새올행정시스템</option>
                <option value="소월행정시스템">소월행정시스템</option>
              </select>
            </div>
          </li>
          <li>
            <h3 class="tit-4">Q2. 정부 부처와 지방자치단체 공무원이 사용하는 전자정부 시스템은?</h3>
            <div class="input-wrap">
              <select v-model="quiz2">
                <option value="">선택해주세요</option>
                <option value="오-나라">오-나라</option>
                <option value="감-나라">감-나라</option>
                <option value="온-나라">온-나라</option>
              </select>
            </div>
          </li>
          <li>
            <h3 class="tit-4">Q3. 일반직 9급 공무원의 호칭 방법은?</h3>
            <div class="input-wrap">
              <select v-model="quiz3">
                <option value="">선택해주세요</option>
                <option value="서기">서기</option>
                <option value="서기보">서기보</option>
                <option value="딸바보">딸바보</option>
                <option value="주사보">주사보</option>
              </select>
            </div>
          </li>
        </ul>
        <button to="/branch" class="submit-btn" :class="{on: isSubmit()}" @click="goMemberEdit()">회원가입하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '@/mixin';
import TopBackHeaderBlind from '../../common/TopBackHeaderBlind.vue';
export default {
  components: { TopBackHeaderBlind },
  mixins: [mixin],
  data: function () {
    return {
      quiz1: '',
      quiz2: '',
      quiz3: '',
    }
  },
  created: function () {
    if(this.MEMBER.b_mb_pass == '1') this.$router.replace({ name: 'blindMemberEdit' });
  },
  methods: {
    isSubmit: function () {
      if (this.quiz1 != '새올행정시스템') return false;
      if (this.quiz2 != '온-나라') return false;
      if (this.quiz3 != '서기보') return false;
      return true;
    },
    goMemberEdit: async function () {
      if (this.isSubmit() == false) return;
      let body = { b_mb_pass: '1' };
      await axios.patch('/api/member', body, this.axiosConfig);
      this.$router.push({ name: 'blindMemberEdit' });
    }
  }
}
</script>
  
