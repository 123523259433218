import DolligoCommunityIndex from '@/components/dolligo/community/Index';
import DolligoCommunityView from '@/components/dolligo/community/View';
import DolligoCommunityEdit from '@/components/dolligo/community/Edit';

const dolligoCommunity = [{
  path: '/dolligo/community/edit/:boardId',
  name: 'dolligoCommunityRegist',
  component: DolligoCommunityEdit,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/community/edit/:boardId/:wr_id',
  name: 'dolligoCommunityEdit',
  component: DolligoCommunityEdit,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/community/:boardId',
  name: 'dolligoCommunity',
  component: DolligoCommunityIndex,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/community/:boardId/:wr_id',
  name: 'dolligoCommunityView',
  component: DolligoCommunityView,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}]

export default dolligoCommunity