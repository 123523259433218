import BlindMemberQuiz from '@/components/blind/member/quiz.vue';
import BlindMemberEdit from '@/components/blind/member/edit.vue';
import BlindMemberTerm1 from '@/components/blind/member/term1.vue';
import BlindMemberTerm2 from '@/components/blind/member/term2.vue';
import BlindMemberTerm3 from '@/components/blind/member/term3.vue';

const blindMember = [{
  path: '/blind/member/quiz',
  name: 'blindMemberQuiz',
  component: BlindMemberQuiz,
  meta: {
    checkAgent: true,
    checkAuth: true,
  }
}, {
  path: '/blind/member/edit',
  name: 'blindMemberEdit',
  component: BlindMemberEdit,
  meta: {
    checkAgent: true,
    checkAuth: true,
  }
}, {
  path: '/blind/member/term1',
  name: 'blindMemberTerm1',
  component: BlindMemberTerm1,
  meta: {
    checkAgent: true,
    checkAuth: true,
  }
}, {
  path: '/blind/member/term2',
  name: 'blindMemberTerm2',
  component: BlindMemberTerm2,
  meta: {
    checkAgent: true,
    checkAuth: true,
  }
}, {
  path: '/blind/member/term3',
  name: 'blindMemberTerm3',
  component: BlindMemberTerm3,
  meta: {
    checkAgent: true,
    checkAuth: true,
  }
}]

export default blindMember