<template>
  <div class="dolligo-wrap">
    <header>
      <dolligo-top></dolligo-top>
      <h1>매칭 찾기</h1>
    </header>
    <div class="content-wrap bg-white">
      <ul class="page-info-list">
        <li>기본 매칭 조건은 지역과 직렬로 이루어집니다.</li>
        <li>교류희망 OFF 상태 교류자는 매칭결과에 포함되지 않습니다.</li>
      </ul>

      <div class="write-wrap" v-if="MEMBER.mb_6 == 'T'">
        <div class="list-wrap">
          <h2>희망 근무지</h2>
          <ul>
            <li id="apply">
              <label v-for="(apply, index) in MEMBER.g5_z_applies" v-bind:key="apply.wr_id" class="submit-btn district"
                v-bind:class="{ on: datas.wr_5 === (index + 1) }" :id="`apply${index + 1}`">
                <input type="radio" :for="`apply${index + 1}`" class="form-check-input" v-model="datas.wr_5"
                  :value="index + 1" />
                <span>{{ index + 1 }}지망</span>
                <strong>{{ apply.wr_4 }}</strong>
              </label>
            </li>
            <li>
              <label class="submit-btn district" :class="{ on: datas.isTimeSelect }" id="time">
                <input type="checkbox" for="time" class="form-check-input" v-model="datas.isTimeSelect" />
                <strong>시간선택제 공무원</strong>
              </label>
              <label class="submit-btn district" :class="{ on: datas.isCityMatch }" id="city">
                <input type="checkbox" for="city" class="form-check-input" v-model="datas.isCityMatch" />
                <strong>광역시도 매칭</strong>
              </label>
            </li>
            <li>
              <label class="submit-btn district" :class="{ on: datas.mb_8 == '지방직' }" id="local">
                <input type="checkbox" for="local" class="form-check-input" v-model="datas.mb_8" true-value="지방직"
                  false-value="" />
                <strong>지방직</strong>
              </label>

              <label class="submit-btn district" :class="{ on: datas.mb_8 == '국가직' }" id="national">
                <input type="checkbox" for="national" class="form-check-input" v-model="datas.mb_8" true-value="국가직"
                  false-value="" />
                <strong>국가직</strong>
              </label>
            </li>
            <li>
              <label class="person2-btn" :class="{ on: datas.way == '2' }" id="way2">
                <input type="checkbox" for="way2" class="form-check-input" v-model="datas.way" true-value="2"
                  false-value="" />
                2자
              </label>
              <label class="person3-btn" :class="{ on: datas.way == '3' }" id="way3">
                <input type="checkbox" for="way3" class="form-check-input" v-model="datas.way" true-value="3"
                  false-value="" />
                3자
              </label>
              <label class="person4-btn" :class="{ on: datas.way == '4' }" id="way4">
                <input type="checkbox" for="way4" class="form-check-input" v-model="datas.way" true-value="4"
                  false-value="" />
                4자
              </label>
              <label class="person5-btn" :class="{ on: datas.way == '5' }" id="way5">
                <input type="checkbox" for="way5" class="form-check-input" v-model="datas.way" true-value="5"
                  false-value="" />
                5자
              </label>
            </li>
          </ul>
        </div>
        <div class="list-wrap">
          <h2>상세 매칭 조건<span class="icon premium-i"></span></h2>
          <h3>검색할 연령대를 선택해 주세요.</h3>
          <ul>
            <li>
              <div class="slider-wrap">
                <div id="slider-range"></div>
                <div class="age-wrap">
                  <span class="age">20세</span>
                  <span class="age">25세</span>
                  <span class="age">30세</span>
                  <span class="age">35세</span>
                  <span class="age">40세</span>
                  <span class="age">45세</span>
                  <span class="age">50세</span>
                </div>
              </div>
            </li>
            <li>
              <label class="submit-btn district" :class="{ on: datas.isSameRank }" id="rank">
                <input type="checkbox" for="rank" class="form-check-input" v-model="datas.isSameRank"
                  @change="isPremiunCheck()" />
                <strong>동일 직급</strong>
              </label>
              <label class="submit-btn district" :class="{ on: datas.isSameSex }" id="sex">
                <input type="checkbox" for="sex" class="form-check-input" v-model="datas.isSameSex"
                  @change="isPremiunCheck()" />
                <strong>동일 성별</strong>
              </label>
            </li>
            <li>
              <label class="submit-btn district" :class="{ on: datas.isPossibleMove }" id="move">
                <input type="checkbox" for="move" class="form-check-input" v-model="datas.isPossibleMove"
                  @change="isPremiunCheck()" />
                <strong>전보 가능</strong>
              </label>
              <label class="submit-btn district" :class="{ on: datas.isPossibleDown }" id="down">
                <input type="checkbox" for="down" class="form-check-input" v-model="datas.isPossibleDown"
                  @change="isPremiunCheck()" />
                <strong>강임 허용</strong>
              </label>
            </li>
          </ul>
        </div>
        <button class="submit-btn on" @click="searchMatching()">매칭 찾기</button>
      </div>

      <div class="result-wrap">
        <h2 class="list-tit" v-if="datas.isSearch && datas.matchMembers.length > 0 && MEMBER.mb_6 == 'T'">
          <strong>{{
            datas.matchMembers.length
          }}</strong>건의 매칭 결과</h2>
        <ul class="result-list" v-if="datas.isSearch && datas.matchMembers.length > 0 && MEMBER.mb_6 == 'T'">
          <li v-for="(members, index) in datas.matchMembers" v-bind:key="index" ref="match">
            <a href="javascript:void(0);" :class="members[0].fold" @click="fold(index)">
              <span class="choice">{{ members[0].wr_5 }}지망</span>
              <strong class="district">{{ members[0].wr_4 }}</strong>
              <ol>
                <li v-for="(member, idx) in members" v-bind:key="idx"
                  :class="member.g5_member.mb_id == MEMBER.mb_id ? `icon face${member.g5_member.mb_36} on` : `icon face${member.g5_member.mb_36}`">
                  {{ member.g5_member.mb_nick }}</li>
              </ol>
              <span class="icon arrow"></span>
            </a>
            <ol class="unfold-list" :style="members[0].fold == 'fold' ? 'display:none' : ''"
              v-if="MEMBER.mb_level >= 5 || members.length == 2">
              <li v-for="(member, idx) in members" v-bind:key="idx">
                <router-link :to="{ name: 'dolligoMemberProfile', params: { id: member.mb_id } }" class="infor-btn"
                  :class="{ on: member.mb_id == MEMBER.mb_id }" @click.native="saveSession()">
                  <span class="infor-box">
                    <span
                      :class="member.mb_id == MEMBER.mb_id ? `icon face${member.g5_member.mb_36} on` : `icon face${member.g5_member.mb_36}`">나</span>
                    <span class="value">{{ member.g5_member.mb_8 }}∙{{ member.g5_member.jobName }}∙{{
                      member.g5_member.rankName
                    }}∙{{ member.g5_member.sex }}</span>
                    <strong class="name">{{ member.g5_member.mb_nick }}</strong>
                  </span>
                  <span class="right-box">
                    <span class="now-box">
                      <span class="district">{{ member.wr_2.split(' ')[0] }}</span>
                      <span class="district">{{ member.wr_2.split(' ')[1] }}</span>
                    </span>
                    <span class="goal-box">
                      <span class="district">{{ member.wr_4.split(' ')[0] }}</span>
                      <span class="district">{{ member.wr_4.split(' ')[1] }}</span>
                    </span>
                  </span>
                </router-link>
              </li>
            </ol>
            <button v-if="MEMBER.mb_level >= 5" class="submit-btn p-on" :class="{ none: members[0].fold == 'fold' }"
              @click="startChat(members)">채팅 시작하기</button>

            <router-link v-if="MEMBER.mb_level < 5 && members.length == 2"
              :to="{ name: 'dolligoMemoView', params: { mb_id: members[1].mb_id } }" class="submit-btn on"
              :class="{ none: members[0].fold == 'fold' }" @click.native="saveSession()">쪽지 보내기</router-link>

            <p v-if="MEMBER.mb_level < 5 && members.length != 2" class="unfold-empty"
              :class="{ none: members[0].fold == 'fold' }">프리미엄 멤버십에 가입하시면<br>매칭 조합 열람이 가능합니다.</p>
            <router-link v-if="MEMBER.mb_level < 5 && members.length > 2" :to="{ name: 'dolligoPremiumIntro' }"
              class="submit-btn p-on" :class="{ none: members[0].fold == 'fold' }" @click.native="saveSession()">
              프리미엄 멤버십 가입하기
            </router-link>
          </li>
        </ul>


        <div class="empty-states" ref="empty-states1" v-if="datas.isSearch && datas.matchMembers.length == 0 && MEMBER.mb_6 == 'T'">
          <p><strong>매칭 결과가 없습니다.</strong><br>많은 사람들이 참여할수록 매칭 확률이 높아집니다.<br />주변에 앱을 알려주세요.</p>
          <router-link :to="{ name: 'dolligoMemberWishArea' }" class="link-btn">희망 근무지 변경하기</router-link>
          <a href="javascript:void(0)" class="share-btn" @click="shareApp()">앱 공유하기</a>
        </div>

        <div class="empty-states" ref="empty-states2" v-if="MEMBER.mb_6 == 'F'">
          <p>매칭을 위해서 교류희망을 선택해주세요.</p>
          <router-link :to="{ name: 'dolligoSetting' }" class="link-btn">교류 희망 선택하기</router-link>
          <a href="javascript:void(0)" class="share-btn" @click="shareApp()">앱 공유하기</a>
        </div>

      </div>
    </div>
    <spinner ref="spinner"></spinner>
    <dolligo-footer propType="dolligoMatch"></dolligo-footer>
  </div>
</template>

<script>
import DolligoTop from '../../common/DolligoTop.vue';
import mixin from '../../../mixin';
import axios from 'axios';
import DolligoFooter from '../../common/DolligoFooter.vue';
import $ from 'jquery';
import Spinner from '../../common/Spinner.vue';

export default {
  mixins: [mixin],
  data: function () {
    return {
      datas: {
        wr_5: 1, // 1지망, 2지망, 3지망
        way: 2, // 매칭 2자, 3자, 4자, 5자
        isCityMatch: false,
        isTimeSelect: false,
        mb_8: '', // 지방직 or 국가직
        s_age: 20,
        e_age: 50,
        isSameRank: false,
        isSameSex: false,
        isPossibleMove: false,
        isPossibleDown: false,
        matchMembers: [],
        isSearch: false,
      },
      sliderValue: 500,
    }
  },
  components: {
    DolligoTop,
    DolligoFooter,
    Spinner
  },
  mounted: function () {
    let self = this;
    self.initAgeSlider();
  },
  created: async function () {
    await this.getSession();
  },
  methods: {
    test: function () {
      console.log(this.datas.s_age);
      console.log(this.datas.e_age);
      console.log(this.datas.way);
    },
    initAgeSlider: function () {
      let self = this;
      $("#slider-range").slider({
        range: true,
        min: 20,
        max: 50,
        step: 5,
        values: [this.datas.s_age, this.datas.e_age],
        stop: function (event, ui) {
          event;
          self.datas.s_age = ui.values[0];
          self.datas.e_age = ui.values[1];
        },
        change: function (event, ui) {
          event;
          if (!(ui.values[0] == 20 && ui.values[1] == 50))
            self.isPremiunCheck();
        }
      });
    },
    searchMatching: async function () {
      try {
        this.$refs.spinner.show = true;
        let hostName = '';
        if (location.hostname == 'www-dev.gongdoll.com' || location.hostname == 'www-dev.gongdoll.com') hostName = 'https://cron-dev.gongdoll.com';
        else if (location.hostname == 'www.gongdoll.com' || location.hostname == 'www.gongdoll.com') hostName = 'https://cron.gongdoll.com';
        let matchMembers = await axios.post(`${hostName}/api/dolligo/match`, this.datas, this.axiosConfig);
        matchMembers = matchMembers.data;
        matchMembers = await matchMembers.map(function (matchMember) {
          matchMember[0].fold = 'fold';
          return matchMember;
        })
        this.$refs.spinner.show = false;
        this.datas.matchMembers = matchMembers;
        this.datas.isSearch = true;
        setTimeout(() => {
          if (this.$refs.match) this.$refs[`match`][0].scrollIntoView({ block: 'center', behavior: "smooth" });
          else if (this.MEMBER.mb_6 == 'T') this.$refs['empty-states1'].scrollIntoView({ block: 'center', behavior: "smooth" });
          else if (this.MEMBER.mb_6 == 'F') this.$refs['empty-states2'].scrollIntoView({ block: 'center', behavior: "smooth" });
        }, 200);
      } catch (error) {
        this.$refs.spinner.show = false;
        this.errorHandler(error);
      }
    },
    fold: async function (index) {
      if (this.datas.matchMembers[index][0].fold == 'fold') {
        this.datas.matchMembers[index][0].fold = 'unfold';
      } else {
        this.datas.matchMembers[index][0].fold = 'fold';
      }
    },
    isPremiunCheck: function () {
      if (this.MEMBER.mb_level < 5) {
        if (confirm("상세정보 및 근무환경 평가 입력 후\n프리미엄 멤버십 무료 체험이 가능합니다.\n입력 하겠습니까?")) {
          $("#slider-range").slider({
            values: [20, 50]
          });
          this.datas.s_age = 20;
          this.datas.e_age = 50;
          this.datas.isSameRank = false;
          this.datas.isSameSex = false;
          this.datas.isPossibleMove = false;
          this.datas.isPossibleDown = false;
          this.saveSession();
          this.$router.push({
            name: 'dolligoPremiumIntro',
          });
        } else {
          $("#slider-range").slider({
            values: [20, 50]
          });
          this.datas.s_age = 20;
          this.datas.e_age = 50;
          this.datas.isSameRank = false;
          this.datas.isSameSex = false;
          this.datas.isPossibleMove = false;
          this.datas.isPossibleDown = false;
        }
      }
    },
    startChat: async function (members) {
      try {
        let applyIDs = [];
        members.forEach(element => {
          applyIDs.push(element.wr_id);
        });
        applyIDs.sort();
        applyIDs = applyIDs.join('_');
        let data = {
          applyIDs: applyIDs
        }
        let chatGroup = await axios.post(`/api/dolligo/chatgroup`, data, this.axiosConfig);
        if (chatGroup) {
          this.saveSession();
          this.$router.push({ name: 'dolligoChatView2', params: { chatGroupCode: chatGroup.data.chat_group_code } })
          // location.href = `/dolligo/chat/${chatGroup.data.chat_group_code}`;
        }
      } catch (error) {
        this.errorHandler(error);
      }
    }
  }
}
</script>

