import Vue from 'vue'
import Router from 'vue-router'
import dolligoMain from './dolligoMain';
import dolligoPremium from './dolligoPremium';
import dolligoReview from './dolligoReview';
import dolligoCommunity from './dolligoCommunity';
import dolligoMatch from './dolligoMatch';
import dolligoMemo from './dolligoMemo';
import dolligoChat from './dolligoChat';
import dolligoMember from './dolligoMember';
import blindMember from './blindMember';
import blindBoard from './blindBoard';
import axios from 'axios';
import mixin from '../mixin';
import dolligoPushlist from './dolligoPushlist'
import dolligoSetting from './dolligoSetting'
import blindPushlistIndex from './blindPushlist';
import blindSettingIndex from './blindSetting';
import dolligoReport from './dolligoReport';
import dolligo from './dolligo'
import blind from './blind';
import NotFound from '@/components/NotFound';

Vue.use(Router);
const router = new Router({
  mode: 'history',
  routes: [
    ...dolligo,
    ...dolligoMember,
    ...dolligoMain,
    ...dolligoPremium,
    ...dolligoReview,
    ...dolligoCommunity,
    ...dolligoMatch,
    ...dolligoMemo,
    ...dolligoChat,
    ...dolligoPushlist,
    ...dolligoSetting,
    ...blind,
    ...blindMember,
    ...blindBoard,
    ...blindPushlistIndex,
    ...blindSettingIndex,
    ...dolligoReport,
    {
      path: '*',
      redirect: '/404',
    },
    {
      path: '/404',
      component: NotFound
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
});


// agent, 권한 검사
router.beforeEach(async (to, from, next) => {
  if (sessionStorage.getItem('interval-chat'))
    clearInterval(parseInt(sessionStorage.getItem('interval-chat')));

  if (sessionStorage.getItem('interval-memo'))
    clearInterval(parseInt(sessionStorage.getItem('interval-memo')));

  if (sessionStorage.getItem('interval-timer'))
    clearInterval(parseInt(sessionStorage.getItem('interval-timer')));

  Vue.prototype.FROM = from;
  if (to.meta.checkAgent == true && navigator.userAgent.indexOf('gongdoll') == -1) {
    next("/");
    return;
  }

  if (to.meta.checkAuth == true) {
    // auth 토큰 체크
    let authToken = localStorage.getItem('authToken');
    if (!authToken) {
      next('/certnum');
      return;
    } else {
      try {
        let config = {
          headers: {
            'Authorization': 'Bearer ' + authToken,
            pushtoken: mixin.methods.getPushToken()
          }
        };
        let member = await axios.get('/api/member', config);
        Vue.prototype.MEMBER = member.data;

        if (window.swingDeviceScript) {
          window.swingWebViewPlugin.app.login.doAppLogin(member.data.mb_id, member.data.mb_id);
        } else {
          window.swingLoginPlugin.doLoginForce(member.data.mb_id, member.data.mb_id)
        }

      } catch (error) {
        // localStorage.removeItem('authToken');
        next('/certnum');
        return;
      }
    }
  }

  // 인사교류 가입 체크
  if (to.meta.checkAuthDolligo == true) {
    if (!Vue.prototype.MEMBER.mb_nick) {
      next("/dolligo/member/regist");
      return;
    }

    if (Vue.prototype.MEMBER.g5_z_applies.length == 0) {
      next("/dolligo/member/wisharea");
      return;
    }
  }

  // 공블리 가입 체크
  if (to.meta.checkAuthBlind == true) {
    if (!Vue.prototype.MEMBER.b_mb_nick) {
      next("/blind");
      return;
    }
  }
  next();
});

export {
  router
};