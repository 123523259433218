import Vue from 'vue'
import App from './App.vue'
import {router} from './routes/index.js'
import VueGtag from 'vue-gtag';
import VueAnalytics from 'vue-analytics'
import InfiniteLoading from 'vue-infinite-loading';
import '@/assets/css/jquery-ui.css';
import 'jquery-ui/ui/widgets/slider';
import '@/assets/css/common.css';
import '@/assets/css/web.css';
import '@/assets/css/dolligo.css';
import '@/assets/css/blind.css';

Vue.use(VueGtag, {
    config: { id: 'UA-141687350-1' }
});

Vue.use(VueAnalytics, {
    id: 'UA-141687350-1',
    router
});

Vue.config.productionTip = false;

Vue.use(InfiniteLoading, { /* options */ });

new Vue({
    render: h => h(App),
    router
}).$mount('#app')
