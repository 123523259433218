<template>
  <div id="app" class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`교류자 프로필`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="profile-wrap">
        <div class="profile-top">
          <span :class="'icon face' + member.mb_36"></span>
          <div class="name-row">
            <strong class="name">
              {{ member.mb_nick }}
              <span class="icon premium-i" v-if="MEMBER.mb_level == 9 && member.mb_level > 4"></span>
            </strong>
            <div class="infor-row">
              <span class="value">{{ member.jobName }}</span>
              <span class="value">{{ member.rankName }}</span>
              <span class="value" v-if="member.mb_sex == 'M'">남성</span>
              <span class="value" v-if="member.mb_sex == 'F'">여성</span>
            </div>
          </div>
          <ul class="data">
            <li><span class="icon info"></span>{{ getState(member.mb_memo_call) }}</li>
            <li v-if="MEMBER.mb_level >= 5 || MEMBER.mb_id == member.mb_id">
              <span class="icon answer"></span>쪽지 응답률(1일 내)
              <strong>{{ member.replyPercent }}</strong>
            </li>
            <li v-if="MEMBER.mb_level < 5 && MEMBER.mb_id != member.mb_id">
              <span class="icon answer"></span>
              <router-link to="/dolligo/premium/intro"><strong>쪽지 응답률</strong> 확인하기</router-link>
            </li>
            <li v-if="MEMBER.mb_level >= 5 || MEMBER.mb_id == member.mb_id">
              <span class="icon time"></span>
              <span v-html="member.lastAccess"></span>
            </li>
            <li v-if="MEMBER.mb_level < 5 && MEMBER.mb_id != member.mb_id">
              <span class="icon time"></span>
              <router-link to="/dolligo/premium/intro"><strong>최근 접속일</strong> 확인하기</router-link>
            </li>
          </ul>

          <div class="btn-row" v-if="me.mb_id != member.mb_id">
            <a v-if="bookmarkMember == null" href="javascript:void(0);" class="bookmark" @click="postBookmark();"><span
                class="icon"></span>관심 교류자 </a>
            <a v-if="bookmarkMember != null" href="javascript:void(0);" class="bookmark on" @click="delBookmark();"><span
                class="icon"></span> 관심 교류자 취소</a>
            <a v-if="withoutMember == null" href="javascript:void(0);" class="remove" @click="postWithoutMember();"><span
                class="icon"></span>매칭 제외</a>
            <a v-if="withoutMember != null" href="javascript:void(0);" class="remove on"
              @click="delWithoutMember();"><span class="icon"></span>매칭 제외 취소</a>
          </div>
        </div>
        <div class="profile-list">
          <dl>
            <dt>현재직</dt>
            <dd>{{ member.mb_8 }} {{ member.mb_8 == '국가직' ? member.currentOfficeName : '' }}</dd>
          </dl>
          <dl>
            <dt>현재 근무지</dt>
            <dd>{{ member.mb_4 }}</dd>
          </dl>
          <dl>
            <dt>희망직</dt>
            <dd>{{ member.mb_18 }} {{ member.officeName }}</dd>
          </dl>
          <dl>
            <dt>희망 근무지</dt>
            <dd>
              <dl v-for="(apply, index) in applies" v-bind:key="apply.wr_id" v-bind:index="index">
                <dt>{{ (index + 1) }}지망</dt>
                <dd>{{ apply.wr_4 }}</dd>
              </dl>
            </dd>
          </dl>
          <dl>
            <dt>직렬</dt>
            <dd>{{ member.jobName }}</dd>
          </dl>
          <dl>
            <dt>직급</dt>
            <dd>{{ member.rankName }}</dd>
          </dl>
          <dl v-if="member.mb_23">
            <dt>전보제한 여부</dt>
            <dd>
              <dl>
                <dt style="width: auto;">{{ member.mb_26 == '1' ? '교류가능' : '교류불가' }}</dt>
              </dl>
            </dd>
          </dl>
          <dl v-if="member.mb_39 == '1'">
            <dt>시간선택제</dt>
            <dd>
              <dl>
                <dt style="width: auto;">시간선택제 공무원</dt>
              </dl>
            </dd>
          </dl>
          <div class="profile-message" v-if="member.mb_5">{{ member.mb_5 }}</div>

          <!-- 관리자만 보이는 부분 start -->
          <div v-if="MEMBER.mb_level == 9" class="profile-list">
            <div>
              <textarea v-model="paymentsInfoText" style="height: 300px;" readonly></textarea>
            </div>
            <dl>
              <dt>imp_uid</dt>
              <dd>
                <dl>
                  <dt style="width: auto;" @click="copyToClipboard(imp_uid)">{{ imp_uid }}</dt>
                </dl>
              </dd>
            </dl>
            <dl>
              <dt>Device</dt>
              <dd>
                <select v-model="device">
                  <option value="">[기기선택]</option>
                  <option value="google">google</option>
                  <option value="apple">apple</option>
                </select>
              </dd>
            </dl>
            <dl v-if="device == 'google'">
              <dt>productId</dt>
              <dd>
                <select v-model="productId">
                  <option value="">[결제구분]</option>
                  <option value="premium_subscribe">premium_subscribe</option>
                  <option value="premium3month">premium3month</option>
                  <option value="premium6month">premium6month</option>
                </select>
              </dd>
            </dl>
            <dl>
              <dt>receipt</dt>
            </dl>
            <div>
              <textarea v-model="receipt" style="height: 200px;"></textarea>
            </div>
            <div>
              <br>
              <a href="javascript:void(0)" class="submit-btn on" @click="payConfirm()">결제 확인</a>
            </div>
            <dl>
              <dt>결제 결과</dt>
            </dl>
            <div>
              <textarea v-model="payResult" style="height: 300px;" readonly></textarea>
            </div>
          </div>
          <!-- 관리자만 보이는 부분 end -->

        </div>

        <div class="fixed-btns" v-if="me.mb_id != member.mb_id">
          <router-link :to="{ name: 'dolligoMemoView2', params: { mb_id: $route.params.id } }" class="submit-btn on">쪽지
            보내기</router-link>
          <a href="javascript:void(0)" @click="showMannerAlert" class="submit-btn border">매너 평가하기</a>
          <router-link :to="{ path: '/dolligo/member/manner-score/view/sum', query: { reg_mb_id: $route.params.id } }"
            class="more-btn">
            <strong>{{ member.mb_nick }}</strong>님의 매너 평가 보기
          </router-link>
        </div>

        <div class="fixed-btns" v-if="me.mb_id == member.mb_id">
          <select v-model="member.mb_memo_call" @change="updateState()">
            <option value="">교류 상태를 선택해 주세요.</option>
            <option value="1">준비중(전보 제한 중)</option>
            <option value="2">관망중(탐색 중)</option>
            <option value="3">인사교류 진행 중</option>
            <option value="4">교류희망(앞으로3개월 내외)</option>
            <option value="5">교류희망(앞으로1년 내외)</option>
            <option value="6">교류의사 없음</option>
            <option value="7">인사교류 성공!</option>
          </select>
        </div>

      </div>
    </div>

    <div class="dialog-layer-wrap" v-if="isMannerAlertShow">
      <div class="dialog css-manner-dialog">
        <a href="javascript:void(0);" class="icon close-btn" @click="$router.go(-1)">닫기</a>
        <h3>이미 매너 평가를 했다면<br>새로운 평가로 대체됩니다.</h3>
        <a href="javascript:void(0)" class="submit-btn border" v-on:click="goMannerScore('plus')">매너 평가하기</a>
        <a href="javascript:void(0)" class="submit-btn border" v-on:click="goMannerScore('minus')">비매너 평가하기</a>
      </div>
    </div>

    <div class="bottomsheet-wrap" v-if="showBottomSheet">
      <div class="bottomsheet-box">
        <div class="button-wrap">
          <router-link class="report-btn"
            :to="{ name: 'dolligoReportEdit', query: { recv_mb_nick: member.mb_id, bo_table: 'member', wr_id: member.mb_no } }">신고하기</router-link>
          <a href="javascript:void(0)" class="block-btn" @click="blockSave()">차단하기</a>
        </div>
        <a href="javascript:void(0);" class="submit-btn border close" @click="showBottomSheet = false">닫기</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '../../../mixin'
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

export default {
  components: { TopBackHeaderDolligo },
  name: 'dolligoMemberProfile',
  mixins: [mixin],
  data: function () {
    return {
      mb_id: this.$route.params.id,
      member: {},
      bookmarkMember: {},
      withoutMember: {},
      applies: {},
      isMannerAlertShow: false,
      showBottomSheet: false,
      paymentsInfoText: '',
      imp_uid: '',
      device: '',
      receipt: '',
      payResult: '',
    }
  },
  created: async function () {
    try {
      // 회원
      let member = await axios.get(`/api/member/${this.mb_id}`, this.axiosConfig);
      this.member = member.data;
      if (this.member.payments_infos) {
        this.paymentsInfoText = JSON.stringify(this.member.payments_infos, null, 2);
        this.imp_uid = this.member.payments_infos && this.member.payments_infos[0] ? this.member.payments_infos[0].imp_uid : '';
        this.receipt = this.member.payments_infos && this.member.payments_infos[0] ? this.member.payments_infos[0].aos_token : '';
      }
      this.device = this.member.mb_20 && this.member.mb_20.length > 20 ? 'apple' : 'google';

      // 북마크
      this.axiosConfig.params.bm_mb_id = this.$route.params.id;
      let bookmarkMember = await axios.get(`/api/bookmark-member`, this.axiosConfig);
      this.bookmarkMember = bookmarkMember.data.length > 0 ? bookmarkMember.data[0] : null;

      // 매칭 제외
      delete this.axiosConfig.params.bm_mb_id;
      this.axiosConfig.params.out_mb_id = this.$route.params.id;
      let withoutMember = await axios.get(`/api/without-member`, this.axiosConfig);
      this.withoutMember = withoutMember.data.length > 0 ? withoutMember.data[0] : null;

      // 희망 근무지
      delete this.axiosConfig.params.out_mb_id;
      this.axiosConfig.params.mb_id = this.$route.params.id;
      let applies = await axios.get(`/api/member/apply`, this.axiosConfig);
      this.applies = applies.data.length > 0 ? applies.data : null;

      // 차단 회원 검사
      delete this.axiosConfig.params.mb_id;
      this.axiosConfig.params.block_mb_id = this.mb_id;
      let blockMember = await axios.get(`/api/dolligo/block-member`, this.axiosConfig);
      if (blockMember.data) {
        alert('차단된 회원 입니다.');
        this.$router.back();
      }

      let self = this;
      window.onpopstate = function () {
        self.isMannerAlertShow = false;
      };
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message == '가입된 회원이 아닙니다.') {
        alert(error.response.data.message);
        this.$router.push({ name: 'dolligoMain' });
      } else {
        this.errorHandler(error);
      }
    }
  },
  methods: {
    postBookmark: async function () {
      try {
        let bookmarkMember = await axios.post(`/api/bookmark-member`, {
          bm_mb_id: this.$route.params.id
        }, {
          headers: this.axiosConfig.headers
        });
        this.bookmarkMember = bookmarkMember.data ? bookmarkMember.data : null;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    delBookmark: async function () {
      try {
        let bookmarkMember = await axios.delete(`/api/bookmark-member`, {
          headers: this.axiosConfig.headers,
          data: { bm_mb_id: this.$route.params.id }
        });
        this.bookmarkMember = bookmarkMember.data ? bookmarkMember.data : null;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    postWithoutMember: async function () {
      try {
        let withoutMember = await axios.post(`/api/without-member`, {
          out_mb_id: this.$route.params.id
        }, {
          headers: this.axiosConfig.headers
        });
        this.withoutMember = withoutMember.data ? withoutMember.data : null;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    delWithoutMember: async function () {
      try {
        let withoutMember = await axios.delete(`/api/without-member`, {
          headers: this.axiosConfig.headers,
          data: { out_mb_id: this.$route.params.id }
        });
        this.withoutMember = withoutMember.data ? withoutMember.data : null;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    // 매너 평가하기 alert
    showMannerAlert: function () {
      this.isMannerAlertShow = true;
      history.pushState('', '', '#manner');
    },
    // 매너 페이지로 이동
    goMannerScore: function (type) {
      this.$router.go(-1);
      setTimeout(() => {
        this.$router.push({
          name: 'dolligoMemberMannerScore',
          params: {
            type: type
          },
          query: {
            reg_mb_id: this.$route.params.id
          }
        })
      }, 10);
    },
    // 상태 업데이트
    updateState: async function () {
      let body = { mb_memo_call: this.member.mb_memo_call };
      await axios.patch('/api/member', body, this.axiosConfig);
      // alert('교류상태가 변경 되었습니다.');
    },

    // 차단 하기
    blockSave: async function () {
      if (confirm('차단 하겠습니까?')) {
        let data = {
          block_mb_id: this.mb_id,
        }
        await axios.post(`/api/dolligo/block-member`, data, this.axiosConfig);
        alert('차단 완료 되었습니다.');
        this.$router.back();
      }
    },

    copyToClipboard(text) {
      if (navigator.clipboard && window.isSecureContext) {
        // HTTPS 연결에서만 작동
        navigator.clipboard.writeText(text)
          .then(() => {
            alert("클립보드에 복사되었습니다!");
            // 여기에 복사 완료 후 처리 로직을 추가할 수 있습니다.
          })
          .catch(err => {
            alert("클립보드 복사에 실패했습니다: ", err);
          });
      } else {
        alert("클립보드 API가 지원되지 않습니다");
        // 클립보드 API가 지원되지 않는 경우의 대체 처리
        // 예: 텍스트를 임시 텍스트 영역에 복사하여 사용자가 수동으로 복사할 수 있도록 함
      }
    },

    async payConfirm() {
      try {
        let body ={
          receipt: this.receipt,
          productId: this.productId,
          mb_id: this.member.mb_id
        }
        if (this.device == 'apple') delete body.productId;
        let result = await axios.post(`${this.baseUrl}/api/dolligo/payments-info/${this.device}`, body, {
          headers: this.axiosConfig.headers
        });
        this.payResult = JSON.stringify(result.data, null, 2);
      } catch (error) {
        alert(this.getErrorMessage(error));
      }
    }
  }
}
</script>

