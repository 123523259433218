<template>
  <div class="puzzle-spinner-wrap" :class="{'none': show == false}">
    <div class="puzzle-spinner">
      <div class="puzzle1"></div>
      <div class="puzzle2"></div>
      <div class="puzzle3"></div>
      <div class="puzzle4"></div>
    </div>
  </div>
</template>

<script>

  export default {
    data: function(){
      return {
        show: false,
      }
    }
  }
</script>

