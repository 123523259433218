<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`나의 근무 환경 평가`"></top-back-header-dolligo>
    <div class="content-wrap">
      <div class="review-office-wrap">
        <p class="notice-txt">직렬 및 행정처가 변경된 경우 추가로 근무환경평가를 작성하실 수 있습니다.</p>
        <div class="review-body-wrap">
          <router-link v-for="item in data.reviews.rows" v-bind:key="item.wr_id"
            :to="{ name: 'dolligoReviewModify', params: { id: item.wr_id } }" class="office-box">
            <span class="office-box-top">
              <strong>{{ item.cityName }} {{ item.districtName }}</strong>
              <span class="rating">{{ item.score }}</span>
              <span class="date">{{ item.txtDate }}</span>
            </span>
            <ul class="reviewer-info">
              <li>{{ item.po_type }}</li>
              <li>{{ item.jobName }}</li>
              <li v-if="data.reviews.branchName">{{ item.branchName }}</li>
            </ul>
            <ul class="reviewer-info">
              <li>근무기간 {{ item.wr_1 }}년 ~ {{ item.wr_2 }}년</li>
            </ul>
          </router-link>
          <p v-if="data.reviews.total_count == 0">본인의 전, 현직 근무지에 대한<br>평가를 올려주세요!<br>인사교류 대상자를 위한 꿀팁이 될 수 있습니다.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '../../../mixin';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin],
  data: function () {
    return {
      data: {
        reviews: {}
      }
    }
  },
  created: async function () {
    this.axiosConfig.params.isMe = 'true';
    this.data.reviews = await axios.get(`/api/dolligo/review`, this.axiosConfig);
    // console.log(this.data.reviews);
    this.data.reviews = this.data.reviews.data;
  }
}
</script>

