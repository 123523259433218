<template>
  <div id="app" class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`프리미엄 멤버십 가입`"></top-back-header-dolligo>
    <div class="content-wrap">
      <div class="product-head">
        <h2>프리미엄 멤버십 {{ priceInfo[payType].payText }}</h2>
        <span class="discount">{{ priceInfo[payType].discountPercent }}%</span>
        <span class="og-price">{{ priceInfo[payType].price }}원</span>
        <span class="price-wrap">
          <span class="final-price">월 {{ priceInfo[payType].discountPrice }}원</span>
        </span>
      </div>
      <div class="premium-bottom">
        <div class="check-wrap">
          <label for="terms1" class="form-check-label">
            <input type="checkbox" id="terms1" class="form-check-input" v-model="datas.isAgree" />서비스 이용약관 동의 (필수)
            <span class="form-check-sign"><span class="check"></span></span>
          </label>
          <router-link :to="{ name: 'dolligoPremiumTerm' }" class="term-btn" @click.native="saveSession()">약관보기
          </router-link>
          <div class="helper-txt error">동의는 필수입니다.</div>
        </div>
        <button class="submit-btn p-on" @click="payStart()">결제하기</button>
      </div>

      <div class="noti-box-wrap">
        <h3>문의사항 및 해지/환불</h3>
        <ul>
          <li>문의사항은 <router-link :to="{ name: 'dolligoCommunity', params: { boardId: 'qa' } }">Q&amp;A
              게시판</router-link>에
            남겨주세요</li>
          <li>정기결제 이용 시 해지가 가능하며 만료일 이전에 해지하셔도 남은 일수만큼 환불되지 않습니다.(기존 이용권 만료일까지 프리미엄 멤버십 혜택 유지)</li>
          <li>월결제(3, 6개월) 이용시에는 환불이 불가능합니다.</li>
          <li>구글 정기구독(결제) 취소 : <strong>플레이스토어 &gt; 정기결제 &gt; 인사교류 &gt; 구독취소</strong></li>
          <li>애플 정기구독(결제) 취소 : <strong>App Store &gt; 프로필 이미지 클릭(계정) &gt; 구독 클릭 &gt; 구독 중인 항목 클릭 &gt; 하단 구독취소
              클릭</strong></li>
        </ul>
      </div>
    </div>
    <spinner ref="spinner"></spinner>
  </div>
</template>
<script>
import axios from 'axios';
import mixin from '../../../mixin';
import Spinner from '../../common/Spinner.vue';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';
export default {
  components: { Spinner, TopBackHeaderDolligo },
  mixins: [mixin],
  data: function () {
    return {
      payType: this.$route.query.payType,
      payMethod: '',
      purchaseHistoryMethod: '',
      swingPlugin: null,
      prodId: '',
      priceInfo: {
        12: { discountPercent: '29', price: '8,500', discountPrice: '6,000', iosProdId: 'premium_sub', andProdId: 'premium_subscribe', payText: '정기 결제' },
        14: { discountPercent: '41', price: '25,500', discountPrice: '15,000', iosProdId: 'premium3mth', andProdId: 'premium3month', payText: '3개월 결제' },
        15: { discountPercent: '47', price: '51,000', discountPrice: '27,000', iosProdId: 'premium6mth', andProdId: 'premium6month', payText: '6개월 결제' }
      },
      datas: {
        isAgree: false,
      }
    }
  },
  created: async function () {
    try {
      // if(this.MEMBER.mb_tel == '01700001111')
      //   alert(window.swingDeviceScript);
      // if (window.swingDeviceScript != null) {
        this.$router.replace({ name: 'dolligoPremiumPayV3', query: { payType: this.$route.query.payType } });
        // return;
      // }
      await this.getSession();
      this.swingPlugin = this.isIphone == true ? window.swingLoginPlugin : window.swingAndroidInAppV3Plugin;
      this.payMethod = this.payType == '12' ? 'subscribe' : 'buy';
      this.prodId = this.isIphone == true ? this.priceInfo[this.payType].iosProdId : this.priceInfo[this.payType].andProdId;
      this.purchaseHistoryMethod = this.isIphone == true ? 'getReceipt' : 'restorePurchases';
      this.purchaseHistoryCheck(); // 구매 이력 체크
    } catch (error) {
      this.errorHandler(error);
    }
  },
  methods: {
    payStart: function () {
      if (this.datas.isAgree == false) {
        alert('서비스 이용약관 동의해 주세요.');
        return;
      }
      let axiosConfig = this.axiosConfig;
      axiosConfig.params = { payText: `${this.priceInfo[this.payType].payText} 클릭` }
      axios.get(`/api/dolligo/pay-inapp-log/slack`, axiosConfig);

      let self = this;
      this.$refs.spinner.show = true;
      this.swingPlugin[self.payMethod](self.prodId, function (isResult, r, receipt) {
        self.addInappLog({ '진행중': { prodId: self.prodId, isResult: isResult, r: r, receipt: receipt } }); // 결제 로그
        if ((self.prodId == 'premium3month' || self.prodId == 'premium6month') && isResult == 0) {
          self.swingPlugin.consume(r.productId, function (r2) {
            if (r2 == 'true') {
              self.addInappLog({ '결제성공': { prodId: self.prodId, consumeResult: r2 } }); // 결제 로그
              self.payCompleted(self.prodId, r.purchaseToken) // 안드 3, 6 개월 결제 완료
            } else {
              self.addInappLog({ '결제실패': { prodId: self.prodId, consumeResult: r2 } }); // 결제 로그
              self.$refs.spinner.show = false;
            }
          }, function (e2) {
            self.addInappLog({ '결제실패e2': { prodId: self.prodId, consumeErrorResult: e2 } }); // 결제 실패 로그
            self.$refs.spinner.show = false;
            self.$router.push({ name: 'dolligoPremiumPayFail' })
          });
        } else {
          if (self.isIphone == true) self.payCompleted(self.prodId, isResult.receipt); // 아이폰 결제 완료
          else if (isResult == 0) self.payCompleted(self.prodId, r.purchaseToken); // 안드 정기 결제 완료
          else {
            self.addInappLog({ '결제실패else': { prodId: self.prodId, isResult: isResult, r: r, receipt: receipt } }); // 결제 로그
            self.$refs.spinner.show = false;
            self.$router.push({ name: 'dolligoPremiumPayFail' })
          }
        }
      },
        function (e) {
          axiosConfig.params = { payText: `${self.priceInfo[self.payType].payText} 입질` }
          axios.get(`/api/dolligo/pay-inapp-log/slack`, axiosConfig);

          self.addInappLog({ '입질': { prodId: self.prodId, payErrorResult: e } }); // 결제 실패 로그
          self.$refs.spinner.show = false;
          self.$router.push({ name: 'dolligoPremiumPayFail' })
        }
      );
    },

    // 결제 로그 저장
    addInappLog: async function (response) {
      response = JSON.stringify(response);
      let data = { response: response };
      await axios.post(`/api/dolligo/pay-inapp-log`, data, this.axiosConfig);
    },

    // 구매이력 체크
    purchaseHistoryCheck: function () {
      if (this.MEMBER.mb_level == '5') return;
      let self = this;
      this.swingPlugin[self.purchaseHistoryMethod](function (r) {

        let response = { '구매이력': r }
        self.addInappLog(response);

        // 아이폰 일반 회원인 경우 
        if (self.isIphone == true && self.MEMBER.mb_level == '2' && r && r.latest_receipt) {
          self.payCompleted(r.latest_receipt_info[0].product_id, r.latest_receipt);
        }

        // 안드로이드 일반 회원인 경우
        if (self.isIphone == false && self.MEMBER.mb_level == '2' && r && (r.products || r.subscriptions)) {
          if (r.subscriptions && r.subscriptions[0].productId == 'premium_subscribe') {
            self.payCompleted(r.subscriptions[0].productId, r.subscriptions[0].purchaseToken);
          } else {
            self.swingPlugin.consume(r.products[0].productId, function (r2) {
              if (r2 == 'true') {
                self.payCompleted(r.products[0].productId, r.products[0].purchaseToken) // 안드 3, 6 개월 결제 완료
              }
            }, function (e2) {
              self.addInappLog({ 'prodId on logding': r.products[0].productId, consumeErrorResult: e2 }); // 결제 실패 로그
            });
          }
        }
      }, function (e) {
        let response = { '구매이력-error': e }
        self.addInappLog(response);
      });
    },

    // 결제 완료
    payCompleted: async function (productId, receipt) {
      try {
        let path = this.isIphone == true ? 'apple' : 'google';
        let data = { productId: productId, receipt: receipt, mb_id: this.MEMBER.mb_id }
        let result = await axios.post(`/api/dolligo/payments-info/${path}`, data, this.axiosConfig);
        this.$refs.spinner.show = false;

        if (result.data.member && result.data.member.mb_level == '5') {
          let axiosConfig = this.axiosConfig;
          axiosConfig.params = { payText: `${this.priceInfo[this.payType].payText} 결제 완료` }
          axios.get(`/api/dolligo/pay-inapp-log/slack`, axiosConfig);
          this.$router.push({ name: 'dolligoPremiumPayDone' })
        }

      } catch (error) {
        this.addInappLog({ 'payCompleted 결제실패': error }); // 결제 실패 로그
      }

    }
  }
}
</script>  

