<template>
  <header>
    <a v-if="isBack" href="javascript:void(0)" class="icon back-btn" @click="goBack()">뒤로가기</a>
    <h2 v-if="isBack" class="tit">{{ title }}</h2>
    <p v-if="isBack && subTitle" class="subtit">{{ subTitle }}</p>
    <a v-if="isBack && $route.name == 'blindBoardEdit' || $route.name == 'blindBoardModify'" href="javascript:void(0)"
      class="txt-btn" :class="{on: $parent.isSave()}" @click="$parent.save()">완료</a>

    <h1 v-if="!isBack" class="logo icon">공블리</h1>
    <router-link v-if="!isBack" :to="{ name: 'dolligoMain' }" class="switch-btn">
      <strong>인사교류</strong>로 전환
    </router-link>
    <h2 v-if="!isBack" class="tit">{{ title }}</h2>
    <p v-if="!isBack" class="subtit">{{ subTitle }}</p>
  </header>
</template>

<script>
export default {
  props: {
    propTitle: String,
    propSubTitle: String,
    propIsBack: Boolean,
  },
  data: function () {
    return {
      title: this.propTitle,
      isBack: this.propIsBack,
      subTitle: this.propSubTitle,
    }
  },
  created: async function () {
    if (!this.title) {
      if (this.$route.query.is_me == 'y') this.title = '내가 쓴 글';
      else this.title = this.$route.params.boardId == 'free' ? '자유게시판' : '공무원 이슈';
    }
  },
  methods: {
    goBack: function () {
      if (this.$route.name == 'blindBoardView' && (!this.FROM || !this.FROM.name)) {
        this.$router.push({ name: 'blindBoardList', params: { boardId: this.$route.params.boardId } });
      }      
      else if (this.FROM.name) {
        this.$router.go(-1);
      }
      else {
        this.$router.push('/branch');
      }
    }
  }
}
</script>

