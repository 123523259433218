import DolligoSetting from '@/components/dolligo/setting/Index';

const dolligoSetting = [{
  path: '/dolligo/setting',
  name: 'dolligoSetting',
  component: DolligoSetting,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}]

export default dolligoSetting