<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`프리미엄 멤버십 무료체험`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="event1-wrap" :class="{ 'event-done': events.length > 0 }">
        <h2>인사교류 프리미엄<br><strong>24시간 무료체험 이벤트</strong></h2>
        <p>근무환경평가, 회원상세정보를 모두 작성하시고,<br>인사교류 프리미엄을 무료로 체험해보세요~</p>
        <div class="btn-wrap">
          <a href="javascript:void(0);" class="submit-btn" v-if="reviews.length > 0">근무환경평가(완료)</a>
          <router-link :to="{ name: 'dolligoReviewRegist' }" class="submit-btn on" v-if="reviews.length == 0">근무환경평가 입력
          </router-link>

          <a href="javascript:void(0);" class="submit-btn" v-if="MEMBER.mb_23">회원상세정보(완료)</a>
          <router-link :to="{ name: 'dolligoMemberRegistDetail', query: { from: 'event' } }" class="submit-btn on"
            v-if="!MEMBER.mb_23">회원상세정보 입력
          </router-link>

          <a href="javascript:void(0)" class="submit-btn p-on" v-if="events.length > 0">24시간 무료체험 완료</a>
          <a href="javascript:void(0)" @click="startEvent();" class="submit-btn" :class="{'p-on': MEMBER.mb_23 && reviews.length > 0}" v-if="events.length == 0">24시간
            무료체험 시작하기</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '../../../mixin';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';
export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin],
  data: function () {
    return {
      events: [],
      reviews: []
    }
  },
  created: async function () {
    try {
      // 이벤트
      this.axiosConfig.params.em_type = '1'
      let events = await axios.get('/api/dolligo/event-member', this.axiosConfig);
      this.events = events.data;

      // 리뷰
      delete this.axiosConfig.params.em_type;
      this.axiosConfig.params.isMe = 'true';
      let reviews = await axios.get(`/api/dolligo/review`, this.axiosConfig);
      // console.log(reviews);
      this.reviews = reviews.data.rows;
    } catch (error) {
      this.errorHandler(error);
    }
  },
  methods: {
    // 이벤트 참여 등록
    startEvent: async function () {
      try {
        if(!(this.MEMBER.mb_23 && this.reviews.length > 0)) return '';

        let body = { em_type: '1' };
        let event = await axios.post(`/api/dolligo/event-member`, body, { headers: this.axiosConfig.headers });
        this.events.push(event.data);

        setTimeout(() => {
          this.$router.replace({name: 'dolligoMatchIndex'});
        }, 500);

      } catch (error) {
        this.errorHandler(error);
      }
    }
  }
}
</script>

