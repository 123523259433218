<template>
  <div class="dolligo-wrap">
    <header>
      <dolligo-top></dolligo-top>
      <h1>채팅&amp;쪽지</h1>
    </header>
    <div class="content-wrap bg-white">
      <ul class="page-info-list">
        <li>프리미엄 멤버십에 가입하시면 무제한 그룹 채팅 이용과<br />상대방 쪽지 응답률이 표시됩니다.</li>
      </ul>
      <ul class="tab-menu">
        <li>
          <router-link :to="{ name: 'dolligoChat' }"><span>그룹 채팅</span><span class="icon premium"></span></router-link>
        </li>
        <li>
          <a href="javascript:void(0)" class="on"><span>쪽지</span></a>
        </li>
      </ul>
      <ul class="message-list">
        <li v-for="memo in datas.memos" v-bind:key="memo.me_id">
          <router-link :to="{name: 'dolligoMemoView2', params: {mb_id: memo.mb_id}}">
          <!-- <a :href="`/dolligo/memo/${memo.mb_id}`"> -->
            <span :class="`icon face${memo.mb_36}`">{{ memo.mb_nick }}</span>
            <span class="name">{{ memo.mb_nick }}</span>
            <span class="percent" v-if="MEMBER.mb_level > 4">응답률 <strong>{{ memo.replyPercent }}</strong></span>
            <span class="infor-row">
              <span class="value">{{ memo.grd1_nm }}</span>
              <span class="value">{{ memo.grd2_nm }}</span>
              <span class="value">{{ memo.mb_4 }}</span>
              <span class="value">{{ memo.mb_sex }}</span>
            </span>
            <p>{{ memo.me_memo }}</p>
            <span class="date">{{ memo.regist_date_txt }}</span>
            <span class="count" v-if="memo.number_not_seen > 0">{{ memo.number_not_seen }}</span>
          <!-- </a> -->
        </router-link>
        </li>
        <infinite-loading :identifier="datas.infiniteId" @infinite="getMemos" ref="infiniteLoading">
          <div slot="no-more"></div>
          <div slot="no-results">
            <div class="empty-states" style="display: block;" v-if="datas.memos.length == 0">
              <p>교류자 프로필 확인 후 쪽지를 보내보세요!</p>
            </div>
          </div>
        </infinite-loading>
      </ul>
    </div>
    <dolligo-footer propType="dolligoChat"></dolligo-footer>
  </div>
</template>

<script>
import DolligoTop from '../../common/DolligoTop.vue';
import DolligoFooter from '../../common/DolligoFooter.vue';
import mixin from '../../../mixin';
import axios from 'axios';

export default {
  mixins: [mixin],
  components: {
    DolligoTop,
    DolligoFooter,
  },
  data: function () {
    return {
      datas: {
        memos: [],
        scrollTop: 0,
        infiniteId: +new Date(),
        last_me_id: ''
      },
      isCreated: false
    }
  },
  created: async function () {
  },
  methods: {
    faceClass: function (user) {
      if (user.mb_id == this.MEMBER.mb_id) {
        return `icon face${user.faceType} on me`;
      } else {
        return `icon face${user.faceType}`;
      }
    },
    getMemos: async function ($state) {
      try {
        this.axiosConfig.params.me_id = this.datas.last_me_id;
        let memos = await axios.get('/api/dolligo/memo', this.axiosConfig);
        memos = memos.data;
        if (memos.length == 0) {
          $state.complete();
        } else {
          this.datas.last_me_id = memos[memos.length - 1].me_id;
          this.datas.memos.push(...memos);
          $state.loaded();
        }        
      } catch (error) {
        this.errorHandler(error);
      }
    }
  }
}
</script>

