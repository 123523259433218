<template>
  <nav class="">
    <ul>
      <li>
        <router-link :to="{name: 'dolligoMain'}" :class="{on: type == 'dolligoMain'}">
          <span class="icon home"></span>
          <span class="tit">홈</span>
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'dolligoMatchIndex'}" :class="{on: type == 'dolligoMatch'}">
          <span class="icon matching"></span>
          <span class="tit">매칭찾기</span>
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'dolligoChat'}" :class="{on: type == 'dolligoChat'}">
          <span class="icon message"></span>
          <span class="tit">채팅&amp;쪽지</span>
          <span v-if="MEMBER.unreadMemoCount && MEMBER.unreadMemoCount > 0" class="count">{{ MEMBER.unreadMemoCount }}</span>
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'dolligoReview'}" :class="{on: type == 'dolligoReview'}">
          <span class="icon review"></span>
          <span class="tit">근무 환경</span>
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'dolligoCommunity', params:{boardId: 'gongam'}}" :class="{on: type == 'dolligoCommunity'}">
          <span class="icon community"></span>
          <span class="tit">커뮤니티</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
  import mixin from '../../mixin'

  export default {
    mixins: [mixin],
    props: {
      propType: String,
    },
    data: function(){
      return {
        type: this.propType,
      }
    }
  }
</script>


