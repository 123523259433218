<template>
  <div class="dolligo-wrap">
    <div class="layer-wrap">
      <header class="fixed">
        <a href="javascript:void(0)" onclick="history.back()" class="icon close-btn">닫기</a>
        <div class="right-btns">
          <a href="javascript:void(0);" v-on:click="initSearch()" class="txt-btn">검색 초기화</a>
        </div>
      </header>
      <div class="content-wrap">
        <div class="write-wrap">
          <div class="list-wrap">
            <h2>교류자의 현재 근무지</h2>
            <ul>
              <depart :departCode="currentWorkInfo" :isShowNoSelect="false" @change.native="search()"></depart>
              <office v-if="currentWorkInfo.mb_8 == '국가직'" v-model="currentWorkInfo.mb_17"
                :officeCode="currentWorkInfo.mb_17" @change.native="search()"></office>
              <addr :addrCode="currentWorkInfo" @change.native="search()"></addr>
            </ul>
          </div>
          <div class="list-wrap">
            <h2>교류자의 희망 근무지</h2>
            <ul>
              <depart :departCode="hopeWorkInfo" :isShowNoSelect="false" @change.native="search()"></depart>
              <office v-if="hopeWorkInfo.mb_8 == '국가직'" v-model="hopeWorkInfo.mb_17" :officeCode="hopeWorkInfo.mb_17"
                @change.native="search()"></office>
              <addr :addrCode="hopeWorkInfo" @change.native="search()"></addr>
            </ul>
          </div>

          <div class="list-wrap">
            <h2>교류자의 직렬, 직급</h2>
            <ul>
              <job-rank v-model="currentWorkInfo" :jobRankCode="currentWorkInfo" @change.native="search()"></job-rank>
            </ul>
            <time-job v-model="currentWorkInfo.mb_39" :className="`match`" @change.native="search()"></time-job>
          </div>

          <div class="list-wrap">
            <h2>상세 조건 추가<span class="icon premium-i"></span></h2>
            <ul>
              <li>
                <label class="submit-btn" :class="{ on: currentWorkInfo.mb_27 == '1' }"><input type="checkbox" true-value="1"
                    false-value="" v-model="currentWorkInfo.mb_27" style="-webkit-appearance: none;"
                    v-on:change="checkPremium(); search();" /> 일방 전출 </label>
              </li>
            </ul>
          </div>
          <button class="submit-btn on fixed" id="btn-submit" v-on:click="goApplyList()">{{ resuleMessage }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Addr from '../../common/Addr.vue';
import Depart from '../../common/Depart.vue';
import JobRank from '../../common/JobRank.vue';
import Office from '../../common/Office.vue';
import TimeJob from '../../common/TimeJob.vue';
import axios from 'axios';

export default {
  components: {
    Depart,
    Office,
    Addr,
    TimeJob,
    JobRank,
  },
  name: "dolligoMainSearch",
  data: function () {
    return {
      currentWorkInfo: {
        mb_8: '', // 국가직 or 지방직
        mb_17: '', // 행정처
        mb_9: '', // 광역시도
        mb_10: '', // 시군구
        mb_1: '', // 직렬
        mb_2: '', // 직급
        mb_39: '', // 시간선택제
        mb_27: '', // 일방전출
      },
      hopeWorkInfo: {
        mb_8: '', // 국가직 or 지방직
        mb_17: '', // 행정처
        mb_9: '', // 광역시도
        mb_10: '', // 시군구
      },
      resuleMessage: '검색하기',
      applyData: null,
      config: {}
    }
  },
  created: function () {
    if (sessionStorage.getItem('currentWorkInfo'))
      this.currentWorkInfo = JSON.parse(sessionStorage.getItem('currentWorkInfo'));

    if (sessionStorage.getItem('hopeWorkInfo'))
      this.hopeWorkInfo = JSON.parse(sessionStorage.getItem('hopeWorkInfo'));

    if (sessionStorage.getItem('config'))
      this.config = JSON.parse(sessionStorage.getItem('config'));

    if (sessionStorage.getItem('config') != '{}')
      this.search();
  },
  methods: {
    checkPremium: function () {
      console.log(this.MEMBER.mb_level);
      if (this.MEMBER.mb_level < 5) {
        this.currentWorkInfo.mb_27 = '';
        if (confirm("프리미엄 멤버십 회원만 검색 가능합니다\n가입하겠습니까?")) {
          this.$router.push({name: 'dolligoPremiumIntro'});
        }
      }
    },
    search: async function () {
      this.config.params = this.currentWorkInfo;
      this.config.params.hopeCity = this.hopeWorkInfo.mb_9;
      this.config.params.hopeDistrict = this.hopeWorkInfo.mb_10;
      this.config.params.mb_18 = this.hopeWorkInfo.mb_8;
      this.config.params.mb_19 = this.hopeWorkInfo.mb_17;

      let response = await axios.get('/api/apply', this.config);
      this.applyData = response.data;
      this.resuleMessage = `검색 결과 보기(${this.applyData.count.toLocaleString()}건)`;
    },
    goApplyList: async function () {
      // 리스트 세션 삭제
      sessionStorage.removeItem('applyCount');
      sessionStorage.removeItem('applyList');
      sessionStorage.removeItem('isDrawerWrapOn');
      sessionStorage.removeItem('query');
      sessionStorage.removeItem('historyBack');

      // 검색 조건 세션 서장
      sessionStorage.setItem('currentWorkInfo', JSON.stringify(this.currentWorkInfo));
      sessionStorage.setItem('hopeWorkInfo', JSON.stringify(this.hopeWorkInfo));
      sessionStorage.setItem('config', JSON.stringify(this.config));

      this.$router.push({
        name: 'dolligoMain',
        query: this.config.params
      });
    },
    initSearch: function () {
      for (let key in this.currentWorkInfo) {
        this.currentWorkInfo[key] = '';
      }
      for (let key in this.hopeWorkInfo) {
        this.hopeWorkInfo[key] = '';
      }
      this.resuleMessage = '검색하기';
      this.applyData = null;
      this.config = {};

      sessionStorage.removeItem('currentWorkInfo');
      sessionStorage.removeItem('hopeWorkInfo');
      sessionStorage.removeItem('config');
    }
  }
}
</script>

