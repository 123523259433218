<template>
  <div class="blind-wrap">
    <top-back-header-blind :propIsBack="true" :propTitle="'마케팅 목적 개인정보 이용 동의'"></top-back-header-blind>
    <div class="container">
      <div class="term-wrap">
        <p>귀하의 개인정보 (성별, 현재 근무지, 직군, 직렬, 핸드폰 번호)는 보유∙이용기간 중에 전화, SMS, LMS, MMS, 이메일, 우편을 통하여 영리목적의 광고성 정보 전달에 활용되거나 영업 및
          마케팅 목적으로 활용될 수 있습니다.</p>
        <p>귀하는 위와 같은 마케팅 목적 개인정보 수집 및 이용을 거부할 수 있으나, 이 경우 행사 안내를 받지 못하거나 회사가 제공하는 유용한 정보를 받지 못할 수 있습니다</p>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixin';
import TopBackHeaderBlind from '../../common/TopBackHeaderBlind.vue';

export default {
  components: { TopBackHeaderBlind },
  mixins: [mixin],
  created: function () {
    window.addEventListener('scroll', this.handleScroll);
  }
}
</script>
  
