<template>
  <div class="layer-wrap" v-bind:class="{ on: isSpinnerShow }" v-bind:style="{opacity : spinnerOpacity}">
    <div class="center-wrap">
      <h2 class="tit-3" v-html="spinnerMessage"></h2>
      <svg class="spinner" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isSpinnerShow: false,
      spinnerOpacity: "0.8",
      spinnerMessage: ''
    }
  }
}
</script>

