<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo></top-back-header-dolligo>
    <div class="content-wrap">
      <div class="board-view-wrap">
        <div class="view-top">
          <h1 class="board-title"><strong class="notice" v-if="datas.board && datas.board.isNotice == '1'">공지</strong>{{
            datas.board.wr_subject }}</h1>
          <span class="board-writer board-writer-mr">
            <span class="left">
              <router-link v-if="datas.board.isView && datas.board.g5_member"
                :to="{ name: 'dolligoMemberProfile', params: { id: datas.board.mb_id } }"><span
                  :class="`icon face${datas.board.g5_member ? datas.board.g5_member.mb_36 : '1'}`">사진</span></router-link>
              <router-link v-if="datas.board.isView && datas.board.g5_member"
                :to="{ name: 'dolligoMemberProfile', params: { id: datas.board.mb_id } }"><span class="name">{{
                  datas.board.wr_name }}</span><span v-if="datas.board.wr_1 == '1'" class="name "
                  :class="{ 'secret': datas.board.wr_1 == '1' }"
                  style="background-image: url(/img/lock-icon.2c58758f.svg);font-size: 11px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></router-link>

              <a v-if="!datas.board.isView" href="javascript:void(0);"><span
                  :class="'icon face' + (datas.board.g5_member ? datas.board.g5_member.mb_36 : '')">사진</span></a>
              <a v-if="!datas.board.isView" href="javascript:void(0);"><span
                  class="name">익명게시자</span></a>

              <ul class="board-stat">
                <li>{{ datas.board.txtDate }}</li>
                <li v-if="datas.board.hitCount > 0">조회 {{ datas.board.hitCount }}</li>
                <li v-if="datas.board.wr_comment">댓글 {{ datas.board.wr_comment }}</li>
              </ul>
            </span>
            <span class="right">
              <router-link class="report-btn" :to="{
                name: 'dolligoReportEdit', query: {
                  recv_mb_nick: datas.board.g5_member && datas.board.g5_member.mb_id,
                  bo_table: $route.params.boardId,
                  wr_id: $route.params.wr_id
                }
              }">신고</router-link>
            </span>
          </span>
        </div>
        <div class="view-body">
          <p v-html="changeBr(datas.board.wr_content)"></p>
          <div class="view-btn-box">
            <router-link v-if="!dolligoCommunity"
              :to="{ name: 'dolligoCommunity', params: { boardId: $route.params.boardId } }" class="goto-list">목록
            </router-link>
            <a v-if="dolligoCommunity" href="javascript:void(0)" class="goto-list" @click="$router.back()">목록</a>
            <router-link v-if="MEMBER.mb_id == datas.board.mb_id || MEMBER.mb_level == '9'"
              :to="{ name: 'dolligoCommunityEdit', params: { boardId: $route.params.boardId, wr_id: $route.params.wr_id } }">
              수정</router-link>
            <a href="javascript:void(0)" v-if="MEMBER.mb_id == datas.board.mb_id || MEMBER.mb_level == '9'"
              @click="del(datas.board.wr_id)">삭제</a>
            <a href="javascript:void(0);" v-if="MEMBER.mb_level == '9'" class="goto-list copyurl"
              @click="copyUrl()">주소복사</a>
          </div>
        </div>
      </div>

      <div class="view-comment">
        <div class="empty-states" v-if="datas.replies.length == 0">
          <p>댓글이 없습니다.<br>첫 댓글을 작성해주세요~!</p>
          <a href="javascript:void(0);" @click="$refs.comment.focus()" class="comment-btn">댓글쓰기</a>
        </div>
        <ul class="comment" v-if="datas.replies.length > 0">
          <li v-for="(reply) in datas.replies" v-bind:key="reply.wr_id"
            :class="{ 'comment-reply': reply.wr_comment_reply }" :ref="`comment-${reply.wr_id}`">
            <span class="board-writer">
              <router-link v-if="reply.isView && reply.g5_member" :to="{ name: 'dolligoMemberProfile', params: { id: reply.mb_id } }"><span
                  :class="`icon face${reply.g5_member ? reply.g5_member.mb_36 : ''}`">사진</span></router-link>
              <router-link v-if="reply.isView && reply.g5_member" :to="{ name: 'dolligoMemberProfile', params: { id: reply.mb_id } }"><span
                  class="name" :class="{ 'secret': reply.wr_option == 'secret' }">{{ reply.wr_name }}</span></router-link>

              <a href="javascript:void(0)" v-if="!reply.g5_member"><span class="name">{{ reply.wr_name }}</span></a>

              <a v-if="!reply.isView && reply.g5_member" href="javascript:void(0)"><span
                  :class="`icon face${reply.g5_member ? reply.g5_member.mb_36 : ''}`">사진</span></a>
              <a v-if="!reply.isView && reply.g5_member" href="javascript:void(0)"><span class="name "
                  :class="{ 'secret': reply.wr_option == 'secret' }">익명게시자</span></a>

              <router-link v-if="reply.isView && reply.mb_id != MEMBER.mb_id && reply.g5_member"
                :to="{ name: 'dolligoMemoView2', params: { mb_id: reply.mb_id } }"><span class="message-btn">쪽지 보내기</span>
              </router-link>
            </span>

            <p :class="{ 'secret': reply.wr_option == 'secret' }">{{ reply.isView ? reply.wr_content : '비밀글 입니다.' }}</p>

            <ul class="board-stat">
              <li>{{ reply.txtDate }}</li>
              <li v-if="!reply.wr_comment_reply && datas.reply.wr_comment == '' && !reply.is_reply && reply.g5_member">
                <span class="reply"
                  @click="datas.reply.wr_comment = reply.wr_comment; reply.is_reply = true; $refs.comment.focus();">답글달기</span>
              </li>
              <li v-if="!reply.wr_comment_reply && datas.reply.wr_comment != '' && reply.is_reply == true"><span
                  class="reply" @click="datas.reply.wr_comment = ''; reply.is_reply = false;">답글취소</span></li>
              <li v-if="MEMBER.mb_level == '9' || reply.mb_id == MEMBER.mb_id"><span class="delete"
                  @click="del(reply.wr_id)">삭제</span></li>
            </ul>
          </li>
        </ul>
        <div class="comment-write" style="border-top: 1px solid #eee; padding-top: 10px;">
          <div class="check-wrap">
            <label for="wr_secret" class="form-check-label">
              <input type="checkbox" id="wr_secret" class="form-check-input" v-model="datas.reply.wr_option"
                true-value="secret" false-value="" />
              <span class="form-check-lock icon"></span>
              <span>모두 열람 가능</span>
            </label>
          </div>
          <div class="textarea-wrap">
            <textarea class="on" placeholder="댓글을 입력해주세요" maxlength="10000" ref="comment" v-model="datas.reply.wr_content"
              :style="{ height: datas.replyHeight }" @focus="datas.replyHeight = '200px'"
              @blur="datas.replyHeight = ''"></textarea>
            <a href="javascript:void(0);" class="upload-btn" @click="save()">등록</a>
          </div>
        </div>
      </div>
    </div>
    <spinner ref="spinner"></spinner>
  </div>
</template>

<script>
import mixin from '../../../mixin';
import axios from 'axios';
import clipboard from 'clipboard';
import Spinner from '../../common/Spinner.vue';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

export default {
  components: {
    Spinner,
    TopBackHeaderDolligo
  },
  mixins: [mixin],
  data: function () {
    return {
      datas: {

        // 게시물
        board: {
          wr_content: '',
          g5_member: {
            mb_id: '1',
          },
          mb_id: '',
        },

        // 댓글 리스트
        replies: [],

        // 입력될 comment
        reply: {
          wr_subject: '',
          wr_content: '',
          wr_is_comment: '1',
          wr_parent: '',
          wr_comment: '',
          wr_option: ''
        },

        // 댓글 입력창 높이
        replyHeight: ''
      },
      dolligoCommunity: JSON.parse(sessionStorage.getItem('dolligoCommunity')),
    }
  },
  created: async function () {
    try {
      // 게시물 상세
      this.datas.board = await axios.get(`/api/dolligo/community/${this.$route.params.boardId}/${this.$route.params.wr_id}`, this.axiosConfig);
      this.datas.board = this.datas.board.data;
      if (!this.datas.board) {
        alert('존재하지 않는 글 입니다.');
        this.datas.board = {
          wr_content: '',
          g5_member: {
            mb_id: '1',
          },
          mb_id: '',
        };
        this.$router.replace({ name: 'dolligoCommunity' });
      } else {
        // 댓글
        this.datas.reply.wr_parent = this.datas.board.wr_parent;

        // 댓글리스트
        await this.getReplyList();

        // 조회수 증가
        await this.addReadCount();
      }
    } catch (error) {
      this.errorHandler(error);
    }
  },
  methods: {
    getReplyList: async function () {
      // 댓글리스트
      let axiosConfig = this.axiosConfig;
      axiosConfig.params = {
        wr_is_comment: '1',
        wr_parent: this.datas.board.wr_parent
      }
      this.datas.replies = await axios.get(`/api/dolligo/community/${this.$route.params.boardId}`, axiosConfig);
      this.datas.replies = this.datas.replies.data;
      console.log(this.datas.replies);
    },
    copyUrl: function () {
      let clip = new clipboard('.copyurl', {
        text: function () {
          alert('주소가 복사 되었습니다.');
          return location.href;
        },
      });
      setTimeout(() => {
        clip.destroy();
      }, 500);
    },
    del: async function (wr_id) {
      try {
        if (confirm('삭제 하겠습니까?')) {
          await axios.delete(`/api/dolligo/community/${this.$route.params.boardId}/${wr_id}`, this.axiosConfig);

          if (wr_id == this.$route.params.wr_id) {
            if (this.dolligoCommunity) {
              let self = this;
              let boards = this.dolligoCommunity.boards.filter(function (e) {
                return e.wr_id !== self.$route.params.wr_id;
              })
              this.dolligoCommunity.boards = boards;
              sessionStorage.setItem('dolligoCommunity', JSON.stringify(this.dolligoCommunity));
              //this.$router.back();
              this.$router.push({ name: 'dolligoCommunity' });
            } else {
              this.$router.push({ name: 'dolligoCommunity' });
            }
          } else {
            await this.getReplyList();
            this.updateSession();
          }
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },

    // 댓글 저장
    save: async function () {
      try {
        if (!this.datas.reply.wr_content.trim()) return false;

        this.$refs.spinner.show = true;

        this.datas.reply.wr_content = this.datas.reply.wr_content.trim();
        let comment = await axios.post(`/api/dolligo/community/${this.$route.params.boardId}`, this.datas.reply, this.axiosConfig);
        comment = comment.data;
        await this.getReplyList();
        this.datas.reply.wr_content = '';
        this.datas.reply.wr_comment = '';
        this.datas.reply.wr_option = '';
        this.$refs.spinner.show = false;
        this.updateSession();

        if (this.$refs[`comment-${comment.wr_id}`]) {
          this.$refs[`comment-${comment.wr_id}`][0].scrollIntoView({ block: 'center', behavior: "smooth" });
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },

    // 조회수 증가
    addReadCount: async function () {
      let body = {
        wr_hit: this.datas.board.wr_hit + 1
      }
      await axios.put(`/api/dolligo/community/${this.$route.params.boardId}/${this.$route.params.wr_id}`, body, this.axiosConfig);
      this.updateSession();
    },

    // 세션 수정
    updateSession: async function () {
      if (this.dolligoCommunity) {
        let self = this;
        this.dolligoCommunity.boards.forEach((element, index) => {
          if (element.wr_id == this.$route.params.wr_id) {
            self.dolligoCommunity.boards[index].wr_comment = self.datas.replies.length;
            self.dolligoCommunity.boards[index].wr_hit = self.datas.board.wr_hit + 1;
            self.dolligoCommunity.boards[index].hitCount = self.dolligoCommunity.boards[index].wr_hit.toLocaleString();
          }
        })
        sessionStorage.setItem('dolligoCommunity', JSON.stringify(this.dolligoCommunity));
      }
    },
  }
}
</script>

