<template>
  <div class="blind-wrap">
    <header>
      <router-link :to="{name: 'branch'}" class="icon back-btn">뒤로가기</router-link>
    </header>
    <div class="intro-wrap">
      <h1 class="icon logo">공블리 - 공무원을 위한 익명 커뮤니티</h1>
      <p>공무원의 침묵을 깨고<br>이슈를 만드는 익명 커뮤니티</p>
      <router-link :to="{name: 'blindMemberQuiz'}" class="submit-btn on">공블리 시작하기</router-link>
    </div>
  </div>
</template>

<script>
import mixin from '../../mixin';
export default {
  mixins: [mixin],
  created: function () {
    if (this.MEMBER.b_mb_nick) this.$router.replace({ name: 'blindBoardList', params: { boardId: 'free' } });
  }
}
</script>

