<template>
  <div class="dolligo-web-wrap">
    <header>
      <div class="container">
        <h1>
          <a href="/" class="icon logo">인사교류</a>
        </h1>
      </div>
    </header>
    <div class="intro-wrap">
      <div class="container">
        <h2>다자간 매칭조합을<br />
          <strong>인사교류</strong>에서<br />쉽고 빠르게<span class="icon"></span>
        </h2>
        <h3>공무원 인사교류 자동 매칭 AI 플랫폼</h3>
        <ul class="store-list">
          <li>
            <a href="https://play.google.com/store/apps/details?id=com.hustay.swing.dededae603d0b4850bac7d4209309ce94">
              <span class="icon googleplay"></span>구글플레이</a>
          </li>
          <li>
            <a href="https://itunes.apple.com/us/app/%EC%9D%B8%EC%82%AC%EA%B5%90%EB%A5%98/id1461429441?mt=8">
              <span class="icon appstore"></span>앱스토어</a>
          </li>
        </ul>
        <a :href="appStoreLink" class="mobile-store">앱 다운로드</a>
        <button class="icon scrolldw-btn">내용보기</button>
      </div>
    </div>

    <div class="section-wrap first" id="first">
      <div class="container">
        <div class="section-txt">
          <h2>현재 교류 희망 지역<br />
            <strong class="counter">{{ applyCount }}</strong>
          </h2>
          <a :href="appStoreLink" class="goto-list">교류자 더 보기</a>
        </div>
        <ul class="user-list">
          <li v-for="apply in applyList" v-bind:key="apply.wr_id">
            <a :href="appStoreLink">
              <span v-bind:class="['icon', 'face' + apply.faceType]"></span>
              <span class="infor-row">
                <strong>{{ apply.nickName }}</strong>
                <span class="value">{{ apply.whereJob }}</span>
                <span class="value">{{ apply.jobType }}</span>
                <span class="value">{{ apply.jobPosition }}</span>
              </span>
              <span class="district-row">
                <span class="now">{{ apply.whereFrom }}</span>
                <span class="goal">{{ apply.whereTo }}</span>
              </span>
            </a>
          </li>
        </ul>
        <a :href="appStoreLink" class="goto-list">교류자 더 보기</a>
      </div>
    </div>

    <div class="section-wrap second">
      <div class="container">
        <div class="section-txt">
          <h2>국내외 유일<br />교류자 자동 매칭 알고리즘</h2>
          <p>현재 근무지, 희망 근무지, 직렬 및 기타 상세 조건으로<br />최대 5자 교류까지의 매칭 조합을 찾아드립니다.</p>
        </div>
        <div class="section-image"></div>
      </div>
    </div>

    <div class="section-wrap third">
      <div class="container">
        <div class="section-txt">
          <h2>그룹채팅으로<br />빠른 인사교류 소통!</h2>
          <p>매칭그룹 교류자들과 그룹채팅을 통해<br />인사교류 매칭여부를 빠르게 판단하세요.</p>
        </div>
        <div class="section-image"></div>
      </div>
    </div>

    <div class="section-wrap fourth">
      <div class="container">
        <div class="section-txt">
          <h2>희망하는 행정처의<br />근무환경 리뷰</h2>
          <p>현직 공무원이 직접 작성한 전국 300여 개 행정처의<br />실시간 근무환경 평가를 살펴보세요.</p>
        </div>
        <div class="section-image">
          <span></span>
        </div>
      </div>
    </div>

    <div class="section-wrap fifth">
      <div class="container">
        <div class="section-txt">
          <h2>고민과 성공담을 나누는<br />인사교류 커뮤니티!</h2>
          <p>알쏭달쏭 인사교류 절차를 묻고 답하고<br />고민과 성공담을 나눠보세요.</p>
        </div>
        <div class="section-image"></div>
      </div>
    </div>

    <div class="footer-wrap">
      <div class="container">
        <span>
          <strong>(주)이공이공</strong>
        </span>
        <div class="footer-box">
          <span>경기도 고양시 일산동구 중앙로 1275번길 60-30</span>
          <!-- <span>대표자 : 최용민</span> -->
        </div>
        <div class="footer-box">
          <span>대표전화 :
            <a href="tel:070-7627-2020">070-7627-2020</a>
          </span>
          <span>대표메일 :
            <a href="mailto:doligo3@gmail.com">dolligo3@gmail.com</a>
          </span>
        </div>
        <div class="footer-box">
          <span>사업자등록번호 : 880-86-01578</span>
        </div>
        <div class="footer-box">
          <span>통신판매업 신고번호 : 제 2020-경기부천-0642호</span>
        </div>
        <div class="footer-box">
          <span>
            <router-link to="/terms1">서비스 이용약관</router-link>
          </span>
          <span>
            <router-link to="/terms2">개인정보취급방침</router-link>
          </span>
          <span>
            <router-link to="/branch">인사교류</router-link>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import $ from "jquery";
import mobileDetect from "mobile-detect";
import loadScript from 'load-script';
import counterUp from 'counterup2';
import numeral from 'numeral';
import terms2 from '../components/Terms2.vue';
import EventBus from '../EventBus';
import VueHead from 'vue-head';

Vue.component(terms2.name, terms2);
Vue.use(VueHead);

const md = new mobileDetect(navigator.userAgent);
export default {
  name: "homePage",
  data: function () {
    return {
      appStoreLink: md.is('iPhone') == true
        ? 'https://itunes.apple.com/us/app/%EC%9D%B8%EC%82%AC%EA%B5%90%EB%A5%98/id1461429' +
        '441?mt=8'
        : 'https://play.google.com/store/apps/details?id=com.hustay.swing.dededae603d0b48' +
        '50bac7d4209309ce94',
      applyCount: 0,
      applyList: {},
      showPage: false
    }
  },
  created: function () {
    // agent check
    if (navigator.userAgent.indexOf('gongdoll') != -1) {
      this.$router.push('/branch');
      return;
    }    

    // 이미지 효과
    loadScript('/js/scrollreveal.js', function () {
      // window.ScrollReveal({ reset: true, opacity: 0 });
      var txtUp = {
        distance: '25px',
        origin: 'bottom',
        duration: 1500
      };
      var phoneUp = {
        distance: '40px',
        origin: 'bottom',
        duration: 3000
      };
      window
        .ScrollReveal()
        .reveal('.section-txt', txtUp);
      window
        .ScrollReveal()
        .reveal('.section-image', phoneUp);
    });

    let self = this
    $(async function () {
      // applyCount setting
      await self.getApplyCount();
      const el = document.querySelector('.counter');
      counterUp(el, {
        duration: 1500,
        delay: 10
      });
    });

    //스크롤시 header 클래스 추가
    $(window).bind('scroll', function () {
      if ($(window).scrollTop() > 1) {
        $("header").addClass("scroll");
        $(".mobile-store").addClass("scroll");
        $(".scrolldw-btn").addClass("scroll");
      } else {
        $("header").removeClass("scroll");
        $(".mobile-store").removeClass("scroll");
        $(".scrolldw-btn").removeClass("scroll");
      }
    });

    // 0.2초후 페이지 보이기
    setTimeout(() => {
      this.showPage = true;
    }, 200);
  },
  methods: {
    getApplyCount: async function () {
      let self = this;
      let axios = require('axios');
      let config = {
        method: 'get',
        url: '/api/apply',
        params: {
          limit: 5
        }
      };
      await axios(config)
        .then(function (response) {
          self.applyCount = numeral(response.data.count).format('0,0');
          self.applyList = response.data.rows;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 약관2 보이기
    showTerms2: function () {
      EventBus.$emit('showTerms2', { showTerms2: true });
    }
  },
}
</script>