<template>
  <div class="dolligo-wrap">
    <header class="fixed">
      <!-- <router-link :to="{ name: 'dolligoChat' }" class="icon back-btn"></router-link> -->
      <a class="icon back-btn" @click="goBack1()"></a>
      <h1>그룹채팅</h1>
      <div class="right-btns">
        <a href="javascript:void(0)" class="icon menu-btn" @click="datas.isShowMatchUsers = true">유저</a>
      </div>
    </header>
    <div class="content-wrap bg-green">
      <div class="massage-view-wrap chats">
        <ul ref="messages">
          <infinite-loading direction="top" :identifier="datas.infiniteId" @infinite="getMessages" ref="infiniteLoading">
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
          <li v-for="message in datas.messages" v-bind:key="message.chat_msg_code"
            :class="{ me: message.mb_id == MEMBER.mb_id }">
            <span v-if="message.mb_id != MEMBER.mb_id" :class="`icon face${message.g5_member.mb_36}`">프로필</span>
            <span v-if="message.mb_id != MEMBER.mb_id" class="name">{{ message.g5_member.mb_nick }}</span>

            <div class="bubble-wrap">
              <p class="bubble" style="white-space: pre-line;">
                {{ message.message }}
                <span v-if="message.noview_cnt > 0" class="count">{{ message.noview_cnt }}</span>
              </p>
              <div class="info-box">
                <span>{{ message.regist_date }}</span>
              </div>
            </div>
          </li>
        </ul>
        <div class="write-bar">
          <textarea class="write-message" placeholder="내용을 입력해주세요" v-model="message" :style="{ height: inputHeight }"
            @focus="checkHeight()" @blur="inputHeight = ''" @keyup="checkHeight()" ref="textarea"></textarea>
          <button class="write-btn" @click="sendMessage()">전송</button>
        </div>
      </div>
    </div>

    <div v-show="datas.isShowMatchUsers" class="layer-popup-wrap">
      <div class="chat-menu-popup">
        <div class="head">
          <h2>매칭된 교류자 목록</h2>
          <div class="icon close-btn" @click="datas.isShowMatchUsers = false">닫기</div>
        </div>
        <ul class="user-list">

          <li :class="{ out: people.out_date != null }" v-for="people in datas.peoples"
            v-bind:key="people.chat_people_code">
            <router-link :to="{ name: 'dolligoMemberProfile', params: { id: people.mb_id } }">
              <span :class="faceClass(people)"></span>
              <span class="name">{{ people.nickName }}</span>
              <span class="infor-row">
                <span class="value">{{ people.mb_8 }}</span>
                <span class="value">{{ people.jobName }}･{{ people.rankName }}</span>
                <span class="value">{{ people.sex }}</span>
              </span>
              <span class="district-row">
                <span class="now">{{ people.moving_area.split(' -> ')[0] }}</span>
                <span class="goal">{{ people.moving_area.split(' -> ')[1] }}</span>
              </span>
            </router-link>
          </li>
        </ul>
        <div class="foot">
          <a href="javascript:void(0)" class="out-btn" @click="outChat()">그룹채팅 나가기</a>
          <!-- <a href="#" class="icon alarm-btn">알림끄기</a> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import mixin from '../../../mixin';
import axios from 'axios';
import { setTimeout } from 'timers';
import jsonQuery from 'json-query';

export default {
  mixins: [mixin],
  data: function () {
    return {
      datas: {
        messages: [],
        peoples: [],
        infiniteId: +new Date(),
        lastChatMsgCode: '',
        isShowMatchUsers: false,
      },
      message: '',
      inputHeight: '',
      intervalCount: 0,
    }
  },
  created: async function () {
    try {
      if (this.getSession() == false) {
        // peoples
        this.axiosConfig.params.chatGroupCode = this.$route.params.chatGroupCode;
        this.datas.peoples = await axios.get('/api/dolligo/chat/people', this.axiosConfig);
        this.datas.peoples = this.datas.peoples.data;
        this.scrollBottom();
        let self= this;

        let interval = setInterval(() => {
          if(self.$router.history.current.name == 'dolligoChatView2')
            self.getNewMessage('chat');
            console.log(new Date());
            self.intervalCount++;
            if(self.intervalCount > 30 && sessionStorage.getItem('interval-chat')) 
              clearInterval(parseInt(sessionStorage.getItem('interval-chat')));
        }, 3000);
        sessionStorage.setItem('interval-chat', interval);
      }
      this.removeSession();
    } catch (error) {
      this.errorHandler(error);
    }
  },
  methods: {
    getMessages: async function ($state) {
      try {
        // messages
        delete this.axiosConfig.params.firstChatMsgCode;
        if (this.datas.messages.length > 0) {
          this.datas.lastChatMsgCode = this.datas.messages[0].chat_msg_code;
          this.axiosConfig.params.lastChatMsgCode = this.datas.lastChatMsgCode;
        }
        this.axiosConfig.params.chatGroupCode = this.$route.params.chatGroupCode;
        let messages = await axios.get('/api/dolligo/chat/message', this.axiosConfig);
        messages = messages.data;

        if (messages.length == 0) {
          $state.complete();
        } else {
          this.datas.messages.unshift(...messages);
          $state.loaded();
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    scrollBottom: function () {
      setTimeout(() => {
        let messages = this.$refs.messages;
        if (messages) {
          window.scrollTo({ top: messages.scrollHeight - 560, behavior: 'smooth' });
        }
      }, 100);
    },
    faceClass: function (user) {
      if (user.mb_id == this.MEMBER.mb_id) {
        return `icon face${user.faceType} on me`;
      } else {
        return `icon face${user.faceType}`;
      }
    },
    outChat: async function () {
      try {
        if (confirm('채팅방을 나가 시겠습니까?')) {
          let self = this;
          let peoples = this.datas.peoples.filter(function (e) {
            return e.mb_id === self.MEMBER.mb_id;
          })
          let chatPeopleCode = peoples[0].chat_people_code;
          delete this.axiosConfig.params;
          await axios.delete(`/api/dolligo/chat/people/${chatPeopleCode}`, this.axiosConfig);
          this.$router.push({ name: 'dolligoChat' });
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    sendMessage: async function () {
      if (this.message) {
        let data = { chatGroupCode: this.$route.params.chatGroupCode, message: this.message }
        await axios.post(`/api/dolligo/chat/message`, data, this.axiosConfig);
        this.getNewMessage('chat');
        this.message = '';
      }
    },
    // 신규 메시지
    getNewMessage: async function (type) {
      try {
        delete this.axiosConfig.params.lastChatMsgCode;
        if (this.datas.messages.length > 0) this.axiosConfig.params.firstChatMsgCode = this.datas.messages[this.datas.messages.length - 1].chat_msg_code;
        this.axiosConfig.params.chatGroupCode = this.$route.params.chatGroupCode;
        let messages = await axios.get('/api/dolligo/chat/message', this.axiosConfig);
        messages = messages.data;
        messages.forEach(element => {
          let result = jsonQuery(`[chat_msg_code=${element.chat_msg_code}]`, {
            data: this.datas.messages
          }).value;
          if (!result) {
            this.datas.messages.push(element);
            if(element.mb_id != this.MEMBER.mb_id) this.readMessage(element.mb_id);
          }
        });
        if (type == 'chat' && messages.length > 0) {
          this.scrollBottom();
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },

    // 읽음 처리
    readMessage: async function (mb_id) {
      this.datas.messages = await this.datas.messages.map(function (message) {
        if (message.view_mb_ids.indexOf(mb_id) == -1) {
          message.view_mb_ids = message.view_mb_ids + ',' + mb_id;
          message.noview_cnt = message.noview_cnt > 0 ? message.noview_cnt - 1 : 0;
        }
        return message;
      })
    },

    // 입력창 스크롤 높이 체크해서 높이값 조정
    checkHeight: function () {
      let message = this.message.split('\n');
      this.inputHeight = 50 + (message.length - 1) * 25 + 'px';
    },

    goBack1: function () {
      this.$router.go(-1);
    }    
  }
}
</script>

