<template>
  <div class="dolligo-wrap">
    <header>
      <dolligo-top></dolligo-top>
      <h1>채팅&amp;쪽지</h1>
    </header>
    <div class="content-wrap bg-white">
      <ul class="page-info-list">
        <li>프리미엄 멤버십에 가입하시면 무제한 그룹 채팅 이용과<br />상대방 쪽지 응답률이 표시됩니다.</li>
      </ul>
      <ul class="tab-menu">
        <li>
          <a href="javascript:void(0)" class="on"><span>그룹 채팅</span><span class="icon premium"></span></a>
        </li>
        <li>
          <router-link :to="{ name: 'dolligoMemo' }"><span>쪽지</span></router-link>
        </li>
      </ul>

      <ul class="chat-list">
        <li v-for="chat in datas.chats" v-bind:key="chat.chat_group_code" :code="chat.chat_msg_code">
          <router-link :to="{ name: 'dolligoChatView2', params: {chatGroupCode: chat.chat_group_code} }">
            <!-- <a :href="`/dolligo/chat/${chat.chat_group_code}`"> -->
            <!-- <span class="choice">{{ chat.applyOrd }}지망</span> -->
            <span class="choice">&nbsp;</span>
            <strong class="district">{{ chat.moving_area }}</strong>
            <ol>
              <li v-for="user in chat.chat_people" v-bind:key="user.chat_group_code" :class="faceClass(user)"
                style="margin-right:7px;"></li>
            </ol>
            <span class="chat-box">
              <p class="message">{{ chat.message }}</p>
              <span class="date">{{ chat.regist_date }}</span>
              <span class="count" v-if="chat.unReadCount > 0">{{ chat.unReadCount > 99 ? '99+' : chat.unReadCount
              }}</span>
            </span>
            <!-- </a> -->
          </router-link>
        </li>
        <infinite-loading :identifier="datas.infiniteId" @infinite="getChats" ref="infiniteLoading">
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </ul>

      <div v-if="MEMBER.mb_level < 5 && datas.chats.length == 0 && isCreated == true" class="empty-states premium">
        <p><strong>프리미엄 멤버십</strong>에 가입하면<br>인사교류에 최적화된 다양한 서비스를<br><strong>무제한</strong>으로 이용할 수 있습니다.</p>
        <router-link :to="{ name: 'dolligoPremiumReady' }" class="submit-btn">프리미엄 가입하기</router-link>
      </div>

      <div v-if="MEMBER.mb_level >= 5 && datas.chats.length == 0 && isCreated == true" class="empty-states premium">
        <p><strong>매칭찾기</strong>에서 <strong>매칭그룹</strong>을 찾으신 후<br><strong>그룹채팅</strong>을 시작하세요!</p>
        <router-link :to="{ name: 'dolligoMatchIndex' }" class="submit-btn">매칭찾기</router-link>
      </div>
    </div>

    <dolligo-footer propType="dolligoChat"></dolligo-footer>
  </div>
</template>

<script>
import DolligoTop from '../../common/DolligoTop.vue';
import DolligoFooter from '../../common/DolligoFooter.vue';
import mixin from '../../../mixin';
import axios from 'axios';
import jsonQuery from 'json-query';

export default {
  mixins: [mixin],
  components: {
    DolligoTop,
    DolligoFooter,
  },
  data: function () {
    return {
      datas: {
        chats: [],
        scrollTop: 0,
        infiniteId: +new Date(),
        last_chat_msg_code: ''
      },
      isCreated: false
    }
  },
  created: async function () {
    // console.log(this.$route.name);
  },
  methods: {
    faceClass: function (user) {
      if (user.mb_id == this.MEMBER.mb_id) {
        return `icon face${user.faceType} on me`;
      } else {
        return `icon face${user.faceType}`;
      }
    },
    getChats: async function ($state) {
      try {
        // console.log(this.datas.last_chat_msg_code);
        this.axiosConfig.params.last_chat_msg_code = this.datas.last_chat_msg_code;
        let chats = await axios.get('/api/dolligo/chatgroup', this.axiosConfig);
        chats = chats.data;
        // console.log(chats.length);
        if (chats.length == 0) {
          $state.complete();
        } else {
          this.datas.last_chat_msg_code = chats[chats.length - 1].chat_msg_code;
          chats.forEach(element => {
            let result = jsonQuery(`[chat_group_code=${element.chat_group_code}]`, {
              data: this.datas.chats
            }).value;
            if (!result && element.moving_area) {
              this.datas.chats.push(element);
            }
          });
          $state.loaded();
        }
        this.isCreated = true;
      } catch (error) {
        this.errorHandler(error);
      }
    }
  }
}
</script>

