import DolligoChatIndex from '@/components/dolligo/chat/Index';
import DolligoChatView from '@/components/dolligo/chat/View';
import DolligoChatView2 from '@/components/dolligo/chat/View2';

const dolligoView = [
  {
    path: '/dolligo/chat2/:chatGroupCode',
    name: 'dolligoChatView2',
    component: DolligoChatView2,
    meta: {
      checkAgent: true,
      checkAuth: true,
      checkAuthDolligo: true
    }
  },{
  path: '/dolligo/chat',
  name: 'dolligoChat',
  component: DolligoChatIndex,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}
  , {
  path: '/dolligo/chat/:chatGroupCode',
  name: 'dolligoChatView',
  component: DolligoChatView,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}]

export default dolligoView