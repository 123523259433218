<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`회원 정보 입력`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="write-wrap">
        <div class="input-wrap number">
          <input type="tel" v-model="member.mb_tel" required="required" placeholder="휴대폰 번호 입력" readonly="readonly" />
          <router-link to="/certnum?changephonenumber=true" class="submit-btn on">변경</router-link>
        </div>
        <div class="input-wrap">
          <input type="text" placeholder="닉네임 입력" required="required" v-model="member.mb_nick"
            v-on:change="checkNickName" v-on:keyup="checkNickName" @input="checkNickName" maxlength="12" />
          <div v-bind:class="{'helper-txt':true, error:errorNickNameText}">{{errorNickNameText}}</div>
        </div>
        <div class="list-wrap">
          <ul id="ulSex">
            <li>
              <a href="javascript:void(0);" v-bind:class="{'submit-btn':true, on:member.mb_sex=='M'}"
                v-on:click="member.mb_sex='M'">남자</a>
              <a href="javascript:void(0);" v-bind:class="{'submit-btn':true, on:member.mb_sex=='F'}"
                v-on:click="member.mb_sex='F'">여자</a>
              <a href="javascript:void(0);" v-bind:class="{'submit-btn':true, on:member.mb_sex=='N'}"
                v-on:click="member.mb_sex='N'">선택안함</a>
            </li>
          </ul>
        </div>
        <div class="list-wrap">
          <h2>프로필 이미지</h2>
          <ul class="face-list">
            <li>
              <input type="radio" v-bind:class="{icon:true, face1:true, on:member.mb_36==1}" v-model="member.mb_36"
                value="1" />
            </li>
            <li>
              <input type="radio" v-bind:class="{icon:true, face2:true, on:member.mb_36==2}" v-model="member.mb_36"
                value="2" />
            </li>
            <li>
              <input type="radio" v-bind:class="{icon:true, face3:true, on:member.mb_36==3}" v-model="member.mb_36"
                value="3" />
            </li>
            <li>
              <input type="radio" v-bind:class="{icon:true, face4:true, on:member.mb_36==4}" v-model="member.mb_36"
                value="4" />
            </li>
            <li>
              <input type="radio" v-bind:class="{icon:true, face5:true, on:member.mb_36==5}" v-model="member.mb_36"
                value="5" />
            </li>
            <li>
              <input type="radio" v-bind:class="{icon:true, face6:true, on:member.mb_36==6}" v-model="member.mb_36"
                value="6" />
            </li>
            <li>
              <input type="radio" v-bind:class="{icon:true, face7:true, on:member.mb_36==7}" v-model="member.mb_36"
                value="7" />
            </li>
            <li>
              <input type="radio" v-bind:class="{icon:true, face8:true, on:member.mb_36==8}" v-model="member.mb_36"
                value="8" />
            </li>
          </ul>
        </div>

        <div class="list-wrap">
          <h2>현재 근무정보</h2>
          <ul>
            <li>
              <a href="javascript:void(0);" v-bind:class="{'submit-btn':true, on:member.mb_8=='국가직'}"
                v-on:click="member.mb_8='국가직'">국가직</a>
              <a href="javascript:void(0);" v-bind:class="{'submit-btn':true, on:member.mb_8=='지방직'}"
                v-on:click="member.mb_8='지방직'">지방직</a>
            </li>
            <li>
              <div class="check-wrap profile">
                <label for="time" class="form-check-label">
                  <input type="checkbox" id="time" class="form-check-input" v-model="member.mb_39" true-value="1"
                    false-value="" />시간선택제 공무원<span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
            </li>
            <li v-if="member.mb_8=='국가직'">
              <select v-model="member.mb_17" required="required">
                <option value="">현재 행정처 선택</option>
                <option v-for="office in offices" v-bind:key="office.grd3_cd" v-bind:value="office.grd3_cd">
                  {{office.grd3_nm}}</option>
              </select>
            </li>

            <li>
              <select v-model="member.mb_9" v-on:change="getDistricts(true)" required="required">
                <option value="">광역.시.도선택</option>
                <option v-for="city in cities" v-bind:key="city.addr1_cd" v-bind:value="city.addr1_cd">
                  {{city.addr1_nm}}</option>
              </select>
              <select v-model="member.mb_10" required="required">
                <option value="">시.군.구선택</option>
                <option v-for="district in districts" v-bind:key="district.addr2_cd" v-bind:value="district.addr2_cd">
                  {{district.addr2_nm}}</option>
              </select>
            </li>

            <li>
              <select v-model="member.mb_1" required="required">
                <option value="">직군선택</option>
                <option v-for="job in jobs" v-bind:key="job.grd1_cd" v-bind:value="job.grd1_cd">
                  {{job.grd1_nm}}</option>
              </select>
              <select v-model="member.mb_2" required="required">
                <option value="">직급선택</option>
                <option v-for="rank in ranks" v-bind:key="rank.grd2_cd" v-bind:value="rank.grd2_cd">
                  {{rank.grd2_nm}}</option>
              </select>
            </li>
            <li>
              <select v-model="member.mb_memo_call" required="required">
                <option value="">교류 상태를 선택해 주세요.</option>
                <option v-bind:value="1">준비중(전보 제한 중)</option>
                <option v-bind:value="2">관망중(탐색 중)</option>
                <option v-bind:value="3">인사교류 진행 중</option>
                <option v-bind:value="4">교류희망(앞으로3개월 내외)</option>
                <option v-bind:value="5">교류희망(앞으로1년 내외)</option>
                <option v-bind:value="6">교류의사 없음</option>
                <option v-bind:value="7">인사교류 성공!</option>
              </select>
            </li>
            <li>
              <textarea placeholder="교류 상세설명 입력 100자 이내" v-model="member.mb_5" maxlength="100"></textarea>
            </li>

            <li>
              <div class="check-wrap" v-if="!member.mb_12">
                <label for="mb_11" class="form-check-label">
                  <input type="checkbox" class="form-check-input" v-model="member.mb_11" id="mb_11" true-value="y"
                    false-value="n" required="required" />이용약관 &#40;필수&#41;<span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
                <a href="javascript:void(0)" class="term-btn" v-on:click="showTerms1m">약관보기</a>
                <div v-bind:class="{'helper-txt':true, error:member.mb_11=='n'}">동의는 필수입니다.</div>
              </div>

              <div class="check-wrap" v-if="!member.mb_14">
                <label for="mb_13" class="form-check-label">
                  <input type="checkbox" class="form-check-input" v-model="member.mb_13" id="mb_13" true-value="y"
                    false-value="n" required="required" />개인정보 수집 및 이용
                  &#40;필수&#41;<span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
                <a href="javascript:void(0)" class="term-btn" v-on:click="showTerms2m">약관보기</a>
                <div v-bind:class="{'helper-txt':true, error:member.mb_13=='n'}">동의는 필수입니다.</div>
              </div>

              <div class="check-wrap">
                <label for="mb_15" class="form-check-label">
                  <input type="checkbox" class="form-check-input" v-model="member.mb_15" id="mb_15" true-value="y"
                    false-value="n" />마케팅 목적 개인정보 이용 동의 &#40;선택&#41;<span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
                <a href="javascript:void(0)" class="term-btn" v-on:click="showTerms3m">약관보기</a>
                <div class="helper-txt">선택</div>
              </div>
            </li>
          </ul>
          <p class="notice">개인정보보안을 위해 6개월간 미사용시 교류 허용이 OFF처리 됩니다.</p>
        </div>
        <button type="submit" v-bind:class="{'submit-btn':true, on:isSubmit()}" v-on:click="saveMember()">저장</button>
      </div>
      <div class="dropout-wrap" v-if="member.mb_12">
        <router-link :to="{name: 'dolligoMemberOut'}" class="txt-btn">회원 탈퇴하기</router-link>
      </div>
    </div>
    <terms1 :showTerms="showTerms1" :isLayer="true" ref="childTerms1"></terms1>
    <terms2 :showTerms="showTerms2" :isLayer="true" ref="childTerms2"></terms2>
    <terms3 :showTerms="showTerms3" :isLayer="true" ref="childTerms3"></terms3>
  </div>
</template>

<script>
  import terms1 from '../../Terms1.vue'
  import terms2 from '../../Terms2.vue';
  import terms3 from '../../Terms3.vue';
  import axios from 'axios';
  import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';
  import mixin from '../../../mixin'

  export default {
    name: 'dolligoMemberRegist',
    mixins: [mixin],
    components: {
      terms1,
      terms2,
      terms3,
      TopBackHeaderDolligo
    },
    data: function () {
      return {
        mb_sex: '',
        member: this.MEMBER,
        cities: [],
        districts: [],
        jobs: [],
        ranks: [],
        offices: [],
        errorNickNameText: "",
        showTerms1: false,
        showTerms2: false,
        showTerms3: false
      }
    },
    created: async function () {
      let self = this;

      // 광역시도
      let config = {
        method: 'get',
        url: '/api/cities',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      };
      axios(config)
        .then(function (response) {
          self.cities = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });

      // 직군(직렬)
      config = {
        method: 'get',
        url: '/api/jobs',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      };
      axios(config)
        .then(function (response) {
          self.jobs = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });

      // 직급
      config = {
        method: 'get',
        url: '/api/ranks',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      };
      axios(config)
        .then(function (response) {
          self.ranks = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });

      // 행정처
      config = {
        method: 'get',
        url: '/api/offices',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      };
      axios(config)
        .then(function (response) {
          self.offices = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });

      // 시군구
      this.getDistricts(false);

      // let self = this;
      window.onpopstate = function () {
        if(self.$refs.childTerms1){
          self.$refs.childTerms1.showTerms1 = false;
          self.$refs.childTerms2.showTerms2 = false;
          self.$refs.childTerms3.showTerms3 = false;
        }
      };      
    },
    methods: {
      // // 약관1 보이기
      showTerms1m: function () {
        this.$refs.childTerms1.showTerms1 = true;
        history.pushState('', '', '#showTerms1');
      },

      // 약관2 보이기
      showTerms2m: function () {
        this.$refs.childTerms2.showTerms2 = true;
        history.pushState('', '', '#showTerms2');
      },

      // 약관3 보이기
      showTerms3m: function () {
        this.$refs.childTerms3.showTerms3 = true;
        history.pushState('', '', '#showTerms3');
      },

      // 시군구
      getDistricts: function (isChange) {
        if (isChange == true)
          this.member.mb_10 = '';

        if (this.member.mb_9) {
          let self = this;
          let config = {
            method: 'get',
            url: '/api/districts',
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            params: {
              cityCode: self.member.mb_9
            }
          };
          axios(config)
            .then(function (response) {
              self.districts = response.data;
            })
            .catch(function (error) {
              console.log(error);
            });

        } else {
          this.districts = [];
        }
      },

      // isSubmit
      isSubmit: function () {
        // 닉네임
        if (this.member.mb_nick && !this.member.mb_nick.replace(/ /g, "")) {
          return false;
        }

        // 성별
        if (!this.member.mb_sex) {
          return false;
        }

        // 플필이미지
        if (!this.member.mb_36) {
          return false;
        }

        // 국가직, 지방직
        if (!this.member.mb_8) {
          return false;
        }

        // 국가직 이면 행정처 선택
        if (this.member.mb_8 == '국가직' && !this.member.mb_17) {
          return false;
        }

        // 광역시도, 시군구
        if (!this.member.mb_9 || !this.member.mb_10) {
          return false;
        }

        // 직군, 직급
        if (!this.member.mb_1 || !this.member.mb_2) {
          return false;
        }

        // 교류상태
        if (!this.member.mb_memo_call) {
          return false;
        }

        // 이용약관
        if (this.member.mb_11 != 'y') {
          return false;
        }

        // 개인정보수집
        if (this.member.mb_13 != 'y') {
          return false;
        }

        return true;
      },

      // 저장
      saveMember: async function () {
        try {
          if (this.isSubmit() == false) return false;
          if (this.member.mb_8 == '지방직') this.member.mb_17 = '';
          let response = await axios.post(`/api/member/step1`, this.member, this.axiosConfig);
          this.$router.push(response.data.goUrl);               
        } catch (error) {
          this.errorHandler(error);
        }  
      },

      // 닉네임 제한 입력
      checkNickName: async function (e) {
        // 한글,숫자,영문 만 입력
        e.target.value = e
          .target
          .value
          .replace(/[^(가-힣ㄱ-ㅎㅏ-ㅣㆍᆢa-zA-Z0-9\-)]/gi,'');
        this.member.mb_nick = e.target.value;

        // 글자수
        if (this.member.mb_nick.length == 1) {
          this.errorNickNameText = "닉네임은 2~12자로 입력해 주세요.";
          return;
        }

        // 닉네임 중복 체크
        let self = this;
        if (self.member.mb_nick.length > 1) {
          let config = {
            method: 'get',
            url: '/api/member/nickname/dup',
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            params: {
              nickName: self.member.mb_nick
            }
          };
          let response = await axios(config);
          if (response.data.dupNickNameCount > 0) this.errorNickNameText = "사용할 수 없는 닉네임 입니다.";
          else this.errorNickNameText = "";
        } else {
          this.errorNickNameText = "";
        }
      },
    }
  }
</script>

