<template>
  <div class="dolligo-wrap">
    <header>
      <h1>프리미엄 멤버십</h1>
    </header>
    <div class="content-wrap bg-white">
      <div class="center-wrap">
        <p class="center-txt"><strong>프리미엄 멤버십</strong><br>가입을 축하드립니다.</p>
        <router-link :to="{name: 'dolligoMain'}" class="submit-btn on">확인</router-link>
      </div>
      <div class="premium-benefit">
        <h3><strong>프리미엄 멤버십</strong> 혜택 4가지</h3>
        <ul>
          <li>
            <h3>그룹 채팅</h3>
            <p>매칭된 교류상대와 빠르게 소통할 수 있어요.</p>
          </li>
          <li>
            <h3>상세 매칭 조건</h3>
            <p>연령대, 전보제한, 일방전출, 강임허용 등 검색 조건이 강화되었습니다.</p>
          </li>
          <li>
            <h3>근무환경 리뷰</h3>
            <p>어디서도 얻을 수 없는 ‘꿀팁’ 얻어가세요.</p>
          </li>
          <li>
            <h3>쪽지 응답률</h3>
            <p>보다 빨라진 교류자의 피드백을 경험해보세요.</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../../../mixin';
export default {
  mixins: [mixin],
}
</script>

