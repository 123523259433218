<template>
  <div class="dolligo-wrap">
    <!-- <header class="fixed">
      <a :href="backUrl" class="icon back-btn">뒤로가기</a>
      <h1>근무 환경 평가</h1>
      <div class="right-btns">
        <a href="javascript:void(0)" class="txt-btn" v-bind:class="{on: isSave()}" @click="save()">저장</a>
      </div>
    </header> -->
    <top-back-header-dolligo :propTitle="`근무 환경 평가`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="review-office-wrap">
        <div class="list-wrap">
          <h2>근무 기간</h2>
          <ul>
            <li>
              <select v-model="data.review.wr_1">
                <option value="">년도 선택</option>
                <option v-for="value in data.years" v-bind:key="value" v-bind:value="value">{{value}}</option>
              </select>
              <select v-model="data.review.wr_2">
                <option value="">년도 선택</option>
                <option v-for="value in data.years" v-bind:key="value" v-bind:value="value">{{value}}</option>
              </select>
            </li>
            <li>
              <label class="submit-btn" :class="{on: data.review.po_type=='지방직'}"><input type="radio" value="지방직"
                  v-model="data.review.po_type" @click="data.review.branch_code='';" /> 지방직 </label>
              <label class="submit-btn" :class="{on: data.review.po_type=='국가직'}"><input type="radio" value="국가직"
                  v-model="data.review.po_type" /> 국가직
              </label>
            </li>
            <li v-if="data.review.po_type=='국가직'">
              <select v-model="data.review.branch_code">
                <option value="">행정처 선택 주세요.</option>
                <option v-for="item in data.offices" v-bind:key="item.grd3_cd" v-bind:value="item.grd3_cd">{{item.grd3_nm}}
                </option>
              </select>
            </li>
            <li @change="getDistricts()">
              <select v-model="data.review.addr1_code">
                <option value="">근무지 광역.시.도 선택</option>
                <option v-for="item in data.cities" v-bind:key="item.addr1_cd" v-bind:value="item.addr1_cd">
                  {{item.addr1_nm}}</option>
              </select>
            </li>
            <li>
              <select v-model="data.review.addr2_code">
                <option value="">근무지 시.군.구 선택</option>
                <option v-for="item in data.districts" v-bind:key="item.addr2_cd" v-bind:value="item.addr2_cd">
                  {{item.addr2_nm}}</option>
              </select>
            </li>
            <li>
              <select v-model="data.review.job_group">
                <option value="">근무지 직렬 선택</option>
                <option v-for="item in data.jobs" v-bind:key="item.grd1_cd" v-bind:value="item.grd1_cd">
                  {{item.grd1_nm}}</option>
              </select>
            </li>
          </ul>
          <ul class="rating-list">
            <li>
              <div class="rating-tit">
                <h2>복지혜택</h2>
                <span class="info-txt">복지포인트, 연차사용, 보건휴가, 특별휴가 등</span>
              </div>
              <div class="rating-star">
                <span class="star" v-bind:class="{on : data.review.wr_3 >= 1}" @click="data.review.wr_3=1">1</span>
                <span class="star" v-bind:class="{on : data.review.wr_3 >= 2}" @click="data.review.wr_3=2">2</span>
                <span class="star" v-bind:class="{on : data.review.wr_3 >= 3}" @click="data.review.wr_3=3">3</span>
                <span class="star" v-bind:class="{on : data.review.wr_3 >= 4}" @click="data.review.wr_3=4">4</span>
                <span class="star" v-bind:class="{on : data.review.wr_3 >= 5}" @click="data.review.wr_3=5">5</span>
              </div>
            </li>
            <li>
              <div class="rating-tit">
                <h2>일&amp;삶 균형</h2>
                <span class="info-txt">정시퇴근 및 잔업 없음 등</span>
              </div>
              <div class="rating-star">
                <span class="star" v-bind:class="{on : data.review.wr_4 >= 1}" @click="data.review.wr_4=1">1</span>
                <span class="star" v-bind:class="{on : data.review.wr_4 >= 2}" @click="data.review.wr_4=2">2</span>
                <span class="star" v-bind:class="{on : data.review.wr_4 >= 3}" @click="data.review.wr_4=3">3</span>
                <span class="star" v-bind:class="{on : data.review.wr_4 >= 4}" @click="data.review.wr_4=4">4</span>
                <span class="star" v-bind:class="{on : data.review.wr_4 >= 5}" @click="data.review.wr_4=5">5</span>
              </div>
            </li>
            <li>
              <div class="rating-tit">
                <h2>승진기회</h2>
              </div>
              <div class="rating-star">
                <span class="star" v-bind:class="{on : data.review.wr_5 >= 1}" @click="data.review.wr_5=1">1</span>
                <span class="star" v-bind:class="{on : data.review.wr_5 >= 2}" @click="data.review.wr_5=2">2</span>
                <span class="star" v-bind:class="{on : data.review.wr_5 >= 3}" @click="data.review.wr_5=3">3</span>
                <span class="star" v-bind:class="{on : data.review.wr_5 >= 4}" @click="data.review.wr_5=4">4</span>
                <span class="star" v-bind:class="{on : data.review.wr_5 >= 5}" @click="data.review.wr_5=5">5</span>
              </div>
            </li>
            <li>
              <div class="rating-tit">
                <h2>동료&amp;상사</h2>
                <span class="info-txt">전입자 대우, 수평적 분위기 등</span>
              </div>
              <div class="rating-star">
                <span class="star" v-bind:class="{on : data.review.wr_6 >= 1}" @click="data.review.wr_6=1">1</span>
                <span class="star" v-bind:class="{on : data.review.wr_6 >= 2}" @click="data.review.wr_6=2">2</span>
                <span class="star" v-bind:class="{on : data.review.wr_6 >= 3}" @click="data.review.wr_6=3">3</span>
                <span class="star" v-bind:class="{on : data.review.wr_6 >= 4}" @click="data.review.wr_6=4">4</span>
                <span class="star" v-bind:class="{on : data.review.wr_6 >= 5}" @click="data.review.wr_6=5">5</span>
              </div>
            </li>
            <li>
              <textarea placeholder="근무환경 평가(10자이상 )" v-model.trim="data.review.wr_7" minlength="10"></textarea>
            </li>
            <li>
              <button class="submit-btn" :class="{on: isSave()}" @click="save()">저장</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import mixin from '../../../mixin';
  import moment from 'moment';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

  export default {
  components: { TopBackHeaderDolligo },
    mixins: [mixin],
    data: function () {
      return {
        data: {
          review: {
            wr_1: '',
            wr_2: '',
            branch_code: '',
            addr1_code: '',
            addr2_code: '',
            job_group: '',
            wr_3: 0,
            wr_4: 0,
            wr_5: 0,
            wr_6: 0,
            wr_7: ''
          },
          years: [],
          offices: [],
          cities: [],
          districts: [],
          jobs: []
        }
      }
    },
    created: async function () {
      // 수정시
      if (this.$route.params.id) {
        let review = await axios.get(`/api/dolligo/review/${this.$route.params.id}`, this.axiosConfig);
        this.data.review = review.data;
        delete this.data.review.wr_id;
        delete this.data.review.mb_id;
        delete this.data.review.wr_datetime;
        delete this.data.review.wr_last;
      }

      let endYear = parseInt(moment().format('Y'));
      let startYear = endYear - 60;
      for (startYear; startYear <= endYear; startYear++) {
        this.data.years.push(startYear);
      }
      this.data.years.reverse();

      // 행정처
      this.data.offices = await axios.get('/api/offices', this.axiosConfig);
      this.data.offices = this.data.offices.data;

      // 광역시도
      this.data.cities = await axios.get('/api/cities', this.axiosConfig);
      this.data.cities = this.data.cities.data;

      // 광역시도 선택되어 있다면
      if (this.data.review.addr1_code) {
        this.getDistricts();
      }

      // 직렬
      this.data.jobs = await axios.get('/api/jobs', this.axiosConfig);
      this.data.jobs = this.data.jobs.data;
    },
    methods: {
      isSave: function () {
        if (!this.data.review.wr_1) return false;
        if (!this.data.review.wr_2) return false;
        if (!this.data.review.wr_3) return false;
        if (!this.data.review.wr_4) return false;
        if (!this.data.review.wr_5) return false;
        if (!this.data.review.wr_6) return false;
        if (!this.data.review.wr_7) return false;
        if (!this.data.review.addr1_code) return false;
        if (!this.data.review.addr2_code) return false;
        if (!this.data.review.po_type) return false;
        if (!this.data.review.job_group) return false;
        if (this.data.review.po_type == '국가직' && !this.data.review.branch_code) return false;
        if (this.data.review.wr_1 > this.data.review.wr_2) return false;
        if (this.data.review.wr_7.length < 10) return false;
        return true;
      },
      getDistricts: async function () {
        let axiosConfig = this.axiosConfig;
        axiosConfig.params.cityCode = this.data.review.addr1_code;
        this.data.districts = await axios.get('/api/districts', this.axiosConfig); // 시군구
        this.data.districts = this.data.districts.data;
      },
      save: async function () {
        try {
          if (this.isSave() == false) return false;
          if (this.$route.params.id) {
            let body = this.data.review;
            await axios.put(`/api/dolligo/review/${this.$route.params.id}`,
              body, {
                headers: this.axiosConfig.headers
              });
          } else {
            let body = this.data.review;
            await axios.post(`/api/dolligo/review`,
              body, {
                headers: this.axiosConfig.headers
              });
          }
          this.$router.go(-1);
        } catch (error) {
          this.errorHandler(error);
        }
      }
    }
  }
</script>

