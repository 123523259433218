<template>
  <li>
    <div class="input-wrap">
      <select v-model="addr.b_mb_city" @change="getDistricts()">
        <option value="">광역.시.도선택</option>
        <option v-for="item in cities" v-bind:key="item.addr1_cd" v-bind:value="item.addr1_cd">{{item.addr1_nm}}
        </option>
      </select>
      <select v-model="addr.b_mb_district" @change="onDataChanged">
        <option value="">시.군.구선택</option>
        <option v-for="item in districts" v-bind:key="item.addr2_cd" v-bind:value="item.addr2_cd">
          {{item.addr2_nm}}</option>
      </select>
    </div>
  </li>
</template>

<script>
import mixin from '../../mixin';
import axios from 'axios';

export default {
  mixins: [mixin],
  props: ['addrCode'],
  data: function () {
    return {
      addr: this.addrCode,
      cities: [],
      districts: [],
    }
  },
  created: async function () {
    this.cities = await axios.get('/api/cities', this.axiosConfig); // 광역시도
    this.cities = this.cities.data;

    if (this.addr.b_mb_city) {
      await this.getDistricts();
    }
  },
  methods: {
    // 시군구
    getDistricts: async function () {
      this.axiosConfig.params.cityCode = this.addr.b_mb_city;
      this.districts = await axios.get('/api/districts', this.axiosConfig); // 시군구
      this.districts = this.districts.data;
      let self = this;
      let addr = await this.districts.filter(function (e) {
        return e.addr2_cd == self.addr.b_mb_district;
      });
      if (addr.length == 0) this.addr.b_mb_district = '';
      this.$emit('send-addr', this.addr) // input 이벤트 발생
    },
    onDataChanged() {
      this.$emit('send-addr', this.addr) // input 이벤트 발생
    }
  }
}
</script>

