<template>
  <div class="dolligo-wrap">
    <header class="fixed">
      <a href="javascript:history.back();" class="icon back-btn">뒤로가기</a>
      <h1>핸드폰 번호 인증</h1>
    </header>
    <div class="content-wrap bg-white">
      <div class="write-wrap">
        <div class="list-wrap">
          <h2>휴대폰 번호 로그인/회원가입</h2>
          <h3>휴대폰 번호는 인사교류 알림톡 및 공지사항 알림에만 사용되며 타인에게 공개되지 않습니다. {{ hostname }}</h3>
          <ul>
            <li>
              <div class="input-wrap number">
                <input type="tel" placeholder="휴대폰 번호 입력" v-model="mobile" v-on:change="checkMobileNumber"
                  v-on:keyup="checkMobileNumber" @input="checkMobileNumber" maxlength="11"
                  @keyup.enter="methodSendCertNumber()" />
                <a href="javascript:void(0);"
                  v-bind:class="{ 'submit-btn': true, on: mobile && mobile.length == 11 && isSendCertNumer == false }"
                  v-on:click="methodSendCertNumber()">전송</a>
              </div>
            </li>
            <li v-if="isSendCertNumer">
              <div class="input-wrap number">
                <input type="tel" placeholder="인증번호 입력" maxlength="6" v-model="certNumber" v-on:change="checkCertNumber"
                  v-on:keyup="checkCertNumber" @input="checkCertNumber" ref="certNumber" @keyup.enter="methodCheckCertNumber()" />
                <a href="javascript:void(0);"
                  v-bind:class="{ 'submit-btn': true, on: certNumber && certNumber.length == 6 }"
                  v-on:click="methodCheckCertNumber()">인증</a>
                <div class="helper-txt">유효시간
                  <strong>{{ expireTime }}</strong>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <ul class="page-info-list" v-if="isCheckCertNumber">
          <li>인증번호 받은후 3분 이내로 인증번호(6자리)를 입력해 주세요.</li>
          <li>인증번호가 전송되지 않을 경우 ‘재전송’ 버튼을 눌러주세요.</li>
        </ul>
        <a href="javascript:void(0);" class="submit-btn" :disabled="isCheckCertNumber" v-if="isCheckCertNumber">다음</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '../mixin';

export default {
  name: 'certNum',
  mixins: [mixin],
  data: function () {
    return {
      mobile: '',
      certNumber: '',
      isSendCertNumer: false,
      isCheckCertNumber: false,
      expireTime: '03:00',
      timer: null,
      hostname : location.hostname
    }

  },
  created: async function () {
    try {
      let member = await axios.get('/api/member', this.axiosConfig);
      if (member && member.data.mb_certify == '1' && this.$route.query.changephonenumber !== 'true')
        this.$router.push('/branch');
    } catch (error) {
      this.errorHandler(error)
    }
  },
  methods: {
    // 인증문자 보내기
    methodSendCertNumber: async function () {
      try {
        // 전화번호 검사
        if (!this.mobile || this.mobile.length != 11 || this.isSendCertNumer == true) return false;

        let data = { mobile: this.mobile };
        await axios.post('/api/certnum', data, this.axiosConfig);
        this.isSendCertNumer = true;
        this.startTimer();

        setTimeout(() => {
          this.$refs.certNumber.focus();
        }, 100);
      } catch (error) {
        this.errorHandler(error);
      }
    },

    // 인증문자 검증
    methodCheckCertNumber: function () {
      let self = this;
      let config = {
        method: 'get',
        url: '/api/certnum',
        params: {
          mobile: self.mobile,
          num: self.certNumber,
          pushToken: self.getPushToken()
        }
      };
      axios(config)
        .then(function (response) {
          localStorage.authToken = response.data.token;
          self.$router.push('/branch');
        })
        .catch(function (error) {
          self.errorHandler(error);
        });
    },

    // 핸드폰 번호 숫자만 입력
    checkMobileNumber: function (e) {
      // 숫자만 입력
      e.target.value = e
        .target
        .value
        .replace(/[^0-9]/g, '');
      this.mobile = e.target.value;
    },

    // 인증문자 숫자만 입력
    checkCertNumber: function (e) {
      // 숫자만 입력
      e.target.value = e
        .target
        .value
        .replace(/[^0-9]/g, '');
      this.certNumber = e.target.value;
    },

    // 타이머
    startTimer: function () {
      let count = 180;
      let self = this;
      let minutes,
        seconds;
      this.timer = setInterval(function () {
        minutes = parseInt(count / 60, 10);
        seconds = parseInt(count % 60, 10);
        minutes = minutes < 10 ?
          "0" + minutes :
          minutes;
        seconds = seconds < 10 ?
          "0" + seconds :
          seconds;
        self.expireTime = minutes + ":" + seconds;

        // 타이머 끝
        if (--count < 0) {
          clearInterval(self.timer);
          self.isSendCertNumer = false;
          self.isCheckCertNumber = false;
        }
        // console.log(new Date());
      }, 1000);

      sessionStorage.setItem('interval-timer', this.timer);
    }
  }
}
</script>

