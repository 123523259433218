<template>
  <li>
    <select v-model="addr.mb_9" @change="getDistricts()">
      <option value="">광역.시.도선택</option>
      <option v-for="item in cities" v-bind:key="item.addr1_cd" v-bind:value="item.addr1_cd">{{item.addr1_nm}}</option>
    </select>
    <select v-model="addr.mb_10" @change="onDataChanged">
      <option value="">시.군.구선택</option>
      <option v-for="item in districts" v-bind:key="item.addr2_cd" v-bind:value="item.addr2_cd">
        {{item.addr2_nm}}</option>
    </select>
  </li>
</template>

<script>
  import mixin from '../../mixin';
  import axios from 'axios';

  export default {
    mixins: [mixin],
    props: ['addrCode'],
    data: function () {
      return {
        addr: this.addrCode,
        cities: [],
        districts: [],
      }
    },
    created: async function () {
      this.cities = await axios.get('/api/cities', this.axiosConfig); // 광역시도
      this.cities = this.cities.data;

      if(this.addr.mb_9){
        await this.getDistricts();
      }
    },
    methods: {
      // 시군구
      getDistricts: async function () {
        this.axiosConfig.params.cityCode = this.addr.mb_9;
        this.districts = await axios.get('/api/districts', this.axiosConfig); // 시군구
        this.districts = this.districts.data;
        let self = this;
        let addr = await this.districts.filter(function(e){
          return e.addr2_cd == self.addr.mb_10;
        });
        if(addr.length == 0) this.addr.mb_10 = '';
        this.$emit('input', this.addr) // input 이벤트 발생
      },
      onDataChanged() {
        this.$emit('input', this.addr) // input 이벤트 발생
      }     
    }
  }
</script>

