<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`근무 환경 평가`"></top-back-header-dolligo>
    <div class="content-wrap">
      <div class="review-office-wrap" style="">
        <div class="office-box-top">
          <h2 v-if="data.cityName">{{ data.cityName }} {{ data.districtName }}</h2>
          <h2 v-if="data.officeName">{{ data.officeName }}</h2>
          <span class="rating">{{ data.reviews.score }}</span>
          <router-link :to="{ name: 'dolligoReviewRegist' }" class="icon write">평가하기</router-link>
        </div>
        <div class="review-body-wrap">
          <h1>평가 {{ data.reviews.total_count }}건</h1>
          <select v-model="data.job_group" @change="jobChange()">
            <option value="">직군선택</option>
            <option v-for="item in data.jobs" v-bind:key="item.job_group" v-bind:value="item.job_group">
              {{ item.g5_z_grade1.grd1_nm }}({{ item.count }})</option>
          </select>
          <router-link v-for="item in data.reviews.rows" v-bind:key="item.wr_id" @click.native="goDetail()"
            :to="{ name: 'dolligoReviewDetail', params: { id: item.wr_id } }" class="office-box">
            <span class="office-box-top">
              <span class="rating">{{ item.score }}</span>
              <span class="date">{{ item.txtDate }}</span>
            </span>
            <p>{{ item.wr_7 }}</p>
            <ul class="reviewer-info">
              <li>{{ item.po_type }}</li>
              <li>{{ item.jobName }}</li>
            </ul>
            <ul class="reviewer-info">
              <li>근무기간 {{ item.wr_1 }}년 ~ {{ item.wr_2 }}년</li>
            </ul>
          </router-link>
          <infinite-loading :identifier="data.infiniteId" @infinite="getReviewList" ref="infiniteLoading">
            <div slot="no-more"></div>
            <div slot="no-results">{{ data.reviews.rows.length == 0 ? '리뷰가 없습니다.' : '' }}</div>
          </infinite-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '../../../mixin';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin],
  data: function () {
    return {
      data: {
        cityName: '',
        districtName: '',
        officeName: '',
        reviews: {
          rows: []
        },
        jobs: {},
        job_group: '',
        infiniteId: +new Date(),
        axiosData: {page: 1},
        isHistoryBack: false
      }
    }
  },
  created: async function () {
    if (this.MEMBER.mb_level == '2') {
      this.$router.replace({ name: 'dolligoPremiumIntro' });
      return false;
    }

    let data = JSON.parse(sessionStorage.getItem('dolligoReviewSearch'));
    if (data && data.isHistoryBack == true) {
      this.data = data;
      sessionStorage.removeItem('dolligoReviewSearch');
    } else {
      this.data.axiosData = this.axiosConfig;
      this.data.axiosData.params = this.$route.query;
      if (this.$route.query.addr1_code) this.data.cityName = await this.getCityName(this.$route.query.addr1_code);
      if (this.$route.query.addr2_code) this.data.districtName = await this.getDistrictName(this.$route.query.addr2_code);
      if (this.$route.query.branch_code) this.data.officeName = await this.getOfficetName(this.$route.query.branch_code);
      await this.getJobGroups();
    }
  },
  methods: {
    getReviewList: async function ($state) {
      this.data.axiosData.params.page = this.data.axiosData.params.page ? this.data.axiosData.params.page : 1;
      if (this.data.axiosData.params.branch_code) {
        delete this.data.axiosData.params.addr1_code;
        delete this.data.axiosData.params.addr2_code;
      } else {
        delete this.data.axiosData.params.branch_code;
      }
      let reviews = await axios.get('/api/dolligo/review', this.data.axiosData);
      if (reviews.data.rows.length == 0) {
        $state.complete();
      } else {
        this.data.axiosData.params.page = this.data.axiosData.params.page + 1;
        this.data.reviews.score = reviews.data.score;
        this.data.reviews.total_count = reviews.data.total_count;
        this.data.reviews.rows.push(...reviews.data.rows);
        $state.loaded();
      }
    },
    jobChange: async function () {
      this.$refs.infiniteLoading.stateChanger.reset();
      this.data.reviews.rows = [];
      this.data.axiosData.params.page = 1;
      if (this.data.job_group)
        this.data.axiosData.params.job_group = this.data.job_group;
      else
        delete this.data.axiosData.params.job_group;
    },
    getJobGroups: async function () {
      try {
        let axiosConfig = {
          headers: this.data.axiosData.headers,
          params: {
            addr1_code: this.$route.query.addr1_code,
            addr2_code: this.$route.query.addr2_code,
            po_type: this.$route.query.po_type,
          }
        }
        if (this.$route.query.branch_code) axiosConfig.params.branch_code = this.$route.query.branch_code;
        this.data.jobs = await axios.get('/api/dolligo/review/job/groups', axiosConfig); // 직렬
        this.data.jobs = this.data.jobs.data;
      } catch (error) {
        this.errorHandler(error);
      }
    },
    goDetail: async function () {
      this.data.isHistoryBack = true;
      sessionStorage.setItem('dolligoReviewSearch', JSON.stringify(this.data));
    }
  }
}
</script>

