import BlindPushlistIndex from '@/components/blind/pushlist/Index';

const blindPushlist = [{
  path: '/blind/push-list',
  name: 'blindPushlist',
  component: BlindPushlistIndex,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthBlind: true
  }
}]

export default blindPushlist;