<template>
  <div class="dolligo-wrap">
    <header>
      <dolligo-top></dolligo-top>
      <h1>근무환경</h1>
    </header>
    <div class="content-wrap bg-white">
      <ul class="page-info-list">
        <li>공무원이 직접 작성한 행정처 근무 환경 평가!</li>
        <li>모든 행정처의 근무환경 평가는 상시 추가됩니다</li>
      </ul>
      <div class="write-wrap">
        <div class="review-body-wrap">
          <div class="best-wrap">
            <h2>지방직 BEST</h2>
            <ol>
              <li v-for="best in data.bests1" v-bind:key="best.wr_id">
                <router-link class="office-box"
                  :to="{ name: 'dolligoReviewSearch', query: { po_type: '지방직', addr1_code: best.addr1_code, addr2_code: best.addr2_code } }">
                  <h2>{{ best.addr1_name }} {{ best.addr2_name }}</h2><span class="rating">{{ best.avg }}</span>
                </router-link>
              </li>
            </ol>
          </div>
          <div class="best-wrap">
            <h2>국가직 BEST</h2>
            <ol>
              <li v-for="best in data.bests2" v-bind:key="best.wr_id">
                <router-link class="office-box"
                  :to="{ name: 'dolligoReviewSearch', query: { po_type: '국가직', branch_code: best.branch_code } }">
                  <h2>{{ best.branch_nm }}</h2><span class="rating">{{ best.avg }}</span>
                </router-link>
              </li>
            </ol>
          </div>
        </div>
        <div class="review-top-wrap">
          <input type="checkbox" id="review-search-btn" v-model="data.showSearchLayer" />
          <label for="review-search-btn" class="review-search-btn" @click="showLayer()">행정처 근무 환경 검색</label>
          <div class="review-search-wrap">
            <div class="search-top">
              <a href="javascript:void(0)" class="close-btn" @click="$router.go(-1)">닫기</a>
            </div>
            <dl class="btns-wrap">
              <dd>
                <label class="submit-btn" :class="{ on: data.filter.po_type == '지방직' }"><input type="radio" value="지방직"
                    v-model="data.filter.po_type" @click="data.filter.branch_code = '';" /> 지방직 </label>
                <label class="submit-btn" :class="{ on: data.filter.po_type == '국가직' }"><input type="radio" value="국가직"
                    v-model="data.filter.po_type" @click="data.filter.addr1_code = ''; data.filter.addr2_code = '';" /> 국가직
                </label>
              </dd>
            </dl>
            <dl>
              <dd v-if="data.filter.po_type == '국가직'">
                <select v-model="data.filter.branch_code">
                  <option value="">행정처 선택 주세요.</option>
                  <option v-for="item in data.offices" v-bind:key="item.grd3_cd" v-bind:value="item.grd3_cd">
                    {{ item.grd3_nm }}
                  </option>
                </select>
              </dd>
              <dd v-if="data.filter.po_type == '지방직'" @change="getDistricts()">
                <select v-model="data.filter.addr1_code">
                  <option value="">광역.시.도선택</option>
                  <option v-for="item in data.cities" v-bind:key="item.addr1_cd" v-bind:value="item.addr1_cd">
                    {{ item.addr1_nm }}</option>
                </select>
              </dd>
            </dl>
            <dl v-if="data.filter.po_type == '지방직' && data.filter.addr1_code">
              <dt>시군구 선택</dt>
              <dd class="">
                <label v-for="item in data.districts" v-bind:key="item.addr2_cd" class="addr-sub"
                  :class="{ on: data.filter.addr2_code == item.addr2_cd }">
                  <input type="radio" v-bind:value="item.addr2_cd" v-model="data.filter.addr2_code" /> {{ item.addr2_nm }}
                </label>
              </dd>
            </dl>
            <div class="search-btn-bar">
              <button class="submit-btn" v-bind:class="{ on: isSubmit() }" @click="search()">검색하기</button>
            </div>
          </div>
        </div>
        <router-link :to="{ name: 'dolligoReviewMe' }" class="submit-btn write-review">근무 환경 평가 작성하기</router-link>
      </div>
    </div>
    <dolligo-footer propType="dolligoReview"></dolligo-footer>
  </div>
</template>

<script>
import DolligoTop from '../../common/DolligoTop.vue';
import mixin from '../../../mixin';
import axios from 'axios';
import DolligoFooter from '../../common/DolligoFooter.vue';

export default {
  mixins: [mixin],
  data: function () {
    return {
      data: {
        bests1: [],
        bests2: [],
        showSearchLayer: false,
        filter: {
          po_type: '',
          branch_code: '',
          addr1_code: '',
          addr2_code: '',
        },
        offices: [],
        cities: [],
        districts: [],
        isHistoryBack: false
      }
    }
  },
  components: {
    DolligoTop,
    DolligoFooter,
  },
  created: async function () {
    let searchData = JSON.parse(sessionStorage.getItem('searchData'));
    if (searchData && searchData.isHistoryBack == true) {
      this.data = searchData
      sessionStorage.removeItem('searchData');
    } else {
      this.data.filter.po_type = this.me.mb_8;
      this.data.filter.branch_code = this.me.mb_17;
      this.data.filter.addr1_code = this.me.mb_9;
      this.data.filter.addr2_code = this.me.mb_10;

      this.data.bests1 = await axios.get('/api/dolligo/review/best/지방직', this.axiosConfig);
      this.data.bests1 = this.data.bests1.data;
      this.data.bests2 = await axios.get('/api/dolligo/review/best/국가직', this.axiosConfig);
      this.data.bests2 = this.data.bests2.data;

      // 행정처
      this.data.offices = await axios.get('/api/offices', this.axiosConfig);
      this.data.offices = this.data.offices.data;

      // 광역시도
      this.data.cities = await axios.get('/api/cities', this.axiosConfig);
      this.data.cities = this.data.cities.data;

      // 광역시도 선택되어 있다면
      if (this.data.filter.addr1_code) {
        this.getDistricts();
      }
    }
    let self = this;
    window.onpopstate = function () {
      self.data.showSearchLayer = false;
    };
  },
  methods: {
    getDistricts: async function () {
      this.axiosConfig.params.cityCode = this.data.filter.addr1_code;
      this.data.districts = await axios.get('/api/districts', this.axiosConfig); // 시군구
      this.data.districts = this.data.districts.data;
    },
    isSubmit: function () {
      if (this.data.filter.po_type == '국가직' && this.data.filter.branch_code) return true;
      if (this.data.filter.po_type == '지방직' && this.data.filter.addr1_code && this.data.filter.addr2_code) return true;
    },
    search: function () {
      if (this.isSubmit() != true) return false;

      if (this.MEMBER.mb_level == '2') {
        this.$router.push({name: 'dolligoPremiumIntro'});
        return false;
      }

      this.data.isHistoryBack = true;
      sessionStorage.setItem('searchData', JSON.stringify(this.data));
      setTimeout(() => {
        if(this.data.filter.po_type == '지방직') this.data.filter.branch_code = '';
        this.$router.push({
          name: 'dolligoReviewSearch',
          query: this.data.filter
        })
      }, 10);
    },
    showLayer: function () {
      history.pushState('', '', '#filter');
    }
  }
}
</script>

