<template>
  <nav>
    <ul class="">
      <li>
        <router-link :to="{name: 'blindBoardList', params :{boardId: 'free'}}"
          :class="{on: $route.name == 'blindBoardList'}">
          <span class="icon board"></span>
          <strong>게시판</strong>
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'blindPushlist'}" :class="{on: $route.name == 'blindPushlist', new: MEMBER.unreadBlindPushCount > 0}">
          <span class="icon bell"></span>
          <strong>알림</strong>
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'blindSetting'}" :class="{on: $route.name == 'blindSetting'}">
          <span class="icon profile"></span>
          <strong>프로필</strong>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import mixin from '@/mixin';

export default {
  mixins: [mixin],
  created: function() {
    // console.log(this.MEMBER);
  }
}
</script>

