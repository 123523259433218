import DolligoReviewIndex from '@/components/dolligo/review/Index';
import DolligoReviewSearch from '@/components/dolligo/review/Search';
import DolligoReviewDetail from '@/components/dolligo/review/Detail';
import DolligoReviewMe from '@/components/dolligo/review/Me';
import DolligoReviewRegist from '@/components/dolligo/review/Regist';

const dolligoReview = [{
  path: '/dolligo/review',
  name: 'dolligoReview',
  component: DolligoReviewIndex,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
},{
  path: '/dolligo/review/search',
  name: 'dolligoReviewSearch',
  component: DolligoReviewSearch,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
},{
  path: '/dolligo/review/me',
  name: 'dolligoReviewMe',
  component: DolligoReviewMe,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
},{
  path: '/dolligo/review/regist',
  name: 'dolligoReviewRegist',
  component: DolligoReviewRegist,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
},{
  path: '/dolligo/review/:id',
  name: 'dolligoReviewDetail',
  component: DolligoReviewDetail,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
},{
  path: '/dolligo/review/regist/:id',
  name: 'dolligoReviewModify',
  component: DolligoReviewRegist,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}]

export default dolligoReview