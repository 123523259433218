<template>
  <div id="app" class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`프리미엄 멤버십 가입`"></top-back-header-dolligo>
    <div class="content-wrap">
      <div class="product-head">
        <h2>프리미엄 멤버십 {{ priceInfo[payType].payText }}</h2>
        <span class="discount">{{ priceInfo[payType].discountPercent }}%</span>
        <span class="og-price">{{ priceInfo[payType].price }}원</span>
        <span class="price-wrap">
          <span class="final-price">월 {{ priceInfo[payType].discountPrice }}원</span>
        </span>
      </div>
      <div class="premium-bottom">
        <div class="check-wrap">
          <label for="terms1" class="form-check-label">
            <input type="checkbox" id="terms1" class="form-check-input" v-model="datas.isAgree" />서비스 이용약관 동의 (필수)
            <span class="form-check-sign"><span class="check"></span></span>
          </label>
          <router-link :to="{ name: 'dolligoPremiumTerm' }" class="term-btn" @click.native="saveSession()">약관보기
          </router-link>
          <div class="helper-txt error">동의는 필수입니다.</div>
        </div>
        <button class="submit-btn p-on" @click="payStart()">결제하기</button>
      </div>

      <div class="noti-box-wrap">
        <h3>문의사항 및 해지/환불</h3>
        <ul>
          <li>문의사항은 <router-link :to="{ name: 'dolligoCommunity', params: { boardId: 'qa' } }">Q&amp;A
              게시판</router-link>에
            남겨주세요</li>
          <li>정기결제 이용 시 해지가 가능하며 만료일 이전에 해지하셔도 남은 일수만큼 환불되지 않습니다.(기존 이용권 만료일까지 프리미엄 멤버십 혜택 유지)</li>
          <li>월결제(3, 6개월) 이용시에는 환불이 불가능합니다.</li>
          <li>구글 정기구독(결제) 취소 : <strong>플레이스토어 &gt; 정기결제 &gt; 인사교류 &gt; 구독취소</strong></li>
          <li>애플 정기구독(결제) 취소 : <strong>App Store &gt; 프로필 이미지 클릭(계정) &gt; 구독 클릭 &gt; 구독 중인 항목 클릭 &gt; 하단 구독취소
              클릭</strong></li>
        </ul>
      </div>
    </div>
    <spinner ref="spinner"></spinner>
  </div>
</template>
<script>
import axios from 'axios';
import mixin from '../../../mixin';
import Spinner from '../../common/Spinner.vue';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';
export default {
  components: { Spinner, TopBackHeaderDolligo },
  mixins: [mixin],
  data: function () {
    return {
      payType: this.$route.query.payType,
      payMethod: '',
      purchaseHistoryMethod: '',
      swingPlugin: null,
      prodId: '',
      priceInfo: {
        12: { discountPercent: '29', price: '8,500', discountPrice: '6,000', iosProdId: 'premium_sub', andProdId: 'premium_subscribe', payText: '정기 결제' },
        14: { discountPercent: '41', price: '25,500', discountPrice: '15,000', iosProdId: 'premium3mth', andProdId: 'premium3month', payText: '3개월 결제' },
        15: { discountPercent: '47', price: '51,000', discountPrice: '27,000', iosProdId: 'premium6mth', andProdId: 'premium6month', payText: '6개월 결제' }
      },
      datas: {
        isAgree: false,
      }
    }
  },
  created: async function () {
    try {
      await this.getSession();
      this.swingPlugin = window.swingWebViewPlugin.app.inapp;
      this.payMethod = this.payType == '12' || this.isIphone == true ? 'subscribe' : 'buy';
      this.prodId = this.isIphone == true ? this.priceInfo[this.payType].iosProdId : this.priceInfo[this.payType].andProdId;
      this.purchaseHistoryMethod = this.isIphone == true ? 'getReceipt' : 'restorePurchases';
      this.purchaseHistoryCheck(); // 구매 이력 체크
    } catch (error) {
      this.errorHandler(error);
    }
  },
  methods: {
    payStart: function () {
      if (this.datas.isAgree == false) {
        alert('서비스 이용약관 동의해 주세요.');
        return;
      }
      let axiosConfig = this.axiosConfig;
      axiosConfig.params = { payText: `${this.priceInfo[this.payType].payText} 클릭-v3` }
      axios.get(`/api/dolligo/pay-inapp-log/slack`, axiosConfig);

      let self = this;
      this.$refs.spinner.show = true;
      // alert('제품ID : ' + self.prodId);
      // alert('구독타입 : ' + self.payMethod);
      this.swingPlugin[self.payMethod](self.prodId, function (responseCode, data) {
        try {
          // alert('responseCode : ' + responseCode);
          self.addInappLog({ '결제결과': { prodId: self.prodId, isResult: responseCode, r: data, receipt: data } }); // 결제 로그
          if ((self.isIphone != true && responseCode == 0) || (self.isIphone == true && responseCode == 1)) // 성공
          {
            var result = JSON.parse(data);
            if (self.isIphone != true) {
              for (let index = 0; index < result.length; index++) {
                let purchaseToken = result[index].purchaseToken;
                // alert(purchaseToken);
                self.addInappLog({ '결제성공': { prodId: self.prodId, consumeResult: data } }); // 결제 로그
                self.payCompleted(self.prodId, purchaseToken) // 결제 완료
                break;
              }
            } else {
              let purchaseToken = result.receipt;
              // alert('data: ' + JSON.stringify(result));
              // alert(purchaseToken);
              self.addInappLog({ '결제성공': { prodId: self.prodId, consumeResult: data } }); // 결제 로그
              self.payCompleted(self.prodId, purchaseToken) // 결제 완료
            }
          }
          else if ((self.isIphone != true && responseCode == 1) || (self.isIphone == true && responseCode == 2))    // 취소
          {
            axiosConfig.params = { payText: `${self.priceInfo[self.payType].payText} 입질 취소 v3` }
            axios.get(`/api/dolligo/pay-inapp-log/slack`, axiosConfig);
            self.addInappLog({ '결제취소': { prodId: self.prodId, isResult: responseCode, r: data, receipt: data } }); // 결제 로그
            self.$refs.spinner.show = false;
            self.$router.push({ name: 'dolligoPremiumPayFail' })
          }
          else    // 기타 에러
          {
            axiosConfig.params = { payText: `${self.priceInfo[self.payType].payText} 입질 실패 ${responseCode} v3` }
            axios.get(`/api/dolligo/pay-inapp-log/slack`, axiosConfig);
            self.addInappLog({ '결제실패': { prodId: self.prodId, isResult: responseCode, r: data, receipt: data } }); // 결제 로그
            self.$refs.spinner.show = false;
            self.$router.push({ name: 'dolligoPremiumPayFail' })
          }
        } catch (error) {
          axiosConfig.params = { payText: `${JSON.stringify(error)} try 에러-v3` }
          axios.get(`/api/dolligo/pay-inapp-log/slack`, axiosConfig);
        }
      });
    },

    // 결제 로그 저장
    addInappLog: async function (response) {
      response = JSON.stringify(response);
      let data = { response: response };
      axios.post(`/api/dolligo/pay-inapp-log`, data, this.axiosConfig);
    },

    // 구매이력 체크
    purchaseHistoryCheck: function () {
      if (this.MEMBER.mb_level == '5') return;
      let self = this;
      if (self.isIphone != true) {
        self.swingPlugin[self.purchaseHistoryMethod]('subs', function (responseCode, data) {
          self.addInappLog({ '구매이력': { prodId: self.prodId, isResult: responseCode, r: data, receipt: data } }); // 결제 로그
          if (responseCode == 0) // 성공
          {
            var result = JSON.parse(data);
            for (var index = 0; index < result.length; index++) {
              var purchaseToken = result[index].purchaseToken;
              self.addInappLog({ '결제성공': { prodId: self.prodId, consumeResult: data } }); // 결제 로그
              self.payCompleted(self.prodId, purchaseToken) // 결제 완료
            }
          }
          else if (responseCode == 1)    // 취소
          {
            self.addInappLog({ '결제취소': { prodId: self.prodId, isResult: responseCode, r: data, receipt: data } }); // 결제 로그
          }
          else    // 기타 에러
          {
            self.addInappLog({ '결제실패': { prodId: self.prodId, isResult: responseCode, r: data, receipt: data } }); // 결제 로그
          }
        });
      } else {
        try {
          window.swingWebViewPlugin.app.inapp.getReceipt(function (value) {
            self.addInappLog({ '아이폰구매이력토큰': { prodId: self.prodId, consumeResult: value}}); // 결제 로그
            var purchaseToken = value;
            if (purchaseToken) {
              self.addInappLog({ '결제성공': { prodId: self.prodId, consumeResult: JSON.stringify(value) } }); // 결제 로그
              self.payCompleted(self.prodId, purchaseToken) // 결제 완료          
            }
          });
        } catch (error) {
          self.addInappLog({ '아이폰구매이력토큰 error': error }); // 결제 실패 로그
          self.errorHandler(error);
        }
      }

    },

    // 결제 완료
    payCompleted: async function (productId, receipt) {
      try {
        let path = this.isIphone == true ? 'apple' : 'google';
        let data = { productId: productId, receipt: receipt, mb_id: this.MEMBER.mb_id }
        let result = await axios.post(`/api/dolligo/payments-info/${path}`, data, this.axiosConfig);
        this.$refs.spinner.show = false;

        if (result.data.member && result.data.member.mb_level == '5') {
          let axiosConfig = this.axiosConfig;
          axiosConfig.params = { payText: `${this.priceInfo[this.payType].payText} 결제 완료 v3` }
          axios.get(`/api/dolligo/pay-inapp-log/slack`, axiosConfig);
          this.$router.push({ name: 'dolligoPremiumPayDone' })
        }
        return true;
      } catch (error) {
        this.addInappLog({ 'payCompleted 결제실패': error }); // 결제 실패 로그
        return false;
      }

    }
  }
}
</script>  

