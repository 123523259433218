<template>
  <div class="blind-wrap">
    <top-back-header-blind :propIsBack="$route.query.is_me == 'y' ? true : false"
      ref="child_header"></top-back-header-blind>
    <div class="tab-menu" v-if="$route.query.is_me != 'y'">
      <ul class="">
        <li>
          <router-link :to="{ name: 'blindBoardList', params: { boardId: 'free' } }"
            :class="{ on: $route.params.boardId == 'free', new: freeNewCount > 0 }">자유 게시판</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'blindBoardList', params: { boardId: 'issue' } }"
            :class="{ on: $route.params.boardId == 'issue', new: issueNewCount > 0 }">공무원 이슈</router-link>
        </li>
      </ul>
    </div>
    <div class="tab-menu" v-if="$route.query.is_me == 'y'">
      <ul class="">
        <li>
          <router-link :to="{ name: 'blindBoardList', params: { boardId: 'free' }, query: { is_me: 'y' } }"
            :class="{ on: $route.query.is_me == 'y' && $route.query.wr_is_comment != '1' }">게시글</router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'blindBoardList', params: { boardId: 'free' }, query: { is_me: 'y', wr_is_comment: '1' } }"
            :class="{ on: $route.query.is_me == 'y' && $route.query.wr_is_comment == '1' }">댓글</router-link>
        </li>
      </ul>
    </div>
    <section class="bg-grey">
      <div class="search-wrap">
        <div class="input-block">
          <input type="search" placeholder="검색어를 입력해주세요." v-model="datas.keyword" maxlength="20"
            v-on:keyup.enter="search" />
        </div>
        <select class="sorting" v-model="datas.order" @change="search">
          <option value="new">최신 순</option>
          <option value="best">인기 순</option>
          <option value="myjob" v-if="$route.params.boardId == 'free' && $route.query.is_me != 'y'">나의직렬</option>
        </select>
      </div>
      <ul class="box-list">
        <li v-for="board in datas.boards" v-bind:key="board.wr_id">
          <router-link :class="{ notice: board.isNotice == '1', new: board.isNew > 0 }"
            :to="{ name: 'blindBoardView', params: { wr_id: board.wr_id, boardId: datas.boardId } }"
            @click.native="saveSession()">
            <span class="date" v-if="$route.query.wr_is_comment != '1' && board.isNotice != '1'">{{ board.txtDate }}</span>
            <h3 class="tit-3">{{ $route.query.wr_is_comment == '1' ? board.wr_subject_parent : board.wr_subject }}</h3>
            <p style="white-space: white-space;" v-if="board.isNotice != '1'">{{ $route.query.wr_is_comment == '1' ? `┖   ${board.wr_content}` : board.wr_content}}</p>
            <ul class="data-list" v-if="board.isNotice != '1'">
              <li v-if="board.wr_good > 0 && $route.query.wr_is_comment != '1'">👍 좋아요 {{ board.wr_good }}</li>
              <li v-if="board.wr_comment > 0 && $route.query.wr_is_comment != '1'">✍️ 댓글 {{ board.wr_comment }}</li>
              <li v-if="$route.query.wr_is_comment == '1'">{{ board.txtDate }}</li>
            </ul>
          </router-link>
        </li>
        <infinite-loading :identifier="datas.infiniteId" @infinite="getBoards" ref="infiniteLoading">
          <div slot="no-more"></div>
          <div slot="no-results">{{datas.boards.length == 0 ? '글이 없습니다.' : ''}}</div>
        </infinite-loading>
      </ul>
      <router-link :to="{ name: 'blindBoardEdit', params: {boardId: $route.params.boardId} }" class="icon big-write-btn">글쓰기</router-link>
    </section>
    <blind-footer v-if="$route.query.is_me != 'y'"></blind-footer>
  </div>
</template>

<script>
import mixin from '@/mixin';
import axios from 'axios';
import BlindFooter from '../../common/BlindFooter.vue';
import TopBackHeaderBlind from '../../common/TopBackHeaderBlind.vue';
import jsonQuery from 'json-query';

export default {
  components: { BlindFooter, TopBackHeaderBlind },
  mixins: [mixin],
  data: function () {
    return {
      datas: {
        keyword: '',
        order: 'new',
        boards: [],
        page: 1,
        is_me: this.$route.query.is_me ? this.$route.query.is_me : 'n',
        wr_is_comment: this.$route.query.wr_is_comment ? this.$route.query.wr_is_comment : '',
        infiniteId: +new Date(),
        boardId: this.$route.params.boardId,
        b_mb_grd1_cd: ''
      },
      freeNewCount: 0,
      issueNewCount: 0,
    }
  },
  created: async function () {
    this.getSession();
    window.addEventListener('scroll', this.handleScroll);
    let data = await axios.get('/api/dolligo/community/boards/new-count', this.axiosConfig);
    this.freeNewCount = data.data.freeCount;
    this.issueNewCount = data.data.issueCount;

    sessionStorage.removeItem(this.$route.name);
  },
  methods: {
    search: async function () {
      setTimeout(() => {
        this.datas.boardId = this.$route.params.boardId;
        this.$refs.infiniteLoading.stateChanger.reset();
        this.datas.boards = [];
        this.datas.page = 1;
        this.datas.b_mb_grd1_cd = this.datas.order == 'myjob' ? this.MEMBER.b_mb_grd1_cd : '';
      }, 100);
    },
    getBoards: async function ($state) {
      try {
        let axiosConfig = this.axiosConfig;
        axiosConfig.params.wr_is_comment = '0';
        axiosConfig.params.keyword = this.datas.keyword;
        axiosConfig.params.page = this.datas.page;
        axiosConfig.params.is_me = this.datas.is_me;
        axiosConfig.params.wr_is_comment = this.datas.wr_is_comment;
        axiosConfig.params.order = this.datas.order;
        axiosConfig.params.b_mb_grd1_cd = this.datas.b_mb_grd1_cd;
        let boards = await axios.get(`/api/dolligo/community/${this.datas.boardId}`, axiosConfig);
        boards = boards.data;

        if (boards.length == 0) {
          $state.complete();
        } else {
          this.datas.page++;
          let self = this;
          boards.forEach((element) => {
            let result = jsonQuery(`[wr_id=${element.wr_id}]`, {
              data: self.datas.boards
            }).value;
            if (!result) this.datas.boards.push(element);
          });          
          $state.loaded();
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
  }
}
</script>