import DolligoReportEdit from '@/components/dolligo/report/Edit';

const dolligoReport = [{
  path: '/dolligo/report',
  name: 'dolligoReportEdit',
  component: DolligoReportEdit,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}]

export default dolligoReport;