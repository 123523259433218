<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`프리미엄 정보`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <ul class="page-info-list">
        <li>멤버십 해지는 정기결제 이용권 보유 시에만 가능합니다.</li>
        <li>멤버십에 관한 문의 사항은 <router-link :to="{ name: 'dolligoCommunity', params: { boardId: 'qa' } }">Q&amp;A
          </router-link>
          게시판에 남겨주세요</li>
      </ul>
      <div v-if="MEMBER.mb_level == '9'" class="membership-info"><dl><dd>관리자 입니다.</dd></dl></div>
      <div class="membership-info" v-if="MEMBER.payments_infos && MEMBER.payments_infos.length > 0">
        <dl>
          <dt>이용 기간</dt>
          <dd>{{ MEMBER.payments_infos[0].start_date }} ~ {{ MEMBER.payments_infos[0].expire_date }}</dd>
        </dl>
        <dl>
          <dt>결제 상태</dt>
          <dd>{{ info[MEMBER.payments_infos[0].pay_type] }}</dd>
        </dl>
        <dl>
          <dt>이용 금액</dt>
          <dd>{{ MEMBER.payments_infos[0].amount.toLocaleString() }}원</dd>
        </dl>
        <a v-if="MEMBER.mb_level > 4" href="javascript:void(0)" class="submit-btn">{{ info[MEMBER.payments_infos[0].pay_type] }}</a>
        <router-link v-if="MEMBER.mb_level < 5" :to="{ name: 'dolligoPremiumReady' }" class="submit-btn p-on">프리미엄
          멤버십 가입하기</router-link>
      </div>
      <div class="noti-box-wrap">
        <h3>문의사항 및 해지/환불</h3>
        <ul>
          <li>문의 사항은 <router-link :to="{ name: 'dolligoCommunity', params: { boardId: 'qa' } }">Q&amp;A</router-link>
            게시판에 남겨주세요</li>
          <li>문의 이메일 : <a href="mailto:dolligo3@gmail.com">dolligo3@gmail.com</a></li>
          <li>문의 연락처 : <a href="tel:070-7627-2020">070-7627-2020</a></li>
          <li>정기결제 이용 시 해지가 가능하며 만료일 이전에 해지하셔도 남은 일수 만큼 환불되지 않습니다.(기존 이용권 만료일까지 프리미엄 멤버십 혜택 유지)</li>
          <li>월결제(3, 6개월) 이용시에는 환불이 불가능합니다.</li>
          <li>구글 정기구독(결제) 취소 : <strong>플레이스토어 &gt; 정기결제 &gt; 인사교류 &gt; 구독취소</strong></li>
          <li>애플 정기구독(결제) 취소 : <strong>App Store &gt; 프로필 이미지 클릭(계정) &gt; 구독 클릭 &gt; 구독 중인 항목 클릭 &gt; 하단 구독취소
              클릭</strong></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../../../mixin';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';
export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin],
  created: function () {
    // console.log(this.MEMBER);
  },
  data: function () {
    return {
      info: {
        11: '프리미엄 멤버십 이벤트 참여중',
        12: '프리미엄 멤버십 정기결제',
        13: '프리미엄 멤버십 정기결제 해지함',
        14: '프리미엄 멤버십 3개월',
        15: '프리미엄 멤버십 6개월',
      }
    }
  },
}
</script>

