import DolligoMemberMark from '@/components/dolligo/member/Mark';
import DolligoMemberWithout from '@/components/dolligo/member/Without';
import DolligoMemberOut from '@/components/dolligo/member/Out';
import DolligoMemberOutDone from '@/components/dolligo/member/OutDone';
import DolligoMemberRegist from '@/components/dolligo/member/Regist';
import DolligoMemberWishArea from '@/components/dolligo/member/WishArea';

const dolligoMember = [{
  path: '/dolligo/member/bookmark',
  name: 'dolligoMemberMark',
  component: DolligoMemberMark,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
},{
  path: '/dolligo/member/without',
  name: 'dolligoMemberWithout',
  component: DolligoMemberWithout,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
},{
  path: '/dolligo/member/out',
  name: 'dolligoMemberOut',
  component: DolligoMemberOut,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
},{
  path: '/dolligo/member/out-done',
  name: 'dolligoMemberOutDone',
  component: DolligoMemberOutDone,
  meta: {
    checkAgent: true,
    // checkAuth: true,
    // checkAuthDolligo: true
  }
}, {
  path: '/dolligo/member/regist',
  name: 'dolligoMemberRegist',
  component: DolligoMemberRegist,
  meta: {
    checkAgent: true,
    checkAuth: true
  }
}, {
  path: '/dolligo/member/wisharea',
  name: 'dolligoMemberWishArea',
  component: DolligoMemberWishArea,
  meta: {
    checkAgent: true,
    checkAuth: true,
    // checkAuthDolligo: true
  }
}]

export default dolligoMember