import blindIndex from '@/components/blind/Index';

const blind = [{
  path: '/blind',
  name: 'blindIndex',
  component: blindIndex,
  meta: {
    checkAgent: true,
    checkAuth: true,
  }
}];
export default blind;