<template>
  <div class="blind-wrap">
    <top-back-header-blind :propIsBack="true"
      :propTitle="MEMBER.b_mb_nick ? `공블리 회원정보수정` : '공블리 회원가입'"></top-back-header-blind>
    <div class="form-wrap" style="">
      <div class="container">
        <h2 class="tit-2" v-if="!MEMBER.b_mb_nick">회원가입을 위해<br>아래 정보를 입력해주세요</h2>
        <ul>
          <li>
            <div class="input-wrap">
              <input type="text" placeholder="닉네임을 입력해주세요." v-model="datas.b_mb_nick" v-on:change="checkNickName"
                v-on:keyup="checkNickName" @input="checkNickName" maxlength="12" />
              <div v-bind:class="{ 'helper-txt': true, error: errorNickNameText }">{{ errorNickNameText }}</div>
            </div>
          </li>
          <li>
            <div class="input-wrap">
              <select v-model="datas.b_mb_grd1_cd">
                <option value="">직군선택</option>
                <option v-for="item in jobs" v-bind:key="item.grd1_cd" v-bind:value="item.grd1_cd">{{ item.grd1_nm }}
                </option>
              </select>
            </div>
          </li>
          <addr-blind :addrCode="datas.addr" ref="addrBlind" @send-addr="sendAddr"></addr-blind>
        </ul>
        <p class="notice">지역 정보는 노출되지 않습니다.</p>
        <div class="check-wrap">
          <div class="check-block" v-if="MEMBER.b_isTerm1 != 'y'">
            <label for="term1" class="form-check-label">
              <input type="checkbox" id="term1" v-model="datas.b_isTerm1" true-value="y" false-value="n"
                class="form-check-input">이용약관(필수)
              <span class="form-check-sign">
                <span class="check"></span>
              </span>
            </label>
            <router-link :to="{ name: 'blindMemberTerm1' }" class="term-btn" @click.native="saveSession()">약관보기
            </router-link>
            <p v-if="datas.b_isTerm1 != 'y'" class="helper-txt error">동의는 필수입니다.</p>
          </div>
          <div class="check-block" v-if="MEMBER.b_isTerm2 != 'y'">
            <label for="term2" class="form-check-label">
              <input type="checkbox" id="term2" v-model="datas.b_isTerm2" true-value="y" false-value="n"
                class="form-check-input">개인정보 수집 및 이용(필수)
              <span class="form-check-sign">
                <span class="check"></span>
              </span>
            </label>
            <router-link :to="{ name: 'blindMemberTerm2' }" class="term-btn" @click.native="saveSession()">약관보기
            </router-link>
            <p v-if="datas.b_isTerm2 != 'y'" class="helper-txt error">동의는 필수입니다.</p>
          </div>
          <div class="check-block">
            <label for="term3" class="form-check-label">
              <input type="checkbox" id="term3" v-model="datas.b_isTerm3" true-value="y" false-value="n"
                class="form-check-input">마케팅 목적 개인정보 이용동의(선택)
              <span class="form-check-sign">
                <span class="check"></span>
              </span>
            </label>
            <router-link :to="{ name: 'blindMemberTerm3' }" class="term-btn" @click.native="saveSession()">약관보기
            </router-link>
          </div>
          <button class="submit-btn" :class="{ on: isSubmit() }" @click="save()">저장</button>
        </div>
        <a href="javascript:void(0);" v-if="MEMBER.b_mb_nick" class="dropout-btn" @click="memberOut()">회원탈퇴</a>
      </div>
    </div>
  </div>
</template>


<script>
import AddrBlind from '@/components/common/AddrBlind.vue';
import axios from 'axios';
import mixin from '@/mixin';
import moment from 'moment';
import TopBackHeaderBlind from '../../common/TopBackHeaderBlind.vue';

export default {
  mixins: [mixin],
  components: {
    AddrBlind,
    TopBackHeaderBlind
  },
  data: function () {
    return {
      datas: {
        b_mb_nick: '',
        b_mb_grd1_cd: '',
        addr: {
          b_mb_city: '',
          b_mb_district: ''
        },
        b_isTerm1: '',
        b_isTerm2: '',
        b_isTerm3: '',
      },
      jobs: [],
      errorNickNameText: ''
    }
  },
  created: async function () {
    try {
      if (this.MEMBER.b_mb_pass != '1') this.$router.replace({ name: 'blindMemberQuiz' });

      // 직렬
      this.jobs = await axios.get('/api/jobs', this.axiosConfig);
      this.jobs = this.jobs.data;

      let isSession = await this.getSession();
      if (isSession == false) {
        this.datas.b_mb_nick = this.MEMBER.b_mb_nick;
        this.datas.b_mb_grd1_cd = this.MEMBER.b_mb_grd1_cd;
        this.datas.b_isTerm1 = this.MEMBER.b_isTerm1;
        this.datas.b_isTerm2 = this.MEMBER.b_isTerm2;
        this.datas.b_isTerm3 = this.MEMBER.b_isTerm3;
        this.datas.addr.b_mb_city = this.MEMBER.b_mb_city;
        this.datas.addr.b_mb_district = this.MEMBER.b_mb_district;
      } else {
        this.$refs.addrBlind.addr.b_mb_city = this.datas.addr.b_mb_city;
        this.$refs.addrBlind.addr.b_mb_district = this.datas.addr.b_mb_district;
      }
    } catch (error) {
      this.errorHandler(error);
    }
  },
  methods: {
    isSubmit: function () {
      if (this.errorNickNameText != "") return false;
      if (!this.datas.b_mb_grd1_cd) return false;
      if (!this.datas.addr.b_mb_city) return false;
      if (!this.datas.addr.b_mb_district) return false;
      if (this.datas.b_isTerm1 == 'n') return false;
      if (this.datas.b_isTerm2 == 'n') return false;

      return true;
    },
    // 닉네임 제한 입력
    checkNickName: async function (e) {
      // 한글,숫자,영문 만 입력
      e.target.value = e.target.value.replace(/[^(가-힣ㄱ-ㅎㅏ-ㅣㆍᆢa-zA-Z0-9\-)]/gi,'');
      this.datas.b_mb_nick = e.target.value;

      // 글자수
      if (this.datas.b_mb_nick.length == 1) {
        this.errorNickNameText = "닉네임은 2~12자로 입력해 주세요.";
        return;
      }

      // 닉네임 중복 체크
      let self = this;
      if (self.datas.b_mb_nick.length > 1) {
        let config = {
          method: 'get',
          url: '/api/member/nickname/dup',
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          },
          params: {
            nickName: self.datas.b_mb_nick,
            nickType: 'blind'
          }
        };
        let response = await axios(config);
        if (response.data.dupNickNameCount > 0) this.errorNickNameText = "사용할 수 없는 닉네임 입니다.";
        else this.errorNickNameText = "";
      } else {
        this.errorNickNameText = "";
      }
    },
    save: async function () {
      try {
        if (this.isSubmit() == false) return false;
        let body = {
          b_mb_nick: this.datas.b_mb_nick,
          b_mb_grd1_cd: this.datas.b_mb_grd1_cd,
          b_mb_city: this.datas.addr.b_mb_city,
          b_mb_district: this.datas.addr.b_mb_district,
          b_isTerm1: this.datas.b_isTerm1,
          b_isTerm2: this.datas.b_isTerm2,
          b_isTerm3: this.datas.b_isTerm3,
          mb_type: this.MEMBER.mb_type == '1' ? '3' : '2'
        };
        if (!this.MEMBER.b_mb_nick) {
          body.b_term1 = moment().format('YYYY-MM-DD HH:mm:ss');
          body.b_term2 = moment().format('YYYY-MM-DD HH:mm:ss');
          body.b_register_datetime = moment().format('YYYY-MM-DD HH:mm:ss');
        } else {
          body.b_update_datetime = moment().format('YYYY-MM-DD HH:mm:ss');
        }
        await axios.patch('/api/member', body, this.axiosConfig);

        if (this.MEMBER.b_mb_nick) 
          this.$router.replace({ name: 'blindSetting'})
        else
          this.$router.push({ name: 'blindBoardList', params: { boardId: 'free' } })
      } catch (error) {
        this.errorHandler(error);
      }
    },
    sendAddr: function (addr) {
      this.datas.addr = addr;
    },
    memberOut: async function () {
      try {
        if (confirm('정말 탈퇴 하겠습니까?')) {
          await axios.post('/api/member/out', { outType: "blind" }, this.axiosConfig);
          this.$router.push({ name: 'blindIndex' })
        }
      } catch (error) {
        this.errorHandler(error);
      }
    }
  }
}
</script>
    
