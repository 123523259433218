<template>
  <div class="dolligo-wrap">
    <header>
      <h1>프리미엄 멤버십</h1>
    </header>
    <div class="content-wrap bg-white">
      <div class="center-wrap">
        <p class="center-txt"><strong>결제에 실패 했습니다.</strong></p>
        <router-link :to="{name: 'dolligoPremiumReady'}" class="submit-btn on">다시 결제하기</router-link>
        <router-link :to="{name: 'dolligoMain'}" class="submit-btn">홈으로</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../../../mixin';
export default {
  mixins: [mixin],
}
</script>

