import HomePage from '@/components/HomePage'
import Terms1 from '@/components/Terms1'
import Terms2 from '@/components/Terms2'
import Terms3 from '@/components/Terms3'
import Branch from '@/components/Branch'
import Certnum from '@/components/Certnum'

const dolligo = [{
  path: '/',
  name: 'index',
  component: HomePage
},
{
  path: '/login',
  redirect: '/branch'
},    
{
  path: '/terms1',
  name: 'terms1',
  component: Terms1
}, {
  path: '/terms2',
  name: 'terms2',
  component: Terms2
}, {
  path: '/terms3',
  name: 'terms3',
  component: Terms3
}, {
  path: '/branch',
  name: 'branch',
  component: Branch,
  meta: {
    checkAgent: true,
    // checkAuth: true,
    // checkAuthDolligo: true
  }
}, {
  path: '/certnum',
  name: 'certnum',
  component: Certnum,
  meta: {
    checkAgent: true
  }
}];

export default dolligo