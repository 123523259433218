<template>
  <div id="app" class="dolligo-wrap">
    <header class="fixed">
      <!-- <router-link :to="{ name: 'dolligoMemo' }" class="icon back-btn"></router-link> -->
      <a class="icon back-btn" @click="goBack1()"></a>
      <router-link :to="{ name: 'dolligoMemberProfile', params: { id: datas.people.mb_id } }" class="message-user">
        <h1>{{ datas.people.mb_nick }}</h1>
        <span class="infor-row">
          <span class="value">{{ datas.people.jobName }}</span>
          <span class="value">{{ datas.people.rankName }}</span>
          <span class="value">{{ datas.people.mb_4 }}</span>
          <span class="value" v-if="datas.people.sex">{{ datas.people.sex }}</span>
        </span>
      </router-link>
      <div class="right-btns">
        <span class="percent" v-if="MEMBER.mb_level > 4">
          <strong>
            {{ MEMBER.mb_level > 4 ? datas.people.replyPercent : '??%' }}
          </strong>
          <span>쪽지 응답률</span>
        </span>
        <a href="javascript:void(0);" class="icon out-btn" @click="memoOut()">나가기</a>
      </div>
    </header>

    <div class="content-wrap bg-white">
      <div class="massage-view-wrap">
        <div class="notice-bar" v-if="datas.people.mb_6 == 'F'">
          현재 인사교류를 원하지 않는 회원입니다.
        </div>
        <div class="notice-bar gray">
          6개월 이전 내용은 자동 삭제됩니다.
        </div>
        <ul ref="messages">
          <infinite-loading direction="top" :identifier="datas.infiniteId" @infinite="getMessages" ref="infiniteLoading">
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
          <li v-for="message in datas.messages" v-bind:key="message.me_id"
            :class="{ me: message.me_send_mb_id == MEMBER.mb_id }">
            <span v-if="message.me_send_mb_id != MEMBER.mb_id" :class="`icon face${message.mb_36}`">프로필</span>
            <span v-if="message.me_send_mb_id != MEMBER.mb_id" class="name">{{ message.nickName }}</span>
            <div class="bubble-wrap">
              <p class="bubble" style="white-space: pre-line">
                {{ message.me_memo
                }}<span
                  v-if="message.me_send_mb_id == MEMBER.mb_id && (!message.me_read_datetime || message.me_read_datetime.substr(0, 4) == '0000')"
                  class="count">1</span>
              </p>
              <div class="info-box">
                <span>{{ message.regist_date_txt }}</span>
              </div>
            </div>
          </li>
        </ul>
        <div class="write-bar">
          <textarea v-model="message" class="write-message" placeholder="내용을 입력해주세요" :style="{ height: inputHeight }"
            @focus="checkHeight()" @blur="inputHeight = ''" @keyup="checkHeight()" ref="textarea"></textarea>
          <button class="write-btn" @click="sendMessage()">전송</button>
          <button class="message-btn"
            @click="isShortMessage == true ? (isShortMessage = false) : (isShortMessage = true)">전송</button>
          <div class="short-message" :class="{ none: !isShortMessage }">
            <ul>
              <li>
                <a href="javascript:void(0)" @click="
                  message = '제가 희망하는 조건이 아니네요. 죄송합니다.';
                isShortMessage = false;
                ">제가 희망하는 조건이 아니네요. 죄송합니다.</a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="
                  message = '현재 시점에는 교류 의사가 없어요. 죄송합니다.';
                isShortMessage = false;
                ">현재 시점에는 교류 의사가 없어요. 죄송합니다.</a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="
                  message = '다른 분들과 인사교류 진행 중입니다. 죄송합니다.';
                isShortMessage = false;
                ">다른 분들과 인사교류 진행 중입니다. 죄송합니다.</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../../../mixin';
import axios from 'axios';
import moment from 'moment';
import jsonQuery from 'json-query';

export default {
  mixins: [mixin],
  data: function () {
    return {
      datas: {
        messages: [],
        people: {},
        infiniteId: +new Date(),
        lastMeId: '',
        firstMeId: '',
      },
      message: '',
      socket: null,
      isShortMessage: false,
      inputHeight: '',
      memoId: '',
      intervalCount: 0,
    };
  },
  created: async function () {
    let self = this;
    let interval = setInterval(() => {
      if (self.$router.history.current.name == 'dolligoMemoView2') {
        self.getNewMessage('newMemo');
        if (self.intervalCount > 30 && sessionStorage.getItem('interval-memo')) clearInterval(parseInt(sessionStorage.getItem('interval-memo')));
      }
    }, 3000);
    sessionStorage.setItem('interval-memo', interval);
  },
  methods: {
    getMessages: async function ($state) {
      try {
        // id 초기화
        delete this.axiosConfig.params.me_id;
        delete this.axiosConfig.params.first_me_id;

        // messages
        if (this.datas.messages.length > 0) {
          this.datas.lastMeId = this.datas.messages[0].me_id;
          this.axiosConfig.params.me_id = this.datas.lastMeId;
        }
        let messages = await axios.get(`/api/dolligo/memo/${this.$route.params.mb_id}`, this.axiosConfig);
        this.datas.people = messages.data.user_info;
        messages = messages.data.memos;

        if (messages.length == 0) {
          $state.complete();
        } else {
          this.datas.messages.unshift(...messages);
          $state.loaded();
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    sendMessage: async function () {
      if (this.message) {
        let data = { me_recv_mb_id: this.datas.people.mb_id, me_memo: this.message };
        await axios.post(`/api/dolligo/memo`, data, this.axiosConfig);
        this.getNewMessage('newMemo');
        this.message = '';
      }
    },

    scrollBottom: function () {
      setTimeout(() => {
        let messages = this.$refs.messages;
        if (messages) {
          window.scrollTo({ top: messages.scrollHeight, behavior: 'smooth' });
        }
      }, 100);
    },

    // 메모 전송 후 내 메세지 가져오기
    getNewMessage: async function (type) {
      try {
        let self = this;

        // id 초기화
        delete this.axiosConfig.params.me_id;
        delete this.axiosConfig.params.first_me_id;

        // 젤 아래 메모 id 세팅
        this.axiosConfig.params.first_me_id = this.datas.messages.length > 0 ? this.datas.messages[this.datas.messages.length - 1].me_id : '';

        let messages = await axios.get(`/api/dolligo/memo/${this.$route.params.mb_id}`, this.axiosConfig);
        messages = messages.data.memos;

        let isOtherPeopleMessage = jsonQuery(`[me_send_mb_id!=${this.MEMBER.mb_id}]`, {
          data: messages,
        }).value;

        if (messages.length > 1 || isOtherPeopleMessage) {
          for (let index = 0; index < this.datas.messages.length; index++) {
            const element = this.datas.messages[index];
            if (!element.me_read_datetime || element.me_read_datetime.substr(0, 4) == '0000') {
              this.datas.messages[index].me_read_datetime = moment().format('YYYY-MM-DD HH:mm:ss');
            }
          }
        }

        messages.forEach((element) => {
          let result = jsonQuery(`[me_id=${element.me_id}]`, {
            data: self.datas.messages,
          }).value;
          if (!result) this.datas.messages.push(element);
        });

        if (type == 'newMemo' && messages.length > 0) {
          this.scrollBottom();
        }
      } catch (error) {
        console.log(error);
        this.errorHandler(error);
      }
    },

    // 읽음 처리
    readMessage: async function () {
      let self = this;
      this.datas.messages = await this.datas.messages.map(function (message) {
        if (message.me_send_mb_id == self.MEMBER.mb_id && (!message.me_read_datetime || message.me_read_datetime.substr(0, 4) == '0000')) {
          message.me_read_datetime = moment().format('YYYY-MM-DD HH:mm:ss');
        }
        return message;
      });
    },

    // 입력창 스크롤 높이 체크해서 높이값 조정
    checkHeight: function () {
      let message = this.message.split('\n');
      this.inputHeight = 50 + (message.length - 1) * 25 + 'px';
    },

    goBack1: function () {
      this.$router.go(-1);
    },

    // 쪽지 나가기
    memoOut: async function () {
      try {
        if (confirm('나가면 쪽지 목록에서 사라집니다\n나가겠습니까?')) {
          let data = { block_mb_id: this.$route.params.mb_id };
          await axios.post(`/api/dolligo/block-memo-member`, data, this.axiosConfig);
          if(this.FROM.name == 'dolligoMemo') this.$router.go(-1);
          else this.$router.push({name: 'dolligoMemo'});
        }
      } catch (error) {
        console.log(error);
        this.errorHandler(error);
      }
    }
  },
};
</script>
