<template>
  <div id="app" class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`멤버십 혜택 상세 설명`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="benefit-wrap">
        <h3>{{ benefit.titles[$route.params.id - 1] }}</h3>
        <p>{{ benefit.descs[$route.params.id - 1] }}
        </p> <img :src="require(`@/assets/images/premium-img-${$route.params.id}.png`)" alt="상대방에게 알림톡(카카오톡) 발송">
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../../../mixin';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';
export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin]
}
</script>

