import BlindSettingIndex from '@/components/blind/setting/Index';

const blindSetting = [{
  path: '/blind/setting',
  name: 'blindSetting',
  component: BlindSettingIndex,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthBlind: true
  }
}]

export default blindSetting;