<template>
  <div class="blind-wrap">
    <top-back-header-blind :propIsBack="false" :propTitle="'프로필'"></top-back-header-blind>
    <section>
      <ul class="txt-list">
        <li><router-link :to="{ name: 'blindMemberEdit' }">
            <h3>회원 정보 변경</h3>
          </router-link>
        </li>
        <li><router-link :to="{ name: 'blindBoardList', params: { boardId: 'free' }, query: { 'is_me': 'y' } }">
            <h3>내가 쓴 글</h3>
          </router-link></li>
        <li><a href="javascript:void(0);" @click="logOut()">
            <h3>로그아웃</h3>
          </a></li>
        <li>
          <div class="block">
            <h3>PUSH 알림</h3> 
            <label class="switch">
              <input type="checkbox" v-model="MEMBER.b_push" true-value="1" false-value="0" @change="memberPatch()" />
              <span class="slider"></span>
            </label>
          </div>
        </li>
        <li>
          <div class="block">
            <h3>운영자에게 연락하기</h3>
            <p>공블리 APP에 대해 궁금하신 사항이나 요청사항은 이메일로 보내주시기 바랍니다. <a href="mailto:dolligo3@gmail.com">dolligo3@gmail.com</a>
            </p>
          </div>
        </li>
      </ul>
      <h2 class="list-tit">약관 및 정책</h2>
      <ul class="txt-list">
        <li>
          <router-link :to="{name: 'blindMemberTerm1'}">
            <h3>이용약관</h3>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'blindMemberTerm2'}">
            <h3>개인정보처리방침</h3>
          </router-link>
        </li>
      </ul>
    </section>
    <blind-footer></blind-footer>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '../../../mixin';
import BlindFooter from '../../common/BlindFooter.vue';
import TopBackHeaderBlind from '../../common/TopBackHeaderBlind.vue';
export default {
  components: { BlindFooter, TopBackHeaderBlind },
  mixins: [mixin],
  data: function () {
    return {
    }
  },
  created: async function () {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    memberPatch: async function () {
      try {
        let body = {
          b_push: this.MEMBER.b_push,
        };
        await axios.patch('/api/member', body, this.axiosConfig);
      } catch (error) {
        this.errorHandler(error);
      }
    }    
  }
}
</script>

