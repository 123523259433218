<template>
  <div id="app" class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`매너 평가 보기`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="manner-wrap">
        <h2><strong>{{member.mb_nick}}</strong>님이 받은 매너 평가</h2>
        <ul class="manner-list good">
          <li>채팅, 쪽지 답변이 빨라요. <span class="score">{{mannerScore.plus_type1_sum ? mannerScore.plus_type1_sum : 0}}</span></li>
          <li>친절하고 매너가 좋아요. <span class="score">{{mannerScore.plus_type2_sum ? mannerScore.plus_type2_sum : 0}}</span></li>
        </ul>
        <p class="notice" v-if="member.mb_id != me.mb_id">비매너 평가는 본인만 확인할 수 있습니다.</p>
        </div>
        <div class="manner-wrap">
        <h2 v-if="member.mb_id == me.mb_id"><strong>{{member.mb_nick}}</strong>님이 받은 비매너 평가</h2>
        <ul class="manner-list bad" v-if="member.mb_id == me.mb_id">
          <li>채팅, 쪽지 답변이 없어요. <span class="score">{{mannerScore.minus_type1_sum ? mannerScore.minus_type1_sum : 0}}</span></li>
          <li>답변이 너무 늦어요. <span class="score">{{mannerScore.minus_type2_sum ? mannerScore.minus_type2_sum : 0}}</span></li>
          <li>인사교류 약속을 하고 연락이 안 돼요. <span class="score">{{mannerScore.minus_type3_sum ? mannerScore.minus_type3_sum : 0}}</span></li>
          <li>욕설, 비방 등의 언행을 했어요. <span class="score">{{mannerScore.minus_type4_sum ? mannerScore.minus_type4_sum : 0}}</span></li>
        </ul>
        </div>
        <div class="manner-wrap">
        <div class="noti-box-wrap">
          <h3>인사교류 매너, 이렇게 지켜주세요.</h3>
          <ul>
            <li>서로 존중해요. 우리 존댓말로 대화해요.</li>
            <li>절대로 중간에 연락 끊기는 일이 없도록 해요.(잠수 금지)</li>
            <li>따뜻한 감사 인사로 마무리를 지어요.</li>
            <li>욕설, 비방, 명예훼손 등의 언행은 삼가주세요.</li>
            <li>늦은 시간 채팅은 자제해 주세요. </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import mixin from '../../../mixin';
  import Vue from 'vue';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

  export default {
  components: { TopBackHeaderDolligo },
    mixins: [mixin],
    data: function () {
      return {
        mannerScore: {},
        member: {},
        me: Vue.prototype.MEMBER
      }
    },
    created: async function() {
      await this.get();
      // 회원
      let member = await axios.get(`/api/member/${this.$route.query.reg_mb_id}`, this.axiosConfig);
      this.member = member.data;      
    },
    methods: {
      get: async function () {
        try {
          this.axiosConfig.params.reg_mb_id = this.$route.query.reg_mb_id;
          let mannerScore = await axios.get(`/api/manner-score/member/sum`, this.axiosConfig);
          this.mannerScore = mannerScore.data != null ? mannerScore.data : {};
        } catch (error) {
          this.errorHandler(error);
        }
      },      
    }
  }
</script>

