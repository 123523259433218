<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`설정`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <ul class="setting-wrap">
        <li>
          <div class="list">
            <strong class="title">교류 희망여부</strong>
            <span class="exp">OFF 시, 매칭 서비스가 제공 되지 않습니다.</span>
            <label class="switch">
              <input type="checkbox" v-model="MEMBER.mb_6" true-value="T" false-value="F" @change="memberPatch()" />
              <span class="slider"></span>
            </label>
          </div>
        </li>
        <li>
          <div class="list">
            <strong class="title">쪽지 수신 여부</strong>
            <label class="switch">
              <input type="checkbox" v-model="MEMBER.mb_open" true-value="1" false-value="0" @change="memberPatch()" />
              <span class="slider"></span>
            </label>
          </div>
        </li>
        <li>
          <div class="list">
            <strong class="title">PUSH 알림</strong>
            <label class="switch">
              <input type="checkbox" v-model="MEMBER.mb_7" true-value="T" false-value="F" @change="memberPatch()" />
              <span class="slider"></span>
            </label>
          </div>
        </li>
        <li>
          <router-link :to="{ name: 'dolligoPremiumIntro' }" class="list">
            <strong class="title">프리미엄 멤버십<span class="icon premium-i"></span></strong>
            <span class="icon arrow"></span>
          </router-link>
        </li>
        <li>
          <div class="contact">
            <strong class="title">운영진에게 연락하기</strong>
            <p>인사교류 APP에 대해 궁금하신 사항이나 요청사항은 이메일로 보내주시기 바랍니다. <a href="mailto:dollig3o@gmail.com">dolligo3@gmail.com</a>
            </p>
          </div>
        </li>
      </ul>
      <h3 class="setting-tit">회사 소개</h3>
      <ul class="setting-wrap">
        <li>
          <div class="list">
            <span class="subtit">법인명</span>
            <strong class="title">(주)이공이공</strong>
          </div>
        </li>
        <!-- <li>
          <div class="list">
            <span class="subtit">대표자</span>
            <strong class="title">최용민</strong>
          </div>
        </li> -->
        <li>
          <div class="list">
            <span class="subtit">주소</span>
            <strong class="title">경기도 고양시 일산동구 중앙로 1275번길 60-30</strong>
          </div>
        </li>
        <li>
          <div class="list">
            <span class="subtit">전화번호</span>
            <strong class="title">070-7627-2020</strong>
          </div>
        </li>
        <li>
          <div class="list">
            <span class="subtit">이메일</span>
            <strong class="title">dolligo3@gmail.com</strong>
          </div>
        </li>
        <li>
          <div class="list">
            <span class="subtit">사업자등록번호</span>
            <strong class="title">880-86-01578</strong>
          </div>
        </li>
      </ul>
      <h3 class="setting-tit">약관 및 정책</h3>
      <ul class="setting-wrap">
        <li>
          <router-link :to="{ name: 'terms1' }" class="list">
            <strong class="title">이용약관</strong>
            <span class="icon arrow"></span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'terms2' }" class="list">
            <strong class="title">개인정보처리방침</strong>
            <span class="icon arrow"></span>
          </router-link>
        </li>
        <li>
          <a href="javascript:void(0)" class="list" @click="logOut()">
            <strong class="title">로그아웃</strong>
            <span class="icon arrow"></span>
          </a>
        </li>
        <li v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
          <a style="background-color: yellow;" href="javascript:void(0)" class="list" @click="eventDelete()">
            <strong class="title">이벤트 참여 삭제</strong>
            <span class="icon arrow"></span>
          </a>
        </li>

        <li v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
          <a style="background-color: yellow;" href="javascript:void(0)" class="list" @click="changeMbLevel('2')"
            v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
            <strong class="title">일반회원 되기</strong>
            <span class="icon arrow"></span>
          </a>
        </li>

        <li v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
          <a style="background-color: yellow;" href="javascript:void(0)" class="list" @click="changeMbLevel('9')"
            v-if="MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
            <strong class="title">관리자 되기</strong>
            <span class="icon arrow"></span>
          </a>
        </li>

        <li v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
          <a style="background-color: yellow;" href="javascript:void(0)" class="list" @click="toBoPremium('12')"
            v-if="MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
            <strong class="title">구독회원 되기</strong>
            <span class="icon arrow"></span>
          </a>
        </li>

        <li v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
          <a style="background-color: yellow;" href="javascript:void(0)" class="list" @click="toBoPremium('14')"
            v-if="MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
            <strong class="title">3개월회원 되기</strong>
            <span class="icon arrow"></span>
          </a>
        </li>

        <li v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
          <a style="background-color: yellow;" href="javascript:void(0)" class="list" @click="toBoPremium('15')"
            v-if="MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
            <strong class="title">6개월회원 되기</strong>
            <span class="icon arrow"></span>
          </a>
        </li>


        <li v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
          <a style="background-color: yellow;" href="https://www-dev.gongdoll.com/login" class="list"
            v-if="MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
            <strong class="title">AWS 개발서버</strong>
            <span class="icon arrow"></span>
          </a>
        </li>

        <li v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
          <a style="background-color: yellow;" href="https://www.gongdoll.com/login" class="list"
            v-if="MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
            <strong class="title">AWS 운영서버</strong>
            <span class="icon arrow"></span>
          </a>
        </li>

        <li v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
          <a style="background-color: yellow;" href="javascript:void(0)" class="list" @click="swing('login')"
            v-if="MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
            <strong class="title">SWING 로그인</strong>
            <span class="icon arrow"></span>
          </a>
        </li>

        <li v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
          <a style="background-color: yellow;" href="javascript:void(0)" class="list" @click="swing('logout')"
            v-if="MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
            <strong class="title">SWING 로그아웃</strong>
            <span class="icon arrow"></span>
          </a>
        </li>

        <li v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
          <a style="background-color: yellow;" href="javascript:void(0)" class="list" @click="testPush()"
            v-if="MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
            <strong class="title">PUSH Test</strong>
            <span class="icon arrow"></span>
          </a>
        </li>        

        <li v-if="MEMBER.exception_member && MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
          <a style="background-color: yellow;" href="javascript:void(0)" class="list"
            v-if="MEMBER.exception_member && MEMBER.exception_member.is_admin == 'Y'">
            <strong class="title">{{ agent }}</strong>
            <span class="icon arrow"></span>
          </a>
        </li>

      </ul>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import mixin from '../../../mixin';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';
export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin],
  data: function () {
    return {
      agent: ''
    }
  },
  created: async function () {
    // console.log(this.MEMBER);
    this.agent = navigator.userAgent;
  },
  methods: {
    memberPatch: async function () {
      try {
        let body = {
          mb_6: this.MEMBER.mb_6,
          mb_7: this.MEMBER.mb_7,
          mb_open: this.MEMBER.mb_open,
        };
        await axios.patch('/api/member', body, this.axiosConfig);
      } catch (error) {
        this.errorHandler(error);
      }
    },
    eventDelete: async function () {
      try {
        await axios.delete('/api/dolligo/event-member', this.axiosConfig);
        this.$router.push({ name: 'dolligoPremiumEvent' });
      } catch (error) {
        this.errorHandler(error);
      }
    },
    changeMbLevel: async function (mb_level) {
      try {
        let body = {
          mb_level: mb_level
        };
        await axios.patch('/api/member', body, this.axiosConfig);
        this.$router.push({ name: 'dolligoPremiumIntro' });
      } catch (error) {
        this.errorHandler(error);
      }
    },
    toBoPremium: async function (pay_type) {
      try {
        let body = {
          pay_type: pay_type,
          is_cron: true
        };
        await axios.post('/api/dolligo/payments-info/to-bo-premium', body, this.axiosConfig);
        this.$router.push({ name: 'dolligoPremiumIntro' });
      } catch (error) {
        this.errorHandler(error);
      }
    },
    swing: async function (type) {
      try {
        if (type == 'login') {
          window.swingWebViewPlugin.app.login.doAppLogin(this.MEMBER.mb_id, this.MEMBER.mb_id);
          alert('SWING 로그인');
        } else if (type == 'logout') {
          window.swingWebViewPlugin.app.login.doAppLogout(this.MEMBER.mb_id, this.MEMBER.mb_id);
          alert('SWING 로그아웃');
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    testPush: async function () {
      try {
        let data = {
          recv_mb_id: this.MEMBER.mb_id,
          send_mb_id: this.MEMBER.mb_id,
          title: `${new Date().getTime()} test title`,
          message: `${new Date().getTime()} test push`,
          url: `https://${location.host}/dolligo/community/gongam`,
          swing_token: this.MEMBER.mb_20
        };
        await axios.post('/api/dolligo/push-list', data, this.axiosConfig);
        alert('푸시등록 완료');
      } catch (error) {
        this.errorHandler(error);
      }
    }
  }
}
</script>

