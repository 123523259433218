<template>
  <div class="blind-wrap">
    <top-back-header-blind :propIsBack="true" :propTitle="'이용약관'"></top-back-header-blind>
    <div class="container">
      <div class="term-wrap">
        <h1>제1장 총칙</h1>
        <h2>제1조 목적</h2>
        <p>(주)이공이공 (이하 ‘회사’)가 제공하는 서비스를 이용해 주셔서 감사합니다. 본 약관은 여러분이 서비스를 이용하는 데 필요한 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을
          규정하고 있습니다</p>

        <h2>제2조 약관의 명시, 효력 및 변경</h2>
        <ol>
          <li>본 약관의 내용은 회사가 제공하는 인사교류 서비스에 공지하며 본 약관에 동의한 여러분 모두에게 그 효력이 발생합니다.</li>
          <li>회사는 필요한 경우 관련법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다. 본 약관이 변경되는 경우 회사는 변경사항을 시행일자 15일 전부터 여러분에게 인사교류 App을 통해
            통지하는 것을 원칙으로 공지 하도록 하겠습니다.</li>
          <li>회사가 전 항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 여러분의
            의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다. <br>여러분이 개정약관에 동의하지 않을 경우 여러분은 이용계약을 해지할 수 있습니다.</li>
        </ol>

        <h2>제3조 약관 외 준칙</h2>
        <p>본 약관에 명시되지 않은 사항은 관계 법령에 규정되어 있을 경우 그 규정에 따르며, 그렇지 않은 경우에는 일반적인 관례를 따른다.</p>

        <h1>제2장 인사교류 계정 및 서비스의 이용</h1>
        <h2>제1조 인사교류 아이디 생성 및 폐기</h2>
        <ol>
          <li>인사교류 아이디란 여러분이 본 서비스에서 본인을 식별하기 위해 미리 등록한 문자, 특수문자, 숫자 등의 조합으로, 여러분이 본 서비스 약관에 동의하고 회원등록에 필요한 필수사항을 입력한 후
            회원등록을 완료하면 회사가 승낙하는 절차로 생성됩니다.</li>
          <li>인사교류 아이디는 여러분 본인만 이용할 수 있으며, 다른 사람이 여러분의 계정을 이용하도록 허락할 수 없습니다. 그리고 여러분은 다른 사람이 여러분의 계정을 무단으로 사용할 수 없도록 직접
            비밀번호를 관리하여야 합니다.</li>
        </ol>
        <h2>제2조 유료 서비스의 이용</h2>
        <ol>
          <li>회사는 무료로 서비스를 제공하고 있으나, 일부 서비스의 경우 유료로 제공할 수 있습니다.</li>
          <li>여러분이 회사가 제공하는 유료서비스를 이용하는 경우 이용대금을 납부한 후 이용하는 것을 원칙으로 합니다. 회사가 제공하는 유료서비스에 대한 이용요금의 결제 방법은 핸드폰결제, 신용카드결제,
            일반전화결제, 계좌이체, 무통장입금, 선불전자지급수단 결제 등이 있으며 각 유료서비스마다 결제 방법의 차이가 있을 수 있습니다. 매월 정기적인 결제가 이루어지는 서비스의 경우 여러분 개인이 해당
            서비스의 이용을 중단하고 정기 결제의 취소를 요청하지 않는 한 매월 결제가 이루어집니다.</li>
          <li>회사는 결제의 이행을 위하여 반드시 필요한 여러분의 개인정보를 추가적으로 요구할 수 있으며, 여러분은 회사가 요구하는 개인정보를 정확하게 제공하여야 합니다.</li>
          <li>여러분 개인의 귀책사유로 이용요금을 환불하는 경우 일반적인 방법은 아래와 같습니다. 단, 여러분이 본 약관을 위반하여 회사가 여러분의 서비스 이용을 제한하거나 계약을 해지하는 경우 회사는
            여러분에게 일체의 환불을 하지 않습니다.</li>
          <li>여러분은 이용요금에 대하여 이의를 제기할 수 있습니다. 단, 이용요금에 관한 이의는 그 사유 발생을 안 날로부터 1월, 그 사유가 발생한 날로부터 3월 이내에 제기하여야 합니다.</li>
        </ol>
        <h2>제3조 이용계약 해지</h2>
        <ol>
          <li>회원은 언제든지 회원탈퇴를 요청할 수 있으며 '설정 &gt; 회원탈퇴' 버튼을 통해 탈퇴를 하실 수 있습니다. 회원탈퇴 요청시 회원의 정보는 1일 이내에 삭제되며, 어떤 이유나 방법으로도
            재생되거나, 이용할 수 없도록 처리됩니다. 단, 상법 등 관련법령의 규정에 의하여 다음과 같이 거래 관련 관리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간
            보유합니다. <br>회원가입정보의 경우: 회원가입을 탈퇴하거나, 회원에서 제명된 때 (본인확인기관을 통해 받는 결과 값(CI)은 신규가입 축하포인트 부정 적립 방지를 위해 30일 보관 후 삭제)
            <br>설문조사 및 이벤트 등의 목적을 위하여 수집한 경우: 당해 설문조사, 이벤트 종료 한 후 3개월 이내 (당첨자 확인 및 경품 수령 확인 목적) <br>* 대금결제 및 재화 등의 공급에
            관한 기록 : 5년 <br></li>
          <li>이용계약이 해지되면 법령 및 개인정보 처리방침에 따라 여러분의 정보를 보유하는 경우를 제외하고는 여러분의 정보나 여러분이 작성한 게시물 등 모든 데이터는 삭제됩니다.</li>
        </ol>

        <h2>제4조 유료결제 해지 및 환불</h2>
        <ol>
          <li>정기결제 해지<br>
            정기결제 이용 시에는 해지가 가능하며 만료일 이전에 해지하셔도 남은 일수 만큼 환불되지 않습니다.<br>
            (기존 이용권 만료일까지 프리미엄 멤버십 혜택 유지)</li>

          <li>월결제 해지<br>
            월결제(3, 6개월) 이용 시에는 환불이 불가능합니다.</li>

          <li>인사교류앱 삭제와 결제는 무관합니다.<br>
            프리미엄 멤버십 이용은 앱삭제와 관계가 없으니 정기결제를 원치 않으시는 경우 꼭 해지신청을 해주시기 바랍니다.</li>
        </ol>


        <h1>제3장 기타</h1>
        <h2>제1조 통지 및 공지</h2>
        <p>여러분은 언제든지 고객센터에 방문하여 의견을 개진할 수 있습니다. 회사는 인사교류 App 을 통해 여러분에게 공지 또는 통지하며, 서비스 이용자 전체에 대한 공지는 7일 이상 서비스 공지사항 란에
          게시함으로써 효력이 발생합니다.</p>

        <h2>제2조 분쟁의 해결</h2>
        <p>본 약관 또는 서비스는 대한민국법령에 의하여 규정되고 이행됩니다. 서비스 이용과 관련하여 회사와 여러분 간에 분쟁이 발생하면 이의 해결을 위해 성실히 협의할 것입니다. 그럼에도 불구하고 해결되지
          않으면 민사소송법의 관할법원에 소를 제기할 수 있습니다.</p>

        <p>공고일자 : 2019년 3월 1일<br>시행일자 : 2019년 4월 1일</p>

      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixin';
import TopBackHeaderBlind from '../../common/TopBackHeaderBlind.vue';

export default {
  components: { TopBackHeaderBlind },
  mixins: [mixin],
  created: function () {
    window.addEventListener('scroll', this.handleScroll);
  }
}
</script>
  
