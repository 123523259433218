<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`프리미엄 멤버십`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="premium-bottom">
        <ul class="products-list">
          <li>
            <router-link :to="{ name: 'dolligoPremiumEvent' }">
              <h2>근무 환경 평가 + 상세 정보 입력 시</h2>
              <span class="price-wrap">
                <span class="final-price">1일 무료 체험</span>
              </span>
              <span class="icon arrow"></span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'dolligoPremiumPay', query: { payType: '12' } }">
              <h2>매월 1개월 정기 결제</h2>
              <span class="discount">29%</span>
              <span class="og-price">8,500원</span>
              <span class="price-wrap">
                <span class="final-price">월 6,000원</span>
              </span>
              <span class="period">할인 기간 : ~ {{ expireDate }}까지</span>
              <span class="icon arrow"></span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'dolligoPremiumPay', query: { payType: '14' } }">
              <h2>3개월 결제</h2>
              <span class="discount">41%</span>
              <span class="og-price">25,500원</span>
              <span class="price-wrap">
                <span class="final-price">15,000원</span>
                <span class="total-price">월 5,000원</span>
              </span>
              <span class="period">할인 기간 : ~ {{ expireDate }}까지</span>
              <span class="icon arrow"></span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'dolligoPremiumPay', query: { payType: '15' } }">
              <h2>6개월 결제</h2>
              <span class="discount">47%</span>
              <span class="og-price">51,000원</span>
              <span class="price-wrap">
                <span class="final-price">27,000원</span>
                <span class="total-price">월 4,500원</span>
              </span>
              <span class="period">할인 기간 : ~ {{ expireDate }}까지</span>
              <span class="icon arrow"></span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../../../mixin';
import moment from 'moment';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin],
  data: function () {
    return {
      expireDate: moment().clone().endOf('month').format('MM월 DD일')
    }
  },
}
</script>

