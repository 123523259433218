<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`회원탈퇴`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="center-wrap">
        <p class="center-txt"><strong>회원 탈퇴 시 프리미엄 멤버십<br>혜택을 받을 수 없습니다.</strong></p>
      </div>
      <div class="write-wrap">
        <div class="list-wrap">
          <ul>
            <li>
              <label for="reason1" class="form-check-label">
                <input type="radio" id="reason1" class="form-check-input" v-model="mb_34" value="1">인사교류
                성공<span class="form-check-sign"><span class="check"></span></span>
              </label>
            </li>
            <li>
              <label for="reason2" class="form-check-label">
                <input type="radio" id="reason2" class="form-check-input" v-model="mb_34" value="2">인사교류 필요
                없어짐<span class="form-check-sign"><span class="check"></span></span>
              </label>
            </li>
            <li>
              <label for="reason3" class="form-check-label">
                <input type="radio" id="reason3" class="form-check-input" v-model="mb_34" value="3">어플
                불만족<span class="form-check-sign"><span class="check"></span></span>
              </label>
            </li>
            <li>
              <label for="reason4" class="form-check-label">
                <input type="radio" id="reason4" class="form-check-input" v-model="mb_34" value="4">기타<span
                  class="form-check-sign"><span class="check"></span></span>
              </label>
            </li>
            <li><textarea v-model="mb_35" placeholder="탈퇴 사유를 입력해주세요."></textarea></li>
          </ul>
        </div>
        <button type="submit" class="submit-btn" :class="{ on: isSave() }" @click="save()">회원 탈퇴</button>
      </div>
    </div>
    <spinner ref="spinner"></spinner>
  </div>
</template>



<script>
import axios from 'axios';
import mixin from '../../../mixin'
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';
import Spinner from '../../common/Spinner.vue';

export default {
  components: { TopBackHeaderDolligo, Spinner },
  name: 'dolligoMemberOut',
  mixins: [mixin],
  data: function () {
    return {
      mb_34: '',
      mb_35: ''
    }
  },
  methods: {
    isSave: function () {
      if (!this.mb_34) return false;
      if (this.mb_34 == '4' && !this.mb_35) return false;
      return true;
    },
    save: async function () {
      try {
        if (this.isSave() == false) return false;
        this.$refs.spinner.show = true;
        let body = { outType: "dolligo", mb_34: this.mb_34, mb_35: this.mb_35 };
        await axios.post('/api/member/out', body, this.axiosConfig);
        this.$router.push({ name: 'dolligoMemberOutDone' })
      } catch (error) {
        this.$refs.spinner.show = false;
        this.errorHandler(error);
      }
    }
  }
}

</script>