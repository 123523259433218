<template>
  <li>
    <select v-model="jobRank.mb_1" @change="onDataChanged">
      <option value="">직군선택</option>
      <option v-for="item in jobs" v-bind:key="item.grd1_cd" v-bind:value="item.grd1_cd">{{item.grd1_nm}}</option>
    </select>
    <select v-model="jobRank.mb_2" @change="onDataChanged">
      <option value="">직급선택</option>
      <option v-for="item in ranks" v-bind:key="item.grd2_cd" v-bind:value="item.grd2_cd">{{item.grd2_nm}}</option>
    </select>
  </li>
</template>


<script>
  import mixin from '../../mixin';
  import axios from 'axios';

  export default {
    mixins: [mixin],
    props: {
      jobRankCode : Object
    },
    data: function () {
      return {
        jobRank : this.jobRankCode,
        jobs: [],
        ranks: [],
      }
    },
    created: async function () {
      this.jobs = await axios.get('/api/jobs', this.axiosConfig); // 직렬
      this.jobs = this.jobs.data;

      this.ranks = await axios.get('/api/ranks', this.axiosConfig); // 직급
      this.ranks = this.ranks.data;      
    },
    methods: {
      onDataChanged() {
        this.$emit('input', this.jobRank) // input 이벤트 발생
      }
    }
  }
</script>

