<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`근무 환경 평가`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="review-office-wrap">
        <div class="rating-wrap">
          <h2 class="rating">{{ data.review.score }}</h2>
          <p class="date">{{ data.review.txtDate }}</p>
          <ul class="reviewer-info">
            <li>{{ data.review.po_type }}</li>
            <li>{{ data.review.jobName }}</li>
            <li>{{ data.review.cityName }} {{ data.review.districtName }}</li>
            <li v-if="data.review.branchName">{{ data.review.branchName }}</li>
            <li>{{ data.review.wr_1 }} ~ {{ data.review.wr_2 }}</li>
          </ul>
        </div>
        <div class="list-wrap">
          <ul class="rating-list">
            <li>
              <div class="rating-tit">
                <h2>복지혜택</h2>
                <span class="info-txt">복지포인트, 연차사용, 보건휴가, 특별휴가 등</span>
              </div>
              <div class="rating-star">
                <span class="star" v-bind:class="{ on: data.review.wr_3 >= 1 }">1</span>
                <span class="star" v-bind:class="{ on: data.review.wr_3 >= 2 }">2</span>
                <span class="star" v-bind:class="{ on: data.review.wr_3 >= 3 }">3</span>
                <span class="star" v-bind:class="{ on: data.review.wr_3 >= 4 }">4</span>
                <span class="star" v-bind:class="{ on: data.review.wr_3 >= 5 }">5</span>
              </div>
            </li>
            <li>
              <div class="rating-tit">
                <h2>일&amp;삶 균형</h2>
                <span class="info-txt">정시퇴근 및 잔업 없음 등</span>
              </div>
              <div class="rating-star">
                <span class="star" v-bind:class="{ on: data.review.wr_4 >= 1 }">1</span>
                <span class="star" v-bind:class="{ on: data.review.wr_4 >= 2 }">2</span>
                <span class="star" v-bind:class="{ on: data.review.wr_4 >= 3 }">3</span>
                <span class="star" v-bind:class="{ on: data.review.wr_4 >= 4 }">4</span>
                <span class="star" v-bind:class="{ on: data.review.wr_4 >= 5 }">5</span>
              </div>
            </li>
            <li>
              <div class="rating-tit">
                <h2>승진기회</h2>
              </div>
              <div class="rating-star">
                <span class="star" v-bind:class="{ on: data.review.wr_5 >= 1 }">1</span>
                <span class="star" v-bind:class="{ on: data.review.wr_5 >= 2 }">2</span>
                <span class="star" v-bind:class="{ on: data.review.wr_5 >= 3 }">3</span>
                <span class="star" v-bind:class="{ on: data.review.wr_5 >= 4 }">4</span>
                <span class="star" v-bind:class="{ on: data.review.wr_5 >= 5 }">5</span>
              </div>
            </li>
            <li>
              <div class="rating-tit">
                <h2>동료&amp;상사</h2>
                <span class="info-txt">전입자 대우, 수평적 분위기 등</span>
              </div>
              <div class="rating-star">
                <span class="star" v-bind:class="{ on: data.review.wr_6 >= 1 }">1</span>
                <span class="star" v-bind:class="{ on: data.review.wr_6 >= 2 }">2</span>
                <span class="star" v-bind:class="{ on: data.review.wr_6 >= 3 }">3</span>
                <span class="star" v-bind:class="{ on: data.review.wr_6 >= 4 }">4</span>
                <span class="star" v-bind:class="{ on: data.review.wr_6 >= 5 }">5</span>
              </div>
            </li>
            <li>
              <p v-html="changeBr(String(data.review.wr_7))"></p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '../../../mixin';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin],
  data: function () {
    return {
      data: {
        review: {}
      }
    }
  },
  created: async function () {
    if (this.MEMBER.mb_level == '2') {
      this.$router.replace({ name: 'dolligoPremiumIntro' });
      return false;
    }
    this.data.review = await axios.get(`/api/dolligo/review/${this.$route.params.id}`, this.axiosConfig);
    this.data.review = this.data.review.data;
  }
}
</script>

