import DolligoPremiumIntro from '@/components/dolligo/premium/Intro';
import DolligoPremiumBenefit from '@/components/dolligo/premium/Benefit';
import DolligoPremiumState from '@/components/dolligo/premium/State';
import DolligoPremiumReady from '@/components/dolligo/premium/Ready';
import DolligoPremiumEvent from '@/components/dolligo/premium/Event';
import DolligoPremiumPay from '@/components/dolligo/premium/Pay';
import DolligoPremiumPayV3 from '@/components/dolligo/premium/PayV3';
import DolligoPremiumTerm from '@/components/dolligo/premium/Term';
import DolligoPremiumPayDone from '@/components/dolligo/premium/PayDone';
import DolligoPremiumPayFail from '@/components/dolligo/premium/PayFail';

const dolligoPremium = [{
  path: '/dolligo/premium/intro',
  name: 'dolligoPremiumIntro',
  component: DolligoPremiumIntro,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/premium/benefit/:id',
  name: 'dolligoPremiumBenefit',
  component: DolligoPremiumBenefit,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/premium/state',
  name: 'dolligoPremiumState',
  component: DolligoPremiumState,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/premium/ready',
  name: 'dolligoPremiumReady',
  component: DolligoPremiumReady,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/premium/event',
  name: 'dolligoPremiumEvent',
  component: DolligoPremiumEvent,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/premium/pay',
  name: 'dolligoPremiumPay',
  component: DolligoPremiumPay,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/premium/pay-v3',
  name: 'dolligoPremiumPayV3',
  component: DolligoPremiumPayV3,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/premium/term',
  name: 'dolligoPremiumTerm',
  component: DolligoPremiumTerm,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/premium/pay-done',
  name: 'dolligoPremiumPayDone',
  component: DolligoPremiumPayDone,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/premium/pay-fail',
  name: 'dolligoPremiumPayFail',
  component: DolligoPremiumPayFail,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}]

export default dolligoPremium