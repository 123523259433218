import DolligoMemoIndex from '@/components/dolligo/memo/Index';
import DolligoMemoView from '@/components/dolligo/memo/View';
import DolligoMemoView2 from '@/components/dolligo/memo/View2';

const dolligoMemo = [{
  path: '/dolligo/memo',
  name: 'dolligoMemo',
  component: DolligoMemoIndex,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/memo/:mb_id',
  name: 'dolligoMemoView2',
  component: DolligoMemoView2,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}, {
  path: '/dolligo/memo/:mb_id',
  name: 'dolligoMemoView',
  component: DolligoMemoView,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}]

export default dolligoMemo