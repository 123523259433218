<template>
  <header class="fixed">
    <a href="javascript:void(0)" class="icon back-btn" @click="goBack()">뒤로가기</a>
    <h1>{{ title }}</h1>
    <div class="right-btns" v-if="$route.name == 'dolligoCommunityView'">
      <router-link :to="{ name: 'dolligoCommunityRegist', params: { boardId: $route.params.boardId } }"
        class="icon write-btn">글쓰기</router-link>
    </div>
    <div class="right-btns"
      v-if="$route.name == 'dolligoCommunityRegist' || $route.name == 'dolligoReviewRegist' || $route.name == 'dolligoCommunityEdit'">
      <a href="javascript:void(0)" class="txt-btn" :class="{ on: $parent.isSave() }" @click="$parent.save()">저장</a>
    </div>
    <div class="right-btns" v-if="$route.name == 'dolligoMemberRegist'">
      <a href="javascript:void(0);" :class="{ 'txt-btn': true, on: $parent.isSubmit() }"
        v-on:click="$parent.saveMember()">저장</a>
    </div>
    <div class="right-btns" v-if="$route.name == 'dolligoMemberRegistDetail' || $route.name == 'dolligoMemberWishArea'">
      <a href="javascript:void(0);" v-bind:class="{ 'txt-btn': true, on: $parent.isSubmit() }"
        v-on:click="$parent.save()">저장</a>
    </div>
    <div class="right-btns" v-if="$route.name == 'dolligoReviewMe'">
      <router-link :to="{ name: 'dolligoReviewRegist' }" class="txt-btn on">평가하기</router-link>
    </div>
    <div class="right-btns" v-if="$route.name == 'dolligoMemberProfile' && $route.params.id != MEMBER.mb_id">
      <a href="javascript:void(0)" class="icon more-btn" @click="$parent.showBottomSheet = true">저장</a>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    propTitle: String,
  },
  data: function () {
    return {
      title: this.propTitle,
    }
  },
  mounted: function () {
    switch (this.$route.name) {
      case 'dolligoCommunityView':
        if (this.$route.params.boardId == 'gongam') this.title = '교류요청';
        if (this.$route.params.boardId == 'success') this.title = '성공했어요!';
        if (this.$route.params.boardId == 'qa') this.title = 'Q&A';
        if (this.$route.params.boardId == 'notice') this.title = '뉴스&공지';
        if (this.$route.params.boardId == 'faq') this.title = 'Faq';
        break;
      case 'dolligoCommunityRegist':
        if (this.$route.params.boardId == 'gongam') this.title = '교류요청 작성하기';
        if (this.$route.params.boardId == 'success') this.title = '성공했어요! 작성하기';
        if (this.$route.params.boardId == 'qa') this.title = 'Q&A 작성하기';
        if (this.$route.params.boardId == 'notice') this.title = '뉴스&공지 작성하기';
        if (this.$route.params.boardId == 'faq') this.title = 'Faq 작성하기';
        break;
      case 'dolligoCommunityEdit':
        if (this.$route.params.boardId == 'gongam') this.title = '교류요청 수정하기';
        if (this.$route.params.boardId == 'qa') this.title = 'Q&A 수정하기';
        if (this.$route.params.boardId == 'notice') this.title = '뉴스&공지 수정하기';
        if (this.$route.params.boardId == 'faq') this.title = 'Faq 수정하기';
        break;
    }
  },
  methods: {
    goBack: function () {
      if (this.$route.name == 'dolligoCommunityView' && (!this.FROM || !this.FROM.name)) {
        this.$router.push({ name: 'dolligoCommunity', params: { boardId: this.$route.params.boardId } });
      }
      else if (this.FROM.name) {
        this.$router.go(-1);
      }
      else {
        this.$router.push('/branch');
      }
    }
  }
}
</script>

