import BlindBoardList from '@/components/blind/board/List';
import BlindBoardView from '@/components/blind/board/View';
import BlindBoardEdit from '@/components/blind/board/Edit';

const blindBoard = [
  {
    path: '/blind/board/:boardId/edit',
    name: 'blindBoardEdit',
    component: BlindBoardEdit,
    meta: {
      checkAgent: true,
      checkAuth: true,
      checkAuthBlind: true
    }
  },
  {
    path: '/blind/board/:boardId/edit/:wr_id',
    name: 'blindBoardModify',
    component: BlindBoardEdit,
    meta: {
      checkAgent: true,
      checkAuth: true,
      checkAuthBlind: true
    }
  }, {
    path: '/blind/board/:boardId',
    name: 'blindBoardList',
    component: BlindBoardList,
    meta: {
      checkAgent: true,
      checkAuth: true,
      checkAuthBlind: true
    }
  }, {
    path: '/blind/board/:boardId/:wr_id',
    name: 'blindBoardView',
    component: BlindBoardView,
    meta: {
      checkAgent: true,
      checkAuth: true,
      checkAuthBlind: true
    }
  }]

export default blindBoard