<template>
  <div class="dolligo-wrap">
    <!-- <header class="fixed">
      <a href="javascript:void(0)" class="icon back-btn" @click="$router.back()">뒤로가기</a>
      <h1>{{ title }} 작성하기</h1>
      <div class="right-btns">
        <a href="javascript:void(0)" class="txt-btn" :class="{ on: isSave() }" @click="save()">저장</a>
      </div>
    </header> -->
    <top-back-header-dolligo ></top-back-header-dolligo>    
    <div class="content-wrap">
      <div class="board-write-wrap">
        <ul class="check-list">
          <li>
            <label for="notice" class="form-check-label" v-if="MEMBER.mb_level == '9'">
              <input type="checkbox" id="notice" v-model="datas.board.isNotice" true-value="1" false-value="0"
                class="form-check-input">공지글로 등록하기<span class="form-check-sign"><span class="check"></span></span><span
                class="form-check-icon anonymous"></span>
            </label>
          </li>
          <li>
            <label for="wr_1" class="form-check-label">
              <input type="checkbox" id="wr_1" v-model="datas.board.wr_1" true-value="1" false-value=""
                class="form-check-input">익명으로 등록하기<span class="form-check-sign"><span class="check"></span></span><span
                class="form-check-icon anonymous"></span>
            </label>
          </li>
          <li v-if="!$route.params.wr_id && $route.params.boardId == 'gongam'">
            <label for="wr_2" class="form-check-label">
              <input type="checkbox" id="wr_2" v-model="datas.board.wr_2" true-value="1" false-value=""
                class="form-check-input" @change="matchCount()">푸시 알림 보내기
              <span class="form-check-sign">
                <span class="check"></span>
              </span>
              <span class="form-check-icon push"></span>
            </label>
            <div class="push-box">
              <p id="ptext" v-if="datas.board.wr_2 != '1'">본인 자리를 채울 교류자들에게 푸시 알림을 보낼 수 있습니다.</p>
              <p id="ptext" v-if="datas.board.wr_2 == '1'">본인 자리를 채울 교류자
                <span style="color:#FF7144;font-weight:bold;">{{ datas.matchMemberCount }}</span>명에게 푸시 알림을 보냅니다.
              </p>
              <ul class="page-info-list">
                <!-- <li>교류 요청 푸시 알림은 3일 간격으로 사용 가능합니다.</li> -->
                <li>활동 회원 위주로 최대 50명에게 푸시 알림을 보냅니다.</li>
              </ul>
              <label for="wr_3" class="form-check-label" v-if="datas.board.wr_2 == '1'">
                <input type="checkbox" id="wr_3" v-model="datas.board.wr_3" true-value="" false-value="1"
                  class="form-check-input" @change="matchCount()">직렬 상관 없음(모든 직렬 회원에게
                발송)<span class="form-check-sign"><span class="check"></span></span>
              </label>
            </div>
          </li>
        </ul>
        <input v-model="datas.board.wr_subject" type="text" class="write-title" placeholder="제목" maxlength="255">
        <textarea class="write-cont" placeholder="내용을 입력해주세요" v-model="datas.board.wr_content"></textarea>
      </div>
    </div>
    <spinner ref="spinner"></spinner>
  </div>
</template>

<script>
import mixin from '../../../mixin';
import axios from 'axios';
import Spinner from '../../common/Spinner.vue';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

export default {
  components: {
    Spinner,
    TopBackHeaderDolligo
  },
  mixins: [mixin],
  data: function () {
    return {
      datas: {
        board: {
          wr_subject: '',
          wr_content: '',
          wr_is_comment: '0',
          wr_1: '',
          wr_2: '',
          wr_3: '1',
          isNotice: '0'
        },
        matchMemberCount: 0
      },
      dolligoCommunity: JSON.parse(sessionStorage.getItem('dolligoCommunity')),
      title: ''
    }
  },
  created: async function () {
    // 게시물 상세
    if (this.$route.params.wr_id) {
      let board = await axios.get(`/api/dolligo/community/${this.$route.params.boardId}/${this.$route.params.wr_id}`, this.axiosConfig);
      board = board.data;
      if (!board) {
        alert('존재하지 않는 게시물 입니다.');
        this.$router.push({
          name: 'dolligoCommunity',
          params: {
            boardId: this.$route.params.boardId
          }
        });
        return;
      }
      this.datas.board.wr_subject = board.wr_subject;
      this.datas.board.wr_content = board.wr_content;
      this.datas.board.wr_is_comment = board.wr_is_comment;
      this.datas.board.wr_is_comment = board.wr_is_comment;
      this.datas.board.wr_1 = board.wr_1;
      this.datas.board.wr_2 = board.wr_2;
      this.datas.board.wr_3 = board.wr_3;
      this.datas.board.isNotice = board.isNotice;
    }

    // setting title
    if (this.$route.params.boardId == 'gongam') this.title = '교류요청';
    if (this.$route.params.boardId == 'success') this.title = '성공했어요!';
    if (this.$route.params.boardId == 'qa') this.title = 'Q & A';
    if (this.$route.params.boardId == 'notice') this.title = '공지사항';
    if (this.$route.params.boardId == 'faq') this.title = '자주묻는질문';
  },
  methods: {
    isSave: function () {
      if (!this.datas.board.wr_subject.trim()) return false;
      if (!this.datas.board.wr_content.trim()) return false;
      return true;
    },
    save: async function () {
      try {
        if (this.isSave() == false) return false;

        this.$refs.spinner.show = true;
        if (this.$route.params.wr_id) {
          await axios.put(`/api/dolligo/community/${this.$route.params.boardId}/${this.$route.params.wr_id}`, this.datas.board, this.axiosConfig);
          this.updateSession();
          setTimeout(() => {
            this.$router.back();
          }, 500);
        }
        else {
          sessionStorage.removeItem('dolligoCommunity');
          await axios.post(`/api/dolligo/community/${this.$route.params.boardId}`, this.datas.board, this.axiosConfig);

          setTimeout(() => {
            this.$refs.spinner.show = false;
            this.$router.replace({
              name: 'dolligoCommunity',
              params: {
                boardId: this.$route.params.boardId
              }
            });
          }, 500);
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },

    // 세션 수정
    updateSession: async function () {
      if (this.dolligoCommunity) {
        let self = this;
        let board = await axios.get(`/api/dolligo/community/${this.$route.params.boardId}/${this.$route.params.wr_id}`, this.axiosConfig);
        this.dolligoCommunity.boards.forEach((element, index) => {
          if (element.wr_id == this.$route.params.wr_id) {
            board = board.data;
            self.dolligoCommunity.boards[index] = board;
          }
        })
        sessionStorage.setItem('dolligoCommunity', JSON.stringify(this.dolligoCommunity));
      }
    },

    // 본인 자리 채울 교류자 카운트 가져오기
    matchCount: async function () {
      try {
        if (this.datas.board.wr_2 == '1') {
          let matchMembers = await axios.post(`/api/dolligo/community/match/count`, { wr_3: this.datas.board.wr_3 }, this.axiosConfig);
          this.datas.matchMemberCount = matchMembers.data.length;
        }
      } catch (error) {
        this.errorHandler(error);
      }
    }
  }
}
</script>

