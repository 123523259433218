<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`신고하기`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="top-txt">
        <h1>
          <strong>‘{{ member.mb_nick }}’님을<br>신고하는 이유를 선택해 주세요</strong>
        </h1>
      </div>
      <div class="write-wrap">
        <div class="list-wrap">
          <ul>
            <li v-for="(element, index) in reasons" v-bind:key="element.key">
              <label :for="`reason${index}`" class="form-check-label">
                <input type="checkbox" @click="setReason()" :id="`reason${index}`" class="form-check-input"
                  :value="element.name">{{ element.name }}<span class="form-check-sign"><span class="check"></span></span>
              </label>
            </li>
            <li>
              <textarea placeholder="신고 내용을 입력해 주세요" v-model="note"></textarea>
            </li>
          </ul>
        </div>
        <button class="submit-btn" :class="{ on: isSubmit() }" @click="save()">신고하기</button>
        <a href="javascript:history.back()" class="low-btn">취소</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '../../../mixin'
import $ from "jquery";
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';
export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin],
  data: function () {
    return {
      recv_mb_nick: this.$route.query.recv_mb_nick,
      bo_table: this.$route.query.bo_table,
      wr_id: this.$route.query.wr_id,
      content: '',
      note: '',
      reasons: [{
        key: 'reason1',
        name: '스팸홍보/도배글 입니다'
      },
      {
        key: 'reason2',
        name: '음란물입니다'
      },
      {
        key: 'reason3',
        name: '불법정보를 포함하고 있습니다'
      },
      {
        key: 'reason4',
        name: '청소년에게 유해한 내용입니다'
      },
      {
        key: 'reason5',
        name: '욕설/생명경시/혐오/차별적 표현입니다'
      },
      {
        key: 'reason6',
        name: '개인정보 노출 게시물입니다'
      },
      {
        key: 'reason7',
        name: '불쾌한 표현이 있습니다'
      },
      {
        key: 'reason8',
        name: '기타'
      },],
      member: {}
    }
  },
  created: async function () {
    try {
      let member = await axios.get(`/api/member/${this.$route.query.recv_mb_nick}`, this.axiosConfig);
      this.member = member.data;
      // console.log(member);
    } catch (error) {
      this.errorHandler(error)
    }
  },
  methods: {
    setReason: function () {
      var str = "";
      $("input:checkbox:checked").each(function () {
        str += $(this).val() + ",";
      });
      this.content = str;
    },
    isSubmit: function () {
      if (this.content == '기타,' && this.note) return true;
      else if (this.content.length > 0 && this.content != '기타,') return true;
      else return false;
    },
    save: async function () {
      if (this.isSubmit() == false) return;
      let data = {
        recv_mb_id: this.recv_mb_nick,
        bo_table: this.bo_table,
        wr_id: this.wr_id,
        note: this.note,
        content: this.content
      }
      await axios.post(`/api/dolligo/report`, data, this.axiosConfig);
      alert('신고 완료 되었습니다.');
      this.$router.back();
    }
  }
}
</script>

