<template>
  <li>
    <label class="submit-btn" :class="{on: departCode.mb_8=='국가직'}"><input type="radio" value="국가직" v-model="departCode.mb_8" @change="onDataChanged" /> 국가직 </label>
    <label class="submit-btn" :class="{on: departCode.mb_8=='지방직'}"><input type="radio" value="지방직" v-model="departCode.mb_8" @change="onDataChanged" /> 지방직 </label>
    <label v-if="isShowNoSelect==true" class="submit-btn" :class="{on: depart=='선택안함'}"><input type="radio" value="선택안함" v-model="departCode.mb_8" @change="onDataChanged" /> 선택안함 </label>
  </li>
</template>

<script>
  export default {
    props: {
      departCode: Object,
      isShowNoSelect: Boolean,
    },
    data: function () {
      return {
        depart: this.departCode,
      }
    },
    created: async function(){
      //  console.log(this.depart);
    },
    methods: {
      onDataChanged() {
        this.$emit('input', this.depart) // input 이벤트 발생
      }
    }
  }
</script>

