<template>
  <div class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`프리미엄 멤버십`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <div class="center-wrap" v-if="MEMBER.mb_level < 5">
        <p class="center-txt">
          <strong>프리미엄 멤버십</strong>
          가입하시고<br />편리한 서비스 혜택을 누리세요.
        </p>
        <router-link :to="{ name: 'dolligoPremiumReady' }" class="submit-btn p-on">멤버십 가입하기</router-link>
      </div>
      <div class="center-wrap" v-if="MEMBER.mb_level > 4">
        <p class="center-txt">
          <strong>프리미엄 멤버십 이용 중</strong>
        </p>
        <router-link :to="{ name: 'dolligoPremiumState' }" class="submit-btn border">이용현황</router-link>
      </div>
      <ul class="benefit-list">
        <li>
          <router-link :to="{ name: 'dolligoPremiumBenefit', params: { id: '1' } }">
            <span class="icon kakao"></span>
            <span class="txt">상대방에게 알림톡 발송</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'dolligoPremiumBenefit', params: { id: '2' } }">
            <span class="icon mail"></span>
            <span class="txt">쪽지 응답률 및 최근 접속일 확인</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'dolligoPremiumBenefit', params: { id: '3' } }">
            <span class="icon chat"></span>
            <span class="txt">다자교류자들 확인 및 그룹채팅</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'dolligoPremiumBenefit', params: { id: '4' } }">
            <span class="icon search"></span>
            <span class="txt">꼭 맞는 교류자 검색</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'dolligoPremiumBenefit', params: { id: '5' } }">
            <span class="icon review"></span>
            <span class="txt">현직 근무자들의 근무환경 리뷰</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import mixin from '../../../mixin';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';
export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin],
}
</script>

