<template>
  <div>
    <a href="javascript:void(0);" class="icon drawer-btn drawer-btn-click" v-bind:class="{on: me.unreadPushCount > 0}"
      v-on:click="isDrawerWrapOn=true">메뉴</a>
    <aside v-bind:class="{'drawer-wrap': true, on:isDrawerWrapOn}">
      <div class="top">
        <router-link :to="{name: 'dolligoPushlist'}" class="icon notification-btn" v-bind:class="{on: me.unreadPushCount > 0}" @click.native="$parent.setBackType()">알림</router-link>
        <router-link :to="{name: 'dolligoSetting'}" class="icon setting-btn">설정</router-link>
      </div>
      <router-link class="profile" :to="{name: 'dolligoMemberProfile', params: {id: me.mb_id}}">
        <span :class="'icon face' + me.mb_36"></span>
        <span class="name">{{me.mb_nick}}</span>
        <span class="icon" v-bind:class="{'premium-i': me.mb_level > 2}"></span>
        <p>{{getState(me.mb_memo_call)}}</p>
      </router-link>
      <ul class="member-list">
        <li>
          <router-link :to="{name: 'dolligoPremiumIntro'}"><span class="tit">프리미엄 멤버십</span></router-link>
        </li>
        <li>
          <router-link :to="{name: 'dolligoMemberMark'}">
            <span class="tit">관심 교류자</span>
            <span class="member">{{me.bookmarkCount}}</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'dolligoMemberWithout'}">
            <span class="tit">매칭제외 교류자</span>
            <span class="member css-without">{{me.withoutCount}}</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name:'dolligoMemberMannerScoreView', query: {reg_mb_id: me.mb_id}}">
            <span class="tit">내 매너평가 보기</span>
          </router-link>
        </li>
      </ul>
      <ul class="menu-list">
        <li>
          <router-link :to="{name: 'dolligoMemberRegist'}">
            <span class="tit">회원 정보</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'dolligoMemberRegistDetail'}">
            <span class="tit">상세 근무 정보</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'dolligoMemberWishArea'}">
            <span class="tit">희망 근무지</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'dolligoReviewMe'}">
            <span class="tit">근무환경 평가</span>
          </router-link>
        </li>
      </ul>
    </aside>
    <div class="drawer-wrap-bg" v-on:click="isDrawerWrapOn=false"></div>
    <div class="right-btns">
      <router-link
        v-if="type=='community' && (MEMBER.mb_level == '9' || $route.params.boardId == 'gongam' || $route.params.boardId == 'success' || $route.params.boardId == 'qa')"
        :to="{name: 'dolligoCommunityRegist', params: {boardId: $route.params.boardId}}" @click.native="saveSession()"
        class="icon write-btn">글쓰기</router-link>
      <router-link :to="{name: 'blindIndex'}" class="switch-btn">
        <strong>공블리</strong>로 전환
      </router-link>
    </div>
    <router-link v-if="$route.name == 'dolligoMain'" :to="{path: `/dolligo/main`}" class="icon logo" >인사교류</router-link>    
  </div>
</template>

<script>
  import mixin from '../../mixin'

  export default {
    mixins: [mixin],
    props: {
      propType: String,
    },
    data: function(){
      return {
        isDrawerWrapOn: false,
        type: this.propType
      }
    },
    created: function() {
      // console.log(this.me);
    },
    methods: {
      saveSession: function(){
        this.$emit('saveSession');
      }
    }
  }
</script>

