<template>
  <li>
    <div class="check-wrap" :class="this.className">
      <label for="time" class="form-check-label">
        <input id="time" type="checkbox" class="form-check-input" v-model="timeJob" true-value="1" @change="onDataChanged()"
          false-value="" />시간선택제 공무원<span class="form-check-sign">
          <span class="check"></span>
        </span>
      </label>
    </div>
  </li>
</template>

<script>
  export default {
    props: {
      timeJobCode: String,
      className: String
    },
    data: function () {
      return {
        timeJob: this.timeJobCode,
        classNm : this.className
      }
    },
    methods: {
      onDataChanged() {
        // console.log(this.timeJob);
        this.$emit('input', this.timeJob) // input 이벤트 발생
      }
    }
  }
</script>

