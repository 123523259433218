<template>
  <div id="dolligoCommunity" class="dolligo-wrap">
    <header>
      <dolligo-top propType="community" @saveSession="saveSession"></dolligo-top>
      <h1>커뮤니티</h1>
      <ul class="tab-menu">
        <li>
          <router-link :to="{ name: 'dolligoCommunity', params: { boardId: 'gongam' } }"
            :class="{ on: $route.params.boardId == 'gongam', new: datas.count.gongamCount > 0 }">교류요청</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'dolligoCommunity', params: { boardId: 'success' } }"
            :class="{ on: $route.params.boardId == 'success', new: datas.count.successCount > 0 }">성공했어요!</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'dolligoCommunity', params: { boardId: 'qa' } }"
            :class="{ on: $route.params.boardId == 'qa', new: datas.count.qaCount > 0 }">Q&amp;A</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'dolligoCommunity', params: { boardId: 'notice' } }"
            :class="{ on: $route.params.boardId == 'notice', new: datas.count.noticeCount > 0 }">공지사항</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'dolligoCommunity', params: { boardId: 'faq' } }"
            :class="{ on: $route.params.boardId == 'faq', new: datas.count.faqCount > 0 }">FAQ</router-link>
        </li>
      </ul>
    </header>
    <div class="content-wrap bg-white">
      <div class="board-search">
        <select v-model="datas.keywordColumn">
          <option value="wr_subject">제목</option>
          <option value="wr_content">내용</option>
          <option value="wr_name">닉네임</option>
        </select>
        <input type="search" placeholder="검색어를 입력하세요" v-model="datas.keyword" maxlength="20" v-on:keyup.enter="search" />
        <label for="check-my" class="form-check-label">
          <input id="check-my" type="checkbox" class="form-check-input" v-model="datas.is_me" true-value="y"
            false-value="" @change="search" />내가 쓴 글
          <span class="form-check-sign"><span class="check"></span></span>
        </label>
      </div>

      <ul class="board-list">
        <li>
          <router-link :class="{ notice: board.isNotice == '1', new: board.isNew > 0 }" v-for="board in datas.boards"
            v-bind:key="board.wr_id"
            :to="{ name: 'dolligoCommunityView', params: { wr_id: board.wr_id, boardId: datas.boardId } }"
            @click.native="goDetail()">
            <span class="top-wrap">
              <span class="board-title-wrap">
                <span class="board-title">
                  <strong :class="{ notice: board.isNotice == '1' }" v-if="board.isNotice == '1'">공지</strong>{{
                    board.wr_subject }}
                </span>
              </span>
              <span class="comment" v-if="board.wr_comment > 0">댓글 <strong>{{ board.wr_comment }}</strong></span>
            </span>
            <ul class="board-stat">
              <li>{{ !board.isView ? '익명게시자' : board.wr_name }}<span v-if="board.wr_1 == '1'" style="background-image: url(/img/lock-icon.2c58758f.svg);">&nbsp;&nbsp;&nbsp;&nbsp;</span> </li>
              <li>{{ board.txtDate }}</li>
              <li class="view">조회 {{ board.hitCount }}</li>
            </ul>
          </router-link>
          <infinite-loading :identifier="datas.infiniteId" @infinite="getBoards" ref="infiniteLoading">
            <div slot="no-more"></div>
            <div slot="no-results">글이 없습니다.</div>
          </infinite-loading>
        </li>
      </ul>
    </div>
    <dolligo-footer propType="dolligoCommunity"></dolligo-footer>
  </div>
</template>

<script>
import DolligoTop from '../../common/DolligoTop.vue';
import axios from 'axios';
import mixin from '../../../mixin';
import DolligoFooter from '../../common/DolligoFooter.vue';
import jsonQuery from 'json-query';

export default {
  mixins: [mixin],
  components: {
    DolligoTop,
    DolligoFooter,
  },
  data: function () {
    return {
      datas: {
        count: {},
        boards: [],
        page: 1,
        keyword: '',
        keywordColumn: 'wr_subject',
        is_me: 'n',
        infiniteId: +new Date(),
        isHistoryBack: false,
        boardId: this.$route.params.boardId
      }
    }
  },
  created: async function () {
    try {
      let datas = JSON.parse(sessionStorage.getItem('dolligoCommunity'));
      if (datas && datas.isHistoryBack == true) {
        this.datas = datas;
        sessionStorage.removeItem('dolligoCommunity');
      }
      this.datas.count = await axios.get('/api/dolligo/community/boards/new-count', this.axiosConfig);
      this.datas.count = this.datas.count.data;
    } catch (error) {
      this.errorHandler(error);
    }
  },
  methods: {
    search: async function () {
      let self = this;
      setTimeout(() => {
        self.$refs.infiniteLoading.stateChanger.reset();
        self.datas.boardId = self.$route.params.boardId;
        self.datas.page = 1;
        self.datas.boards = [];
      }, 100);
    },
    getBoards: async function ($state) {
      try {
        let axiosConfig = this.axiosConfig;
        axiosConfig.params.wr_is_comment = '0';
        axiosConfig.params.keyword = this.datas.keyword;
        axiosConfig.params.keywordColumn = this.datas.keywordColumn;
        axiosConfig.params.page = this.datas.page;
        axiosConfig.params.is_me = this.datas.is_me;
        let boards = await axios.get(`/api/dolligo/community/${this.datas.boardId}`, axiosConfig);
        boards = boards.data;

        if (boards.length == 0) {
          $state.complete();
        } else {
          this.datas.page++;
          let self = this;
          boards.forEach((element) => {
            let result = jsonQuery(`[wr_id=${element.wr_id}]`, {
              data: self.datas.boards
            }).value;
            if (!result) this.datas.boards.push(element);
          });
          $state.loaded();
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    goDetail: async function () {
      this.datas.isHistoryBack = true;
      sessionStorage.setItem('dolligoCommunity', JSON.stringify(this.datas));
    },
    saveSession: function () {
      sessionStorage.setItem('dolligoCommunity', JSON.stringify(this.datas));
    }
  }
}
</script>

