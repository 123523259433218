import DolligoMatchIndex from '@/components/dolligo/match/Index';

const dolligoMatch = [{
  path: '/dolligo/match/index',
  name: 'dolligoMatchIndex',
  component: DolligoMatchIndex,
  meta: {
    checkAgent: true,
    checkAuth: true,
    checkAuthDolligo: true
  }
}]

export default dolligoMatch