<template>
  <div id="app" class="dolligo-wrap">
    <top-back-header-dolligo :propTitle="`관심교류자`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <ul class="bookmark-list">
        <li v-for="member in datas.members" v-bind:key="member.bm_id">
          <router-link :to="{ name: 'dolligoMemberProfile', params: { id: member.bm_mb_id } }"
            v-if="member.member.mb_nick">
            <span class="user-row">
              <span :class="`icon face${member.member.mb_36}`"></span>
              <span class="name">{{ member.member.mb_nick }}</span>
              <span class="infor-row">
                <span class="value">{{ member.member.mb_8 }}</span>
                <span class="value">{{ member.member.jobName }}・{{ member.member.rankName }}</span>
                <span class="value" v-if="member.member.officeName">{{ member.member.officeName }}</span>
                <span class="value">{{ member.member.sex }}</span>
              </span>
            </span>
            <span class="district-row">
              <dl>
                <dt>현재 근무지</dt>
                <dd>{{ member.member.mb_4 }}</dd>
              </dl>
              <dl>
                <dt>희망 근무지</dt>
                <dd v-for="apply in member.member.g5_z_applies" v-bind:key="apply.wr_id">
                  <strong>{{ apply.wr_5 }}지망</strong>{{ apply.wr_4 }}
                </dd>
              </dl>
            </span>
          </router-link>
        </li>
        <infinite-loading :identifier="datas.infiniteId" @infinite="getList" ref="infiniteLoading">
          <div slot="no-more"></div>
          <div slot="no-results">관심 교류자가 없습니다.</div>
        </infinite-loading>

      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import mixin from '../../../mixin';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

export default {
  components: { TopBackHeaderDolligo },
  mixins: [mixin],
  data: function () {
    return {
      datas: {
        members: [],
        infiniteId: +new Date(),
        bm_id: ''
      }
    }
  },
  methods: {
    getList: async function ($state) {
      try {
        let axiosConfig = this.axiosConfig;
        axiosConfig.params.bm_id = this.datas.members.length > 0 ? this.datas.members[this.datas.members.length - 1].bm_id : '';
        let members = await axios.get(`/api/bookmark-member`, axiosConfig);
        members = members.data;
        if (members.length == 0) {
          $state.complete();
        } else {
          this.datas.members.push(...members);
          $state.loaded();
        }
      } catch (error) {
        this.errorHandler(error);
      }
    }
  }
}
</script>

