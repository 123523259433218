<template>
  <div class="dolligo-wrap">
    <!-- <header class="fixed">
      <a href="javascript:void(0)" class="icon back-btn" @click="$router.back()">뒤로가기</a>
      <h1>상세 근무 정보</h1>
      <div class="right-btns">
        <a href="javascript:void(0);" v-bind:class="{'txt-btn':true, on:isSubmit()}" v-on:click="save()">저장</a>
      </div>
    </header> -->
    <top-back-header-dolligo :propTitle="`상세 근무 정보`"></top-back-header-dolligo>
    <div class="content-wrap bg-white">
      <ul class="page-info-list">
        <li>상세 근무 정보를 입력해 주시면 상세 매칭이 가능합니다.</li>
        <li>안심하세요~ 아래 정보는 상세 매칭 만을 위하여 사용됩니다.</li>
        <li>(전보 제한여부는 프로필에 표시)</li>
      </ul>
      <div class="write-wrap">
        <div class="list-wrap">
          <ul>
            <li>
              <h2>출생년도</h2>
              <select v-model="member.mb_23">
                <option value="">년도 선택</option>
                <option v-for="value in years" v-bind:key="value" v-bind:value="value">{{value}}</option>
              </select>
            </li>
            <li>
              <h2>임용년월</h2>
              <select v-model="mb_24_y" class="year">
                <option value="">년도 선택</option>
                <option v-for="value in years" v-bind:key="value" v-bind:value="value">{{value}}년</option>
              </select>
              <select v-model="mb_24_m" class="month">
                <option value="">월 선택</option>
                <option v-for="value in months" v-bind:key="value" v-bind:value="value">{{value}}월</option>
              </select>
            </li>
            <li>
              <h2>승진년월</h2>
              <select v-model="mb_25_y" class="year">
                <option value="">승진년도</option>
                <option v-for="value in years" v-bind:key="value" v-bind:value="value">{{value}}년</option>
              </select>
              <select v-model="mb_25_m" class="month">
                <option value="">월 선택</option>
                <option v-for="value in months" v-bind:key="value" v-bind:value="value">{{value}}월</option>
              </select>
            </li>
            <li>
              <h2>전보 제한여부</h2>
              <ul class="btns-wrap">
                <li>
                  <label class="submit-btn" :class="{on: member.mb_26=='1'}"><input type="radio" value="1" v-model="member.mb_26" /> 교류가능 </label>
                  <label class="submit-btn" :class="{on: member.mb_26=='0'}"><input type="radio" value="0" v-model="member.mb_26" /> 교류불가 </label>
                </li>
              </ul>
            </li>
            <li>
              <h2>일방 전출여부</h2>
              <ul class="btns-wrap">
                <li>
                  <label class="submit-btn" :class="{on: member.mb_27=='1'}"><input type="radio" value="1" v-model="member.mb_27" /> 전출가능 </label>
                  <label class="submit-btn" :class="{on: member.mb_27=='0'}"><input type="radio" value="0" v-model="member.mb_27" /> 전출불가 </label>
                </li>
              </ul>
            </li>
            <li>
              <h2>강임여부</h2>
              <ul class="btns-wrap">
                <li>
                  <label class="submit-btn" :class="{on: member.mb_28=='1'}"><input type="radio" value="1" v-model="member.mb_28" /> 강임가능 </label>
                  <label class="submit-btn" :class="{on: member.mb_28=='0'}"><input type="radio" value="0" v-model="member.mb_28" /> 강임불가 </label>
                </li>
              </ul>
            </li>
            <li>
              <h2>교류사유</h2>
              <select v-model="member.mb_29">
                <option value="">사유선택</option>
                <option value="1">결혼 및 육아(부부합가)</option>
                <option value="2">연고지 근무(근거리)</option>
                <option value="3">부모봉양 및 병간호</option>
                <option value="4">승진 및 상급기관</option>
                <option value="5">근무환경 열악</option>
                <option value="6">기타</option>
              </select>
            </li>
            <li>
              <h2>교류가능 날짜(해당 날짜 이후부터 가능)</h2>
              <select v-model="mb_30_y" class="year">
                <option value="">년도 선택</option>
                <option v-for="value in changeYears" v-bind:key="value" v-bind:value="value">{{value}}년</option>
              </select>
              <select v-model="mb_30_m" class="month">
                <option value="">월 선택</option>
                <option v-for="value in months" v-bind:key="value" v-bind:value="value">{{value}}월</option>
              </select>
              <div class="helper-txt">설정한 날짜 이후 자동으로 전보 제한 여부가<br>교류 가능으로 변경 됩니다.</div>
            </li>
          </ul>
        </div>
        <button type="submit" class="submit-btn" v-bind:class="{on: isSubmit()}" @click="save()">저장</button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import mixin from '../../../mixin';
  import moment from 'moment';
import TopBackHeaderDolligo from '../../common/TopBackHeaderDolligo.vue';

  export default {
  components: { TopBackHeaderDolligo },
    name: 'dolligoMemberProfile',
    mixins: [mixin],
    data: function () {
      return {
        member: this.MEMBER,
        years: [],
        changeYears: [],
        months: ['01','02','03','04','05','06','07','08','09','10','11','12'],
        mb_24_y: this.MEMBER.mb_24.substr(0,4),
        mb_24_m: this.MEMBER.mb_24.substr(4,2),
        mb_25_y: this.MEMBER.mb_25.substr(0,4),
        mb_25_m: this.MEMBER.mb_25.substr(4,2),
        mb_30_y: this.MEMBER.mb_30.substr(0,4),
        mb_30_m: this.MEMBER.mb_30.substr(4,2)
      }
    },
    methods: {
      isSubmit: function(){
        if(!this.member.mb_23) return false;
        if(!this.mb_24_y) return false;
        if(!this.mb_24_m) return false;
        if(!this.mb_25_y) return false;
        if(!this.mb_25_m) return false;
        if(!this.member.mb_26) return false;
        if(!this.member.mb_27) return false;
        if(!this.member.mb_28) return false;
        if(!this.member.mb_29) return false;
        if(!this.mb_30_y) return false;
        if(!this.mb_30_m) return false;
        return true;
      },
      save: async function () {
        try {
          if(!this.isSubmit()) return false;
          this.member.mb_24 = this.mb_24_y + this.mb_24_m;
          this.member.mb_25 = this.mb_25_y + this.mb_25_m;
          this.member.mb_30 = this.mb_30_y + this.mb_30_m;
          let body = {
            mb_23: this.member.mb_23,
            mb_24: this.member.mb_24,
            mb_25: this.member.mb_25,
            mb_26: this.member.mb_26,
            mb_27: this.member.mb_27,
            mb_28: this.member.mb_28,
            mb_29: this.member.mb_29,
            mb_30: this.member.mb_30,
          };
          await axios.patch('/api/member', body, this.axiosConfig);

          if (this.$route.query.from == 'event') this.$router.push({name:'dolligoPremiumEvent'});
          else this.$router.push({name:'dolligoMemberRegistDone'});
        } catch (error) {
          this.errorHandler(error);
        }
      }
    },
    created: function(){
      let endYear = parseInt(moment().format('Y'));
      let startYear = endYear - 60;
      for (startYear; startYear <= endYear; startYear++) {
        this.years.push(startYear);
      }
      this.years.reverse();

      let changeEndYear = endYear + 3;
      for (endYear; endYear <= changeEndYear; endYear++) {
        this.changeYears.push(endYear);
      }      
    }
  }
</script>

